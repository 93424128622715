<template>
<div
  class="form-field"
  :class="classes"
>
  <label class="form-field__label">
    <span
      v-if="label && label.length"
      class="form-field__label-text"
    >
      {{ label }}
    </span>
    <input
      v-if="inputType === 'tel'"
      class="form-field__input"
      :type="inputType"
      v-maska="MASKS.tel"
      :placeholder="MASKS.tel"
      v-model="currentValue"
      @input="$emit('update:modelValue', $event.target.dataset.maskRawValue)"
      @focusout="phoneFocusOutHandler"
    >
    <input
      v-else
      class="form-field__input"
      :type="inputType"
      :placeholder="placeholder"
      v-model="currentValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
  </label>
  <div
    v-if="isShowMessage"
    class="form-field__message"
  >
    {{ message }}
  </div>
</div>
</template>

<script>
import { maska } from 'maska';
import { computed, ref } from 'vue';

const VALID_INPUT_TYPES = ['text', 'email', 'number', 'password', 'tel'];
const MASKS = {
  tel: '+7 (###) ###-##-##',
};

export default {
  name: 'form-field',
  directives: {
    maska,
  },
  props: {
    inputType: {
      type: String,
      validator: (value) => VALID_INPUT_TYPES.includes(value),
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isShowMessage: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    preselectedValue: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const currentValue = ref(props.preselectedValue);
    const rawPhoneValue = ref('');

    const phoneFocusOutHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();

      emit('focusout', { target: { value: rawPhoneValue.value } });
    };

    return {
      currentValue,
      rawPhoneValue,
      MASKS,
      classes: computed(() => ({
        'form-field--error': props.isError,
        'form-field--secondary': props.secondary,
      })),
      phoneFocusOutHandler,
    };
  },
};
</script>

<style lang="scss">
.form-field {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: $grey800;
  font-weight: $font-weight-medium;
  font-size: $font-size-medium;

  &__label {
    display: flex;
    flex-direction: column;

    &-text {
      margin-bottom: 8px;
      color: $grey800;
    }
  }

  &__input {
    width: 100%;
    height: 42px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid $grey300;
    border-radius: 4px;
    color: $grey900;
    font-size: $font-size-medium;
    background-color: $white;

    &:focus,
    &:focus-visible {
      border-color: $blue400;
      outline: none;
    }
  }

  &__message {
    margin-top: 12px;
    font-size: $font-size-regular;
  }

  &--error {
    color: $red500;

    .form-field {
      &__input {
        background-color: $red200;

        &:focus,
        &:focus-visible {
          border-color: $grey300;
        }
      }
    }
  }

  &.form-field--secondary {
    .form-field {
      &__input {
        width: fit-content;
        min-width: 100px;
        padding: 4px 12px;
        height: 30px;
        font-size: $font-size-medium;
      }

      &__message {
        margin-top: 4px;
        font-size: $font-size-small;
      }

      &__label-text {
        margin-bottom: 4px;
        font-size: $font-size-small;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .form-field {
    &__input {
      font-size: $font-size-regular!important;
      height: 40px!important;
    }
  }
}
</style>
