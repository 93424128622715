<template>
  <teleport to="body">
    <div
      v-show="isShown"
      class="modal-window"
      :class="{
        'modal-window--media': isMedia,
      }"
    >
      <div
        class="modal-window__background"
        @click="closePopup"
      />
      <div class="modal-window__wrapper">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'modal-window',
  emits: ['close-window'],
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    isMedia: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const closePopup = () => {
      emit('close-window');
    };

    return {
      closePopup,
    };
  },
};
</script>

<style lang="scss">
.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $grey400;
    opacity: 0.5;
    z-index: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__wrapper {
    max-width: 100%;
    border-radius: 12px;
    padding: 24px 16px;
    background: $white;
    z-index: 1;
  }

  &--media {
    .modal-window {
      &__wrapper {
        max-width: 90vw;
        max-height: 100vh;
        padding: 0;

        .media-block__carousel {
          height: 100%;

          .carousel {
            &__viewport {
              width: 100%;
              height: auto;
              max-height: 100%;
            }

            &__track {
              max-height: 100%;
            }

            &__slide {
              max-height: 100%;
            }
          }
        }
      }

      &__background {
        background: $grey400;
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .modal-window {
    &__wrapper {
      padding: 14px 10px;
    }
  }
}
</style>
