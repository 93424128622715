export default {
  en: {
    changePassword: 'Change password',
    oldPassword: 'Verify current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm new password',
    cancel: 'Cancel',
    update: 'Update',
    wasChange: 'The password is changed',
    errors: {
      emailValidation: "This doesn't look like an email address.",
      phoneValidation: 'Invalid phone number.',
      passwordEmpty: 'Enter a password.',
      passwordIncorrect: 'The password is incorrect.',
      passwordRepeatIncorrect: 'Passwords do not match.',
      passwordNewIsSameAsOld: 'The new password must be different from the old one.',
      passwordNotValid: 'Enter 6 to 20 characters. The password must contain at least one digit, one uppercase letter, and one lowercase letter.',
      loginError: 'No user found with this combination of username and password.',
    },
  },
  ru: {
    changePassword: 'Сменить пароль',
    oldPassword: 'Старый пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Новый пароль еще раз',
    cancel: 'Отменить',
    update: 'Продолжить',
    wasChange: 'Пароль изменен',
    errors: {
      emailValidation: 'Это непохоже на email-адрес.',
      phoneValidation: 'Некорректный номер телефона.',
      passwordEmpty: 'Введите пароль',
      passwordIncorrect: 'Пароль введен не верно',
      passwordRepeatIncorrect: 'Пароли не совпадают.',
      passwordNewIsSameAsOld: 'Новый пароль должен отличаться от старого',
      passwordNotValid: 'Введите от 6 до 20 символов. Пароль должен содержать хотя бы одну цифру, одну заглавную и строчную букву',
      loginError: 'Пользователь с такой комбинацией логина и пароля не найден.',
    },
  },
};
