import { USER_TOKEN_KEY } from '@/constants/auth';

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
  const updatedOptions = {
    path: '/',
    ...options,
  };

  if (updatedOptions.expires instanceof Date) {
    updatedOptions.expires = updatedOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.keys(updatedOptions).forEach((optionKey) => {
    updatedCookie += `; ${optionKey}`;
    const optionValue = updatedOptions[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  document.cookie = updatedCookie;
};

const requestOptions = (options = {}) => {
  const headers = {
    'Content-Type': options.contentType || 'application/json;charset=utf-8',
    referrerPolicy: 'no-referrer-when-downgrade',
  };
  const token = localStorage.getItem(USER_TOKEN_KEY);

  if (token && token.length) {
    headers.Authorization = `Bearer ${token}`;
  }

  return {
    headers,
  };
};

export const sendRequest = async (url, payload = null, method = 'POST', isStream = false, isUpload = false, postPathVars = false) => {
  const options = {
    method,
    ...requestOptions(),
  };

  if (method !== 'GET' && !isUpload) {
    options.body = JSON.stringify(payload);
  }

  if (isUpload) {
    delete options.headers['Content-Type'];
    const body = new FormData();
    Object.keys(payload).forEach((key) => {
      body.append(key, payload[key]);
    });
    options.body = body;
  }

  const updatedUrl = (method === 'GET' || postPathVars) && payload ? `${url}?${new URLSearchParams(payload)}` : url;

  if (!isStream) {
    const resp = await fetch(updatedUrl, {
      ...options,
    });

    if (resp.status === 401 && resp.url.indexOf('login') === -1) {
      localStorage.removeItem(USER_TOKEN_KEY);
      Document.location.reload();
    }

    const data = await resp.json();
    return {
      ok: resp.ok,
      status: resp.status,
      data,
    };
  }
  const resp = await fetch(updatedUrl, {
    ...options,
  });
  const contentDisposition = resp.headers.get('Content-Disposition');
  const fileName = contentDisposition ? decodeURI(contentDisposition.split('filename*=UTF-8\'\'')[1]) : '';
  const blob = await resp.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = blobUrl;
  link.download = fileName || 'VIEWga_doc.pdf';

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
  return true;
};

export const downloadFile = async (url, payload = null) => {
  const options = {
    method: 'GET',
    ...requestOptions({ contentType: 'application/octet-stream' }),
  };

  const updatedUrl = payload ? `${url}?${new URLSearchParams(payload)}` : url;

  const resp = await fetch(updatedUrl, {
    ...options,
  });

  if (!resp.ok) {
    throw new Error(`Status code: ${resp.status}`);
  }

  const header = resp.headers.get('Content-Disposition');
  const blob = await resp.blob();

  return {
    blob,
    fileName: header ? header.slice(header.indexOf('filename=') + 'filename='.length) : '',
  };
};
