<template>
  <div
    v-if="unitsList && unitsList.length"
    class="user-actives"
  >
    <div class="user-actives__title">{{ t('yourActive') }}</div>
    <ul class="user-actives__list">
      <li
        v-for="(unit, index) in unitsList"
        :key="unit.userUnitId"
        class="user-actives__list-item"
      >
        <router-link :to="`/units#unit_${index}`">
        <span class="user-actives__text">
          {{ unit.part === '1' ? unit.unitName : `${unit.part}\u202F${unit.unitName}`}}
        </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import transcription from './transcription';

export default {
  name: 'UserActives',
  props: {
    unitsList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', transcription.en);
    mergeLocaleMessage('ru', transcription.ru);

    return {
      t,
    };
  },
};
</script>

<style scoped lang="scss">
.user-actives {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 0 0;
    margin: 12px 0 0;
    list-style: none;

    &-item {
      display: flex;
      width: fit-content;
      max-width: 100%;
      align-items: center;
      padding: 4px 12px;
      margin-top: 4px;
      border-radius: 12px;
      background: $blue100;

      a {
        text-decoration: none;
        color: $blue500;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
      }

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: $blue500;

        a {
          color: $white;
        }
      }
    }
  }

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
}
</style>
