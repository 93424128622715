<template>
  <div
    class="filter-dropdown"
    v-click-away="closeDropdown"
  >
    <popup-dropdown
      :data="filterOptions"
      :isMultipleChoice="isMultipleChoice"
      :manual-show="showDropdown"
      :active-item-id="activeItemId"
      @select-dropdown-item="filterSelectHandler"
    >
      <filter-button
        v-if="!selectView"
        :label="filterName"
        :value="filterButtonValue"
        @toggle-filter-dropdown="dropdownToggle"
        @clear-filters="clearFilters"
      >
        <template #beforeIcon>
          <slot name="icon"/>
        </template>
      </filter-button>
      <secondary-button
        v-if="selectView"
        :label="filterButtonValue"
        @click="dropdownToggle"
      >
        <template #iconAfter>
          <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
              1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
              -0.0719468 1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496
              0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742 0.21598C11.0753
              0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
              5.69705 6 5.49998 6Z"/>
          </svg>
        </template>
      </secondary-button>
    </popup-dropdown>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { directive } from 'vue3-click-away';
import FilterButton from '@/ui-kit/FilterButton/FilterButton.vue';
import PopupDropdown from '@/components/PopupDropdown/PopupDropdown.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';

export default {
  name: 'filter-dropdown',
  components: {
    FilterButton,
    PopupDropdown,
    SecondaryButton,
  },
  directives: {
    ClickAway: directive,
  },
  props: {
    filterName: {
      type: String,
      default: '',
    },
    filterValues: {
      type: Array,
      default: () => [],
    },
    isMultipleChoice: {
      type: Boolean,
      default: false,
    },
    resetFilter: {
      type: Boolean,
      default: false,
    },
    preSelectedValue: {
      type: Object,
      default: () => {},
    },
    selectView: {
      type: Boolean,
      deafult: false,
    },
  },
  emits: ['filter-select'],
  setup(props, { emit }) {
    const showDropdown = ref(null);
    const filterOptions = computed(() => props.filterValues);
    const filterButtonValue = ref(props.preSelectedValue ? props.preSelectedValue.label : '');
    const activeItemId = ref(props.preSelectedValue ? props.preSelectedValue.id : '');

    const closeDropdown = () => {
      showDropdown.value = false;
    };

    const dropdownToggle = () => {
      showDropdown.value = !showDropdown.value;
    };

    const clearFilters = () => {
      closeDropdown();
      filterOptions.value = filterOptions.value.map((item) => {
        const updatedItem = item;
        updatedItem.selected = false;
        return updatedItem;
      });
      filterButtonValue.value = '';
      emit('filter-select', props.isMultipleChoice ? [] : {});
    };

    const filterSelectHandler = (source) => {
      if (!source.length) {
        clearFilters();
        return;
      }

      if (props.isMultipleChoice) {
        filterButtonValue.value = `${source.length}`;
      } else {
        filterButtonValue.value = source[0] ? source[0].label : '';
        dropdownToggle();
      }

      emit('filter-select', props.isMultipleChoice ? source : source[0]);
    };

    watch(() => props.resetFilter, (first, second) => {
      if (second) {
        closeDropdown();
        filterOptions.value = filterOptions.value.map((item) => {
          const updatedItem = item;
          updatedItem.selected = false;
          return updatedItem;
        });
        filterButtonValue.value = '';
      }
    });
    watch(() => props.preSelectedValue, () => {
      activeItemId.value = props.preSelectedValue ? props.preSelectedValue.id : '';
      filterButtonValue.value = props.preSelectedValue ? props.preSelectedValue.label : '';
    });

    return {
      showDropdown,
      dropdownToggle,
      filterSelectHandler,
      clearFilters,
      closeDropdown,
      filterButtonValue,
      filterOptions,
      activeItemId,
    };
  },
};
</script>

<style lang="scss">
.avatar-icon {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  background-color: $blue100;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  font-size: $font-size-medium;

  &--big {
    font-size: 44px;
  }

  &__image {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    margin: 0;
    padding: 0;
    color: $blue500;
    font-weight: $font-weight-medium;
    line-height: 12px;
  }
}

.filter-dropdown {
  .filter-button__label-text {
    color: $main-black;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
  }
}

</style>
