<template>
  <div class="news-card">
    <div class="news-card__header-wrapper">
      <ui-label
        :label="headerLabel"
        class="news-card__label"
      />
      <span
        v-if="headerDate"
        class="news-card__date"
      >
      {{ headerDate }}
      </span>
    </div>
    <div class="news-card__title">
      <slot name="cardTitle"></slot>
    </div>
    <div class="news-card__content">
      <slot name="cardContent"></slot>
    </div>
    <div class="news-card__img">
      <slot name="cardImage"></slot>
    </div>
  </div>
</template>

<script>
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';

export default {
  name: 'NewsCard',
  components: {
    UiLabel,
  },
  props: {
    headerLabel: {
      type: String,
      default: '',
    },
    headerDate: {
      type: String,
      default: '',
    },
  },
};

</script>

<style lang="scss">
.news-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header-wrapper {
    display: flex;
    align-items: center;
  }

  &__date {
    padding: 0;
    margin: 0 0 0 16px;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: 16px;
    color: $grey900;
  }

  &__title {
    margin: 24px 0 0 0;
    padding: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-title-mid-large;
    line-height: 32px;
    color: $main-black;
  }

  &__content {
    margin: 16px 0 0 0;
    color: $main-black;
    font-weight: $font-weight-regular;
    font-size: $font-size-medium;
    line-height: 22px;

    p {
      padding: 0;
      margin: 0 0 15px 0;
    }

    ul {
      margin: 0 0 15px 0;
      padding: 0;
    }

    li {
      margin: 6px 0 0 20px;
      padding: 0;

      &:first-child {
        margin: 0 0 0 20px;
      }
    }

    h4 {
      margin: 0 0 10px 0;
      padding: 0;
      font-weight: $font-weight-bold;
    }

  }

  &__img {
    width: 490px;

    img {
      margin-top: 24px;
      object-fit: cover;
      border-radius: 12px;
      overflow: hidden;
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .news-card {
    &__title {
      font-size: $font-size-medium;
      line-height: 24px;
    }

    &__content {
      line-height: 20px;
      font-size: $font-size-regular;
    }

    &__date,
    &__label {
      line-height: 18px;
    }

    &__img {
      width: 280px;
    }
  }
}
</style>
