<template>
<a :href="url" class="main-notification">
  <div class="main-notification__icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8
      0C12.4183 0 16 3.58172 16 8Z" fill="#F48575"/>
      <path d="M8.8 12C8.8 12.4418 8.44183 12.8 8 12.8C7.55817 12.8 7.2 12.4418 7.2 12C7.2
      11.5582 7.55817 11.2 8 11.2C8.44183 11.2 8.8 11.5582 8.8 12Z" fill="#F5F5F5"/>
      <path d="M7.2 4.8C7.2 4.35817 7.55817 4 8 4C8.44183 4 8.8 4.35817 8.8 4.8V9.6C8.8 10.0418
      8.44183 10.4 8 10.4C7.55817 10.4 7.2 10.0418 7.2 9.6V4.8Z" fill="#F5F5F5"/>
    </svg>
  </div>
  <div class="main-notification__text">
    <slot name="content"/>
  </div>
</a>
</template>

<script>
export default {
  name: 'MainNotification',
  props: {
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.main-notification {
  display: flex;
  width: 100%;
  padding: 12px 36px;
  align-items: center;
  background-color: $red200;
  text-decoration: none;
  font-weight: $font-weight-medium;
  box-sizing: border-box;
  z-index: 99;

  &:active, &:visited {
    color: $red500;
    text-decoration: none;
  }

  &__icon {
    height: 18px;
  }

  &__text {
    display: flex;
    align-items: center;
    height: 18px;
    margin-left: 8px;
    color: $red500;
    font-size: $font-size-regular;
   }
}

@media screen and (max-width: $mobile-breakpoint) {
  .main-notification {
    padding: 13px 20px;
    justify-content: center;

    &__text {
      margin-left: 4px;
      font-size: $font-size-small;
      padding-bottom: 1px;
    }
  }
}
</style>
