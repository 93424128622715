<template>
  <TimeLineWrapper
    class="stages"
    :title="t('title')"
  >
    <ul class="stages__list">
      <li
        class="stages__list-element"
        v-for="item in preparedArray"
        :key="item.id"
      >
        <div class="stages__list-element-upper-wrapper">
          <div class="stages__list-element-date">
            <div
              class="stages__list-element-date-text"
              v-if="item.date"
            >
              {{ item.date }}
            </div>
          </div>
          <div class="stages__list-element-icon-container">
            <svg
              v-if="item.status === 3"
              class="stages__list-element-icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114
                3.58862 16 8 16C12.4114 16 16 12.4114
                16 8C16 3.58862 12.4114 0 8 0ZM12.0547
                6.30469L7.72131 10.6379C7.59131 10.7679
                7.42065 10.8334 7.25 10.8334C7.07935 10.8334
                6.90869 10.7679 6.77869 10.6379L4.61206
                8.47131C4.35132 8.21069 4.35132 7.78931
                4.61206 7.52869C4.87268 7.26794 5.29395
                7.26794 5.55469 7.52869L7.25 9.224L11.1121
                5.36206C11.3727 5.10132 11.7939 5.10132
                12.0547 5.36206C12.3153 5.62268 12.3153
                6.04395 12.0547 6.30469Z"
                fill="#5DC397"
              />
            </svg>
            <svg
              v-else-if="item.status === 1"
              class="stages__list-element-icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="16"
                height="16"
                rx="8"
                fill="#89909E"
              />
              <path
                d="M8.47953 5.605C8.47953 5.33991
                8.26464 5.125 7.99953 5.125C7.73443 5.125
                7.51953 5.33991 7.51953 5.605V8.005C7.51953
                8.2701 7.73443 8.485 7.99953
                8.485H9.91953C10.1846 8.485 10.3995
                8.2701 10.3995 8.005C10.3995 7.7399 10.1846
                7.525 9.91953 7.525H8.47953V5.605Z"
                fill="#FAFBFB"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99922 3.20312C5.34825 3.20312 3.19922
                5.35216 3.19922 8.00313C3.19922 10.6541
                5.34825 12.8031 7.99922 12.8031C10.6502 12.8031 12.7992 10.6541 12.7992
                8.00313C12.7992 5.35216 10.6502 3.20312
                7.99922 3.20312ZM4.15922 8.00313C4.15922
                5.88235 5.87844 4.16313 7.99922
                4.16313C10.12 4.16313 11.8392 5.88235 11.8392
                8.00313C11.8392 10.1239 10.12 11.8431 7.99922 11.8431C5.87844 11.8431
                4.15922 10.1239 4.15922 8.00313Z"
                fill="#FAFBFB"
              />
            </svg>
            <svg
              v-else-if="item.status === 2"
              class="stages__list-element-icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="16"
                height="16"
                rx="8"
                fill="#658EEA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.206 5.13674C8.44221 4.81258 7.58052 4.8041 6.81049 5.11315C6.58244
                5.20468 6.32312 5.09463 6.23128 4.86733C6.13945 4.64003 6.24987 4.38158
                6.47793 4.29005C7.46701 3.89308 8.57379 3.90398 9.55482 4.32034C10.5359
                4.73671 11.3107 5.52442 11.709 6.5102C12.1072 7.496 12.0964 8.59908 11.6786
                9.57684C11.4207 10.1804 11.0209 10.7056
                10.52 11.1127H11.5409C11.7868 11.1127
                11.9861 11.3113 11.9861 11.5563C11.9861 11.8014 11.7868 12 11.5409
                12H9.31515C9.06929 12 8.87 11.8014 8.87 11.5563V11.3226C8.86982 11.3158
                8.86982 11.309 8.87 11.3023V9.33797C8.87 9.09293 9.06929 8.8943 9.31515
                8.8943C9.56101 8.8943 9.76031 9.09293 9.76031 9.33797V10.5726C10.2429
                10.2399 10.6259 9.77578 10.8594 9.22918C11.1847 8.46797 11.1932 7.6092
                10.8832 6.84167C10.573 6.07417 9.96979 5.4609 9.206 5.13674ZM5.14404
                5.41583C5.38989 5.41583 5.5892 5.61446 5.5892 5.8595V5.86394C5.5892
                6.10897 5.38989 6.30761 5.14404 6.30761C4.89819 6.30761 4.69889 6.10897
                4.69889 5.86394V5.8595C4.69889 5.61446 4.89819 5.41583 5.14404
                5.41583ZM4.44515 7.11957C4.691 7.11957 4.89031 7.3182 4.89031
                7.56324V7.56768C4.89031 7.81272 4.691 8.01135 4.44515 8.01135C4.1993 8.01135
                4 7.81272 4 7.56768V7.56324C4 7.3182 4.1993 7.11957 4.44515 7.11957ZM4.69889
                8.93867C4.94474 8.93867 5.14404 9.1373 5.14404 9.38234V9.38677C5.14404
                9.63181 4.94474 9.83045 4.69889 9.83045C4.45304 9.83045 4.25374 9.63181
                4.25374 9.38677V9.38234C4.25374 9.1373
                4.45304 8.93867 4.69889 8.93867ZM5.82513
                10.3895C6.07098 10.3895 6.27028 10.5881 6.27028 10.8331V10.8376C6.27028
                11.0826 6.07098 11.2813 5.82513 11.2813C5.57928 11.2813 5.37997 11.0826
                5.37997 10.8376V10.8331C5.37997 10.5881 5.57928 10.3895 5.82513
                10.3895ZM7.53454 11.0859C7.7804 11.0859 7.97969 11.2846 7.97969
                11.5296V11.5341C7.97969 11.7791 7.7804 11.9777 7.53454 11.9777C7.28868
                11.9777 7.08939 11.7791 7.08939 11.5341V11.5296C7.08939 11.2846 7.28868
                11.0859 7.53454 11.0859Z"
                fill="#FAFBFB"
              />
            </svg>
          </div>
          <div class="stages__list-element-step">
            <info-header
              class="info-header"
              :class="{
                'info-header--state-grey':item.status === 1,
              }"
              :text="item.title"
              :popupData="{
                  text: item.constructionStageDescription,
                }"
            />
          </div>
        </div>
        <div
          class="stages__list-element-lower-wrapper"
          v-if="item.list"
        >
          <ul
            class="stages__list-element-inner"
          >
            <li
              class="stages__list-element-inner-element"
              v-for="innerItem in item.list"
              :key="innerItem.id"
            >
              <div class="stages__list-element-upper-wrapper">
                <div class="stages__list-element-date--state-inner">
                  <div
                    class="stages__list-element-date-text"
                    v-if="innerItem.date"
                  >
                    {{ innerItem.date }}
                  </div>
                </div>
                <div class="stages__list-element-icon-container">
                  <svg
                    v-if="innerItem.status === 3"
                    class="stages__list-element-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114
                      3.58862 16 8 16C12.4114 16 16 12.4114
                      16 8C16 3.58862 12.4114 0 8 0ZM12.0547
                      6.30469L7.72131 10.6379C7.59131 10.7679
                      7.42065 10.8334 7.25 10.8334C7.07935 10.8334
                      6.90869 10.7679 6.77869 10.6379L4.61206
                      8.47131C4.35132 8.21069 4.35132 7.78931
                      4.61206 7.52869C4.87268 7.26794 5.29395
                      7.26794 5.55469 7.52869L7.25 9.224L11.1121
                      5.36206C11.3727 5.10132 11.7939 5.10132
                      12.0547 5.36206C12.3153 5.62268 12.3153
                      6.04395 12.0547 6.30469Z"
                      fill="#5DC397"
                    />
                  </svg>
                  <svg
                    v-else-if="innerItem.status === 1"
                    class="stages__list-element-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="16"
                      height="16"
                      rx="8"
                      fill="#89909E"
                    />
                    <path
                      d="M8.47953 5.605C8.47953 5.33991
                      8.26464 5.125 7.99953 5.125C7.73443 5.125
                      7.51953 5.33991 7.51953 5.605V8.005C7.51953
                      8.2701 7.73443 8.485 7.99953
                      8.485H9.91953C10.1846 8.485 10.3995
                      8.2701 10.3995 8.005C10.3995 7.7399 10.1846
                      7.525 9.91953 7.525H8.47953V5.605Z"
                      fill="#FAFBFB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99922 3.20312C5.34825 3.20312 3.19922
                      5.35216 3.19922 8.00313C3.19922 10.6541
                      5.34825 12.8031 7.99922 12.8031C10.6502 12.8031 12.7992 10.6541 12.7992
                      8.00313C12.7992 5.35216 10.6502 3.20312
                      7.99922 3.20312ZM4.15922 8.00313C4.15922
                      5.88235 5.87844 4.16313 7.99922
                      4.16313C10.12 4.16313 11.8392 5.88235 11.8392
                      8.00313C11.8392 10.1239 10.12 11.8431 7.99922 11.8431C5.87844 11.8431
                      4.15922 10.1239 4.15922 8.00313Z"
                      fill="#FAFBFB"
                    />
                  </svg>
                  <svg
                    v-else-if="innerItem.status === 2"
                    class="stages__list-element-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="16"
                      height="16"
                      rx="8"
                      fill="#658EEA"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.206 5.13674C8.44221 4.81258 7.58052 4.8041 6.81049 5.11315C6.58244
                      5.20468 6.32312 5.09463 6.23128 4.86733C6.13945 4.64003 6.24987 4.38158
                      6.47793 4.29005C7.46701 3.89308 8.57379 3.90398 9.55482 4.32034C10.5359
                      4.73671 11.3107 5.52442 11.709 6.5102C12.1072 7.496 12.0964 8.59908 11.6786
                      9.57684C11.4207 10.1804 11.0209 10.7056
                      10.52 11.1127H11.5409C11.7868 11.1127
                      11.9861 11.3113 11.9861 11.5563C11.9861 11.8014 11.7868 12 11.5409
                      12H9.31515C9.06929 12 8.87 11.8014 8.87 11.5563V11.3226C8.86982 11.3158
                      8.86982 11.309 8.87 11.3023V9.33797C8.87 9.09293 9.06929 8.8943 9.31515
                      8.8943C9.56101 8.8943 9.76031 9.09293 9.76031 9.33797V10.5726C10.2429
                      10.2399 10.6259 9.77578 10.8594 9.22918C11.1847 8.46797 11.1932 7.6092
                      10.8832 6.84167C10.573 6.07417 9.96979 5.4609 9.206 5.13674ZM5.14404
                      5.41583C5.38989 5.41583 5.5892 5.61446 5.5892 5.8595V5.86394C5.5892
                      6.10897 5.38989 6.30761 5.14404 6.30761C4.89819 6.30761 4.69889 6.10897
                      4.69889 5.86394V5.8595C4.69889 5.61446 4.89819 5.41583 5.14404
                      5.41583ZM4.44515 7.11957C4.691 7.11957 4.89031 7.3182 4.89031
                      7.56324V7.56768C4.89031 7.81272 4.691 8.01135 4.44515 8.01135C4.1993 8.01135
                      4 7.81272 4 7.56768V7.56324C4 7.3182 4.1993 7.11957 4.44515 7.11957ZM4.69889
                      8.93867C4.94474 8.93867 5.14404 9.1373 5.14404 9.38234V9.38677C5.14404
                      9.63181 4.94474 9.83045 4.69889 9.83045C4.45304 9.83045 4.25374 9.63181
                      4.25374 9.38677V9.38234C4.25374 9.1373
                      4.45304 8.93867 4.69889 8.93867ZM5.82513
                      10.3895C6.07098 10.3895 6.27028 10.5881 6.27028 10.8331V10.8376C6.27028
                      11.0826 6.07098 11.2813 5.82513 11.2813C5.57928 11.2813 5.37997 11.0826
                      5.37997 10.8376V10.8331C5.37997 10.5881 5.57928 10.3895 5.82513
                      10.3895ZM7.53454 11.0859C7.7804 11.0859 7.97969 11.2846 7.97969
                      11.5296V11.5341C7.97969 11.7791 7.7804 11.9777 7.53454 11.9777C7.28868
                      11.9777 7.08939 11.7791 7.08939 11.5341V11.5296C7.08939 11.2846 7.28868
                      11.0859 7.53454 11.0859Z"
                      fill="#FAFBFB"
                    />
                  </svg>
                </div>
                <div class="stages__list-element-step">
                  <info-header
                    class="info-header"
                    :class="{'info-header--state-grey':innerItem.status === 1}"
                    :text="innerItem.title"
                    :popupData="{
                      text: innerItem.constructionStageDescription,
                    }"
                  />
                </div>
              </div>
              <div
                class="stages__list-element-lower-wrapper"
                v-if="innerItem.innerList"
              >
                <ul class="stages__list-element-last-list">
                  <li
                    class="stages__list-element-last-list-element"
                    v-for="bottomItem in innerItem.innerList"
                    :key="bottomItem.id"
                  >
                    <div class="stages__list-element-date--state-inner">
                      <div
                        class="stages__list-element-date-text"
                        v-if="bottomItem.date"
                      >
                        {{ bottomItem.date }}
                      </div>
                    </div>
                    <div
                      class="stages__list-element-icon--state-dot-container"
                      :class="{
                        'stages__list-element-icon--state-dot-container-top':bottomItem.isFirst,
                        'stages__list-element-icon--state-dot-container-bottom':bottomItem.isLast,
                      }"
                    >
                      <svg
                        class="stages__list-item-inner-icon-dot"
                        :class="{
                          'stages__icon-dot--grey' : bottomItem.status === 1,
                          'stages__icon-dot--blue' : bottomItem.status === 2,
                          'stages__icon-dot--green' : bottomItem.status === 3,
                        }"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="6" height="6" rx="3"/>
                      </svg>
                    </div>
                    <div
                      class="stages__list-element-last-list-text"
                      :class="{
                        'stages__list-element-last-list-text--state-grey': bottomItem.status === 1,
                        'stages__list-element-last-list-text--state-purple':
                          bottomItem.status === 2,
                      }"
                    >
                      {{ bottomItem.title }}
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </TimeLineWrapper>
</template>

<script>
import TimeLineWrapper from '@/components/TimeLineWrapper/TimeLineWrapper.vue';
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import translate from './translate';

const prepareArray = (arr) => !arr.length
  ? []
  : arr.map((item) => {
    if (item.list) {
      return {
        ...item,
        list: item.list.map((innerItem) => {
          if (innerItem.innerList) {
            const editedItem = innerItem;
            editedItem.innerList[0] = {
              ...editedItem.innerList[0],
              isFirst: true,
            };
            // eslint-disable-next-line
            editedItem.innerList[editedItem.innerList.length - 1] = {
              ...editedItem.innerList[editedItem.innerList.length - 1],
              isLast: true,
            };
            return {
              ...editedItem,
            };
          }

          return {
            ...innerItem,
          };
        }),
      };
    }

    return {
      ...item,
    };
  });

export default {
  name: 'PeripheryStagesList',
  components: {
    TimeLineWrapper,
    InfoHeader,
  },
  props: {
    peripheryData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translate.en);
    mergeLocaleMessage('ru', translate.ru);
    const preparedArray = ref(prepareArray(props.peripheryData));

    watch(() => props.peripheryData, () => {
      preparedArray.value = prepareArray(props.peripheryData);
    });

    return {
      t,
      preparedArray,
    };
  },
};
</script>

<style lang="scss">
@import "PeripheryStagesList";
</style>
