<template>
  <router-link
   class="more-link"
   :to="path"
  >
  {{ label }}
  </router-link>
</template>

<script>
export default {
  name: 'MoreLink',
  props: {
    label: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
  },
};
</script>

<style computed lang="scss">
.more-link {
  margin: 0;
  padding: 3px 8px;
  border-radius: 12px;
  background-color: transparent;
  color: $blue500;
  font-size: $font-size-regular;
  font-weight: $font-weight-medium;
  line-height: 18px;
  text-decoration: none;
  transition: 0.4s;

  &:hover {
    background-color: $blue100;
    cursor: pointer;
  }
}
</style>
