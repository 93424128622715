export default {
  en: {
    accommodation: 'Accommodation',
    booked: 'Booked',
    guests: 'Guests',
    source: 'Source',
    paid: 'Paid',
    renovation: 'Renovation',
    description: 'Description',
  },
  ru: {
    accommodation: 'Проживание',
    booked: 'Забронировано',
    guests: 'Гости',
    source: 'Источник',
    paid: 'Оплачено',
    renovation: 'Ремонт',
    description: 'Описание',

  },
};
