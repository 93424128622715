<template>
  <dashboard-layout
    class="timeline-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="timeline"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <div class="timeline-page__wrapper">
        <div class="timeline-page__title-block">
          <title-main
            class="timeline-page__title"
            :text="t('timelinePage.title')"
            icon
          >
            <template #icon>
              <img src="../assets/list-icon.svg" alt="booking-icon">
            </template>
          </title-main>
          <div class="timeline-page__info">
            {{ t('timelinePage.info') }}
          </div>
        </div>
        <div class="timeline-page__content">
          <ui-tabs
            :list="timelineUnits"
            :preselected-tab-id="timelineUnits[0] ? timelineUnits[0].id : 0"
            flat
            @tab-change="tabChangeHandler"
          />
          <template
            v-if="currentTimeline
            && currentTimeline.length && timelineCurrentTab.id !== 1488 && isInvestor"
          >
            <time-line
              v-if="currentTimeline.length"
              class="timeline-page__timeline"
              :data="currentTimeline"
            />
          </template>
          <template
            v-else-if="currentTimeline
            && currentTimeline.length && (timelineCurrentTab.id === 1488 || !isInvestor)"
          >
            <periphery-stages-list
              class="timeline-page__timeline timeline-page__timeline--periphery"
              :peripheryData="currentTimeline"
            />
          </template>
          <template v-else>
            <div class="timeline-page__timeline">
              <stub-block-no-data
                :title="t('timelinePage.stubTitle')"
                :sub-title="t('timelinePage.stubSubTitle')"
              >
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       viewBox="0 0 24 24" fill="none">
                    <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893
                    21.7071C0.48043 21.8946 0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
                    <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054
                    8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
                    <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536
                    15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
                    <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536
                    22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
                  </svg>
                </template>
              </stub-block-no-data>
            </div>
          </template>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import TimeLine from '@/components/TimeLine/TimeLine.vue';
import PeripheryStagesList from '@/components/PeripheryStagesList/PeripheryStagesList.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';

export default {
  name: 'TimelinePage',
  components: {
    PeripheryStagesList,
    TimeLine,
    TitleMain,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    UserActives,
    StubBlockNoData,
    UserManager,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const userState = store.state.user;
    const userManagerData = computed(() => userState.managerData);
    const timelineState = store.state.timeline;
    const menuItems = computed(() => userState.sideMenuItems);
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const isInvestor = computed(() => userState.isInvestor);
    const timelineUnits = computed(() => timelineState.timelineTabs);
    const currentTimeline = computed(() => timelineState.currentTimeline);
    const timelineCurrentTab = computed(() => timelineState.currentTab);

    const roleAndLocationChangeHandler = () => new Promise((resolve) => {
      let fieldsUpdated = 0;

      const checkCompletion = () => {
        fieldsUpdated++;
        if (fieldsUpdated === 2) {
          fieldsUpdated = 0;
          resolve();
        }
      };

      watch(() => store.state.user.currentRole, () => {
        checkCompletion();
      });

      watch(() => store.state.user.currentLocation, () => {
        checkCompletion();
      });

      watch(() => store.state.user.units, () => {
        checkCompletion();
      });
    });

    const initTimeline = async () => {
      await store.dispatch('timeline/initTimelines');
      roleAndLocationChangeHandler().then(() => {
        initTimeline();
      });
    };

    const initTimelineData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }

      await store.dispatch('timeline/initTimelines');

      roleAndLocationChangeHandler().then(() => {
        initTimeline();
      });
    };

    initTimelineData();

    const tabChangeHandler = (tabId) => {
      store.dispatch('timeline/changeCurrentTab', tabId);
    };

    const locationChangeHandler = async (locationId) => {
      await store.dispatch('user/setCurrentLocation', locationId);
      await store.dispatch('timeline/initTimelines');
    };

    return {
      t,
      menuItems,
      userUnits,
      userLocations,
      currentUserLocation,
      timelineUnits,
      currentTimeline,
      timelineCurrentTab,
      isInvestor,
      tabChangeHandler,
      locationChangeHandler,
      userManagerData,
    };
  },
};
</script>

<style lang="scss">
.timeline-page {
  &__info {
    margin-top: 8px;
    max-width: 740px;
    line-height: 18px;
    font-size: $font-size-regular;
    color: $grey800;
  }

  &__timeline {
    margin-top: 60px;
  }

  &__content {
    margin-top: 32px;
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .timeline-page {
    &__timeline {
      margin-top: 40px;
    }

    &__content {
      margin-top: 24px;
    }
  }
}
</style>
