<template>
  <content-layout
    class="documents-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #main-content>
      <div class="documents-page__content-block">
<!--        <div class="documents-page__title-block">-->
<!--          <title-main-->
<!--            class="documents-page__title"-->
<!--            :text="t('documentsPage.documents')"-->
<!--          />-->

<!--          <div class="documents-page__add-content">-->
<!--            <div>-->
<!--              &lt;!&ndash;              <title-main&ndash;&gt;-->
<!--              &lt;!&ndash;                class="documents-page__title"&ndash;&gt;-->
<!--              &lt;!&ndash;                :text="t('requestForDocument')"&ndash;&gt;-->
<!--              &lt;!&ndash;              />&ndash;&gt;-->
<!--              <title-secondary-->
<!--                class="documents-page__title"-->
<!--                :text="t('requestForDocument')"-->
<!--              />-->
<!--              <div class="income-page__info">-->
<!--                {{ t('requestForDocumentText') }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="margin-top: 20px;">-->
<!--              <n-form-->
<!--                ref="formRef"-->
<!--                :model="formValue"-->
<!--                :rules="formRules"-->
<!--              >-->
<!--                <div class="document-add-form" style="display: flex; gap: 16px;">-->
<!--                  <n-form-item-->
<!--                    path="document"-->
<!--                    style="width: 40%;"-->
<!--                    class="document-add-form-select"-->
<!--                  >-->
<!--                    <n-select-->
<!--                      v-model:value="formValue.category"-->
<!--                      :options="categoryList"-->
<!--                      :placeholder="t('selectDocument')"-->
<!--                      style="border-color: red"-->
<!--                      :css-vars="{-->
<!--                      '&#45;&#45;n-border-hover': 'blue'-->
<!--                      }"-->
<!--                    />-->
<!--                  </n-form-item>-->

<!--                  <form-field-->
<!--                    v-model="commentFieldValue"-->
<!--                    placeholder="Напишите комментарий, если нужно"-->
<!--                  />-->

<!--                  <primary-button-->
<!--                    :label="t('request')"-->
<!--                  />-->
<!--                </div>-->
<!--              </n-form>-->

<!--            </div>-->

<!--          </div>-->
<!--          <info-card-->
<!--            v-if="false"-->
<!--            title="Сейчас в списке документов не отображаются акты"-->
<!--            subtitle="Чтобы мы могли отслеживать статусы актов, а вы подписывать их в личном-->
<!--              кабинете,введите ваш идентификатор «Диадока» в настройках аккаунта."-->
<!--            linkText="Перейти в настройки аккаунта"-->
<!--            url="/profile"-->
<!--          />-->
<!--        </div>-->
        <div class="documents-page__table">
          <documents-table
            :documents="filteredDocuments"
            :is-diadoc="isDiadocUser"
            :category-list="categoryList"
            :unit-list="unitList"
            :location-list="locationList"
            :is-stub="!allDocuments.length"
            :is-investor="isInvestor"
            @download-doc="downloadDocHandler"
            @upload-doc="uploadDocHandler"
            @on-filter-change="filterHandler"
          />
        </div>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
// import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
// import InfoCard from '@/ui-kit/InfoCard/InfoCard.vue';
import ContentLayout from '@/layouts/ContentLayout.vue';
import DocumentsTable from '@/components/DocumentsTable/DocumentsTable.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
// import { NSelect } from 'naive-ui';
// import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';
// import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
// import FormField from '@/ui-kit/FormField/FormField.vue';
import translates from './translates';

export default {
  name: 'DocumentsPage',
  components: {
    // FormField,
    // PrimaryButton,
    // TitleMain,
    ContentLayout,
    // InfoCard,
    DocumentsTable,
    // NSelect,
    // TitleSecondary,
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const documentsState = store.state.documents;
    const userState = store.state.user;
    const menuItems = computed(() => userState.sideMenuItems);
    const isInvestor = computed(() => userState.isInvestor);
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const allDocuments = computed(() => [...documentsState.userDocuments,
      ...documentsState.currentLocationDocuments]);
    const filteredDocuments = computed(() => documentsState.filteredDocuments);
    const isDiadocUser = computed(() => userState.isDiadoc);
    const categoryList = computed(() => Array.from(new Set(allDocuments.value
      .map(({ category: { id, name } }) => JSON.stringify({
        id,
        label: name,
      }))), JSON.parse));
    const locationList = computed(() => Array.from(new Set(allDocuments.value
      .map(
        (doc) => doc.location
          ? JSON.stringify({
            id: doc.location.id,
            label: doc.location.name,
          })
          : JSON.stringify({}),
      )), JSON.parse));
    const unitList = computed(() => isInvestor.value ? Array.from(new Set(allDocuments.value
      .map(
        (doc) => doc.unit
          ? JSON.stringify({
            id: doc.unit.id,
            label: doc.unit.name,
          })
          : JSON.stringify({}),
      )), JSON.parse) : []);

    const defaultFormValue = {
      newsImageUploadId: 0,
      category: null,
    };

    const formValue = ref({ ...defaultFormValue });

    const formRules = ref({
      category: {
        required: true,
        message: 'Пожалуйста, выберите роль', // Please select a category
      },
    });
    const commentFieldValue = ref('');

    const initDocumentsPage = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('user/initSideMenuItems');
    };

    const locationChangeHandler = async (id) => {
      store.dispatch('user/setCurrentLocation', id);
      await initDocumentsPage();
    };

    const downloadDocHandler = async (id) => {
      await store.dispatch('documents/downloadDoc', id);
    };
    const uploadDocHandler = async (payload) => {
      await store.dispatch('documents/uploadDoc', payload);
    };

    const filterHandler = (event) => {
      store.dispatch('documents/filterDocuments', event);
    };

    initDocumentsPage();

    return {
      t,
      userUnits,
      isDiadocUser,
      menuItems,
      userLocations,
      currentUserLocation,
      allDocuments,
      filteredDocuments,
      categoryList,
      locationList,
      unitList,
      isInvestor,
      downloadDocHandler,
      locationChangeHandler,
      uploadDocHandler,
      filterHandler,
      formValue,
      formRules,
      commentFieldValue,
    };
  },
};
</script>

<style lang="scss">

.document-add-form {
  @media screen and (max-width: $tablet-breakpoint) {
    display: flex;
    flex-direction: column;
  }
}

.document-add-form-select {
  @media screen and (max-width: $tablet-breakpoint) {
    width: 100% !important;
  }
}

.primary-button {
  @media screen and (max-width: $tablet-breakpoint) {
    width: 100% !important;
  }
}

.documents-page {

  &__add-content {
    background: $grey100;
    padding: 24px;
    width: 100%;
    margin-top: 12px;

    .form-container {
      display: flex;
      gap: 16px;

      @media screen and (max-width: $tablet-breakpoint) {
        flex-direction: column; // Change to column on mobile
      }
    }

    .form-item {
      width: 100%; // Set the width to 100% for mobile
    }
  }

  &__content-block {
    width: 100%;
    //padding-right: 25px;
    display: flex;
    flex-direction: column;
  }

  &__table {
    margin-top: 16px;
    overflow: auto;
  }

  &__title {
    &-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      .info-card {
        margin-top: 16px;
      }
    }
  }

  &__subtitle {
    margin-top: 8px;
    color: $grey800;
    font-size: $font-size-regular;
    line-height: 18px;
    font-weight: $font-weight-regular;
  }

  &__tabs-block {
    margin-top: 60px;

    .tabs__list {
      border-bottom: 1px solid $grey200;
    }
  }

}

@media screen and (max-width: $tablet-breakpoint) {
  .documents-page {
    &__table {
      margin-top: 24px;
    }
  }
}
</style>
