<template>
  <div class="inventory-table">
    <div class="inventory-table__documents">
      <div class="inventory-table__inner">
        <div class="inventory-table__header">
          <div class="inventory-table__row">
            <div class="inventory-table__cell inventory-table__cell--name">
              <div class="inventory-table__cell-content">
                Название
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--number">
              <div class="inventory-table__cell-content">
                Инвентарный номер
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--cost">
              <div class="inventory-table__cell-content">
                <secondary-button
                  dashed
                  uppercase
                  label="Стоимость"
                  @click="sortByCost"
                  class="inventory-table__desktop-button"
                >
                  <template #iconBefore>
                    <div
                      class="inventory-table__sort-icon"
                      :class="{
                        'inventory-table__sort-icon--desc': sortOrder === 'desc',
                      }"
                    >
                      <svg width="7" height="8" viewBox="0 0 7 8"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.5 8L7 4.5L6.299 3.8L4 6.098L4 2.62268e-07L3 3.49691e-07L3
                            6.098L0.701 3.8L-3.0598e-07 4.5L3.5 8Z"/>
                      </svg>
                    </div>
                  </template>
                </secondary-button>
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--date">
              <div class="inventory-table__cell-content">
                Дата инвентаризации
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--status">
              <div class="inventory-table__cell-content">
                Статус
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--media">
              <div class="inventory-table__cell-content">
                Актуальное фото
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="inventoryItems && inventoryItems.length"
          class="inventory-table__body"
        >
          <div
            v-for="(inventoryItem) in filteredList"
            :key="inventoryItem.ItemID"
            class="inventory-table__row"
          >
            <div class="inventory-table__cell inventory-table__cell--name">
              <div class="inventory-table__cell-content">
                  {{ inventoryItem.ItemName }}
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--number">
              <div class="inventory-table__cell-content">
                {{ inventoryItem.InvNumber }}
              </div>
            </div>
            <div class="document-table__cell inventory-table__cell--cost">
              <div class="inventory-table__cell-content">
                {{ inventoryItem.Cost }} ₽
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--date">
              <div class="inventory-table__cell-content">
                {{ inventoryItem.preparedDate }}
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--status">
              <div class="inventory-table__cell-content">
                <ui-label
                  v-if="inventoryItem.InvStatus !== undefined"
                  :label="inventoryItem.InvStatus ? 'Обнаружено' : 'Не обнаружено'"
                  :type="String(!!inventoryItem.InvStatus)"
                />
              </div>
            </div>
            <div class="inventory-table__cell inventory-table__cell--media">
              <div class="inventory-table__cell-content">
                <secondary-button
                  @click="mediaButtonHandler(inventoryItem.ItemID)"
                >
                  <template #iconBefore>
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.2 9.99977C10.92 9.99977 11.5 9.41977 11.5 8.69977V2.72977C11.5
                      2.00977 10.92 1.42977 10.22 1.42977H8.54C8.54 1.42977 8.45 1.39977 8.44
                      1.36977L8.13 0.729766C7.92 0.289766 7.47 0.00976562 6.98 0.00976562H5.04C4.56
                      0.00976562 4.11 0.289766 3.89 0.729766L3.58 1.35977C3.58 1.35977 3.52 1.42977
                      3.47 1.42977H1.8C1.08 1.42977 0.5 2.00977 0.5 2.72977V8.70977C0.5 9.41977 1.08
                      10.0098 1.8 10.0098H10.2V9.99977ZM6 3.54977C7.08 3.54977 7.95 4.43977 7.95
                      5.51977C7.95 6.59977 7.08 7.48977 6 7.48977C4.92 7.48977 4.05 6.59977 4.05
                      5.51977C4.05 4.43977 4.93 3.54977 6 3.54977Z" fill="#89909E"/>
                    </svg>
                  </template>
                </secondary-button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="inventory-table__body"
        >
          <stub-block-no-data
            style="margin-top: 30px"
            :title="`Пока для этого юнита нет информации об инвенторизации`"
            :sub-title="`Позже вы сможете увидеть на этой странице предметы из ваших юнитов.`"
          >
            <template #icon>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043
                21.8946 0.734784 22 1 22H24V20H2Z"/>
                <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z"/>
                <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 157H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478
                11 8V19H16V8Z"/>
                <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652 3
                22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z"/>
              </svg>
            </template>
          </stub-block-no-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
// import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';

export default {
  name: 'InventoryTable',
  components: {
    UiLabel,
    SecondaryButton,
    // FilterDropdown,
    StubBlockNoData,
  },
  props: {
    inventoryItems: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['mediaRequest'],
  setup(props, { emit }) {
    const sortOrder = ref(null);
    const filteredList = computed(() => props.inventoryItems && props.inventoryItems.length
      ? [...props.inventoryItems].sort((a, b) => {
        if (sortOrder.value === 'asc') {
          return Number(a.Cost ? new Date(a.Cost).getTime() : 0)
            - Number(b.Cost ? new Date(b.Cost).getTime() : 0);
        }
        return Number(b.Cost ? new Date(b.Cost).getTime() : 0)
          - Number(a.Cost ? new Date(a.Cost).getTime() : 0);
      })
      : []);

    const sortByCost = () => {
      if (sortOrder.value === null) {
        sortOrder.value = 'desc';
      } else {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      }
    };

    const mediaButtonHandler = (e) => {
      emit('mediaRequest', e);
    };

    return {
      sortOrder,
      filteredList,
      sortByCost,
      mediaButtonHandler,
    };
  },
};
</script>

<style lang="scss">
.inventory-table {
  min-width: 1024px;

  &__controls {
    display: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    color: $main-black;
    font-weight: $font-weight-regular;
    font-size: $font-size-regular;
  }

  &__header {
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    .secondary-button, .filter-button__label {
      height: 24px;
      color: $main-black;
      font-size: $font-size-tiny;
      font-weight: $font-weight-half-bold;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .filter-button__value {
      font-weight: $font-weight-medium;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    background-color: #fff;
  }

  &__cell {
    flex-grow: 1;
    text-align: left;

    &-content {
      display: flex;
      padding: 12px 18px 12px 12px;
    }

    &-text {
      margin-left: 4px;
      font-size: $font-size-regular;
      line-height: 16px;
      font-weight: $font-weight-regular;

      &--alert {
        color: $red500;
      }

      &--grey {
        color: $grey700;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      //align-items: flex-start;
      width: 132px;
      flex-shrink: 0;
    }

    &-label {
      margin-left: 6px;
    }

    &--name {
      max-width: 288px;
      min-width: 288px;
    }

    &--date {
      max-width: 120px;
      min-width: 120px;
    }

    &--number {
      min-width: 155px;
      max-width: 155px;
    }

    &--status {
      min-width: 147px;
      max-width: 147px;
    }

    &--media {
      .inventory-table__cell-content {
        justify-content: flex-end;
      }

      svg {
        fill: $grey700;
      }

      .secondary-button {
        width: 24px;
        height: 24px;
        padding: 0;
        justify-content: center;

        &__icon {
          margin: 0;
        }
      }
    }

    &--cost {
      min-width: 140px;
      max-width: 140px;

      .inventory-table__cell-content {
        justify-content: flex-end;
      }
    }
  }

  &__body {
    .inventory-table__cell {
      &--number {
        color: $grey700;
      }
    }
  }

  &__tablet-content {
    display: none;
  }

  &__stub {
    .inventory-table {
      &__header {
        color: $grey400;
      }

      &__body {
        padding-top: 85px;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
  }

  &__documents {
    .inventory-table {
      &__row {
        border-bottom: 1px solid $grey200;

        // &:first-child {
        //   border-top: 1px solid #eff2f6;
        // }
        &:nth-child(odd) {
          background-color: $grey100;
        }
      }

      &__header {
        .inventory-table__row {
          background-color: #fff;
        }

        .inventory-table__cell {
          display: flex;
          align-items: center;
          width: 100%;

          .inventory-table__cell-content {
            width: 100%;
            padding-bottom: 6px;
          }
        }
      }
    }
  }

  &__sort-icon {
    &--desc {
      transform: rotate(180deg);
    }
  }

  &__dot-button {
    .popup-wrapper {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__popup {
    &-wrapper {
      padding: 16px 20px 20px 20px;
    }

    &-text {
      background: linear-gradient(180deg, #2B54E2 100%, #2B54E2 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      line-height: 18px;
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .inventory-table {
    &__cell {
      &-content {
        padding: 16px 12px 10px;
      }

      &--name {
        max-width: 188px;
        min-width: 188px;
      }

      &--date {
        max-width: 120px;
        min-width: 120px;
      }

      &--number {
        min-width: 155px;
        max-width: 155px;
      }

      &--status {
        min-width: 147px;
        max-width: 147px;
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
    }

    &__controls {
      display: flex;
      width: 340px;
      height: 40px;
      gap: 8px;

      .secondary-button {
        font-size: $font-size-small;
        color: $grey900;
        font-weight: $font-weight-medium;
        line-height: 18px;
      }

      .filter-dropdown {
        .filter-button {
          height: 40px;

          &__label {
            font-size: $font-size-small;
            color: $grey900;
            font-weight: $font-weight-medium;
            line-height: 18px;
          }
        }
      }
    }

    &__tablet-content {
      display: flex;
    }

    &__body {
      .inventory-table__cell {
        &--date {
          .inventory-table__cell-content {
            word-break: break-all;
            line-height: 17px;
            max-width: 65px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .inventory-table {
    &__controls {
      width: 430px;
      gap: 0 12px;

      .filter-button {
        &__label {
          color: $main-black;
          font-weight: $font-weight-medium;
        }
      }

      .secondary-button {
        text-transform: none;
      }
    }

    &__row {
      &--state-alert {
        background-color: $red100!important;
      }
    }
  }
}
</style>
