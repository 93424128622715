import { sendRequest } from '@/helpers/request';
import {
  API_INSPECTION_BY_ID,
} from './api_pathes';

async function getInspectionById(inspectionId) {
  try {
    const resp = await sendRequest(`${API_INSPECTION_BY_ID}`, { inspectionId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[CONTROL] Something went wrong with request to get check by id', error);
    return {};
  }
}

export default {
  getInspectionById,
};
