<template>
  <div
    v-if="chartData.length"
    class="info-chart"
  >
    <div class="info-chart__wrapper">
      <apexchart
        height="100%"
        width="100%"
        :series="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import VueApexcharts from 'vue3-apexcharts';

const STROKE_HEIGHT = '2';

export default {
  name: 'infoChart',
  components: {
    apexchart: VueApexcharts,
  },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      default: 'bar',
    },
    isMultiplyColumns: {
      type: Boolean,
      default: false,
    },
    strokeColors: {
      type: Array,
      default: () => [],
    },
    disableDistibutedColors: {
      type: Boolean,
      default: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    yaxisOptions: {
      type: Array,
      default: () => {},
    },
    customTooltip: {
      type: Array,
      default: () => {},
    },
    categories: {
      type: Array,
      default: () => [],
    },
    rotateLabels: {
      type: Number,
      default: -45,
    },
  },
  setup(props) {
    const additionalOptions = {
      area: {
        stroke: {
          curve: 'straight',
          width: 1,
          colors: ['#2B54E2'],
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    const chartOptions = computed(() => {
      if (!props.data.length) {
        return [];
      }

      return {
        legend: {
          show: false,
        },
        chart: {
          type: props.type,
        },
        plotOptions: {
          bar: {
            distributed: !props.disableDistibutedColors,
          },
        },
        colors: props.strokeColors.length ? props.strokeColors : ['rgb(43, 84, 226)'],
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            shade: 'light',
            opacityFrom: 0.5,
            opacityTo: 0.2,
            shadeIntensity: 0,
            stops: [0, 90, 100],
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: props.data.length > 15 ? 15 : undefined,
          categories: props.categories,
          labels: {
            hideOverlappingLabels: true,
            rotate: props.rotateLabels,
          },
        },
        ...additionalOptions[props.type],
        tooltip: {
          enabled: !props.disableTooltip,
          ...props.customTooltip,
        },
        yaxis: {
          ...props.yaxisOptions,
        },
      };
    });
    const chartData = computed(() => {
      if (props.isMultiplyColumns) {
        return props.data.map((item) => ({
          ...item,
          data: item.data.map((dataItem) => {
            if (!dataItem.amount) {
              return {
                x: dataItem.label,
                y: dataItem.amount,
                name: item.name,
              };
            }
            return {
              x: dataItem.label,
              y: dataItem.amount,
              name: item.name,
              goals: [{
                value: dataItem.amount,
                strokeHeight: STROKE_HEIGHT,
                strokeColor: item.color,
              }],
            };
          }),
        }));
      }

      const preparedData = props.data.map((item) => {
        if (!item.amount) {
          return {
            x: item.label,
            y: item.amount,
            ...item,
          };
        }
        return {
          x: item.label,
          y: item.amount,
          ...item,
          goals: [{
            value: item.amount,
            strokeHeight: STROKE_HEIGHT,
            strokeColor: item.barColor ? item.barColor : item.color,
          }],
        };
      });

      return [{
        data: preparedData,
      }];
    });

    return {
      chartOptions,
      chartData,
    };
  },
};
</script>

<style lang="scss">
.info-chart {
  width: 100%;
  height: 200px;
  &__wrapper {
    margin-top: 28px;
    overflow: visible;
    width: 100%;
    height: 100%;
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 16px;
    color: $grey900;
    background: #fff;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #dbdcde 0%, #c5c6c9 100%);
    box-shadow: 0 7px 13px 0 #ACB0C25E;

    &-label {
      font-size: $font-size-small;
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-sum {
      margin-top: 4px;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
    }

    &-underline {
      margin-top: 12px;
      border-bottom: 1px solid $grey200;
    }
  }

  .apexcharts-toolbar {
    display: none;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .info-chart {
    &__wrapper {
      margin-top: 20px;
    }
  }
}
</style>
