export const WEEKEND_DAYS = ['сб', 'вс'];
export const TIMEZONE_DIFF = 1000 * 3600 * 3;
export const ONE_HOUR = 1000 * 3600;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_YEAR = ONE_DAY * 365;
export const MONTHS_SHORT_NAMES = new Map([
  [1, 'Янв'],
  [2, 'Фев'],
  [3, 'Мар'],
  [4, 'Апр'],
  [5, 'Май'],
  [6, 'Июн'],
  [7, 'Июл'],
  [8, 'Авг'],
  [9, 'Сен'],
  [10, 'Окт'],
  [11, 'Ноя'],
  [12, 'Дек'],
]);
