<template>
  <div class="outcome-table">
    <div
      v-if="outcomes && outcomes.length"
      class="outcome-table__controls"
    >
      <filter-dropdown
        :filter-name="t('category')"
        is-multiple-choice
        :filter-values="categoryList"
        @filter-select="categorySelectHandler"
      >
        <template #icon>
          <svg width="9" height="8" viewBox="0 0 9 8"
               fill="none" xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
                      0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
                      0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
                      1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
                      7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
                      7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
                      3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
                      6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
                      6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
                      1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
                      8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
                      7.58989 0Z" fill="#89909E"/>
          </svg>
        </template>
      </filter-dropdown>
      <secondary-button
        dashed
        uppercase
        :label="t('amount')"
        @click="sortOutcomes"
      >
        <template #iconBefore>
          <div
            class="outcome-table__sort-icon"
            :class="{
                        'outcome-table__sort-icon--desc': sortOrder === 'desc',
                      }"
          >
            <svg width="7" height="8" viewBox="0 0 7 8"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.5 8L7 4.5L6.299 3.8L4 6.098L4 2.62268e-07L3 3.49691e-07L3
                            6.098L0.701 3.8L-3.0598e-07 4.5L3.5 8Z"/>
            </svg>
          </div>
        </template>
      </secondary-button>
    </div>
    <div
      class="outcome-table__outcomes"
      v-if="outcomes && outcomes.length"
    >
      <div class="outcome-table__inner">
        <div class="outcome-table__header">
          <div class="outcome-table__row">

            <div class="outcome-table__cell outcome-table__cell--date">
              <div class="outcome-table__cell-content">
                {{ t('date') }}
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--category">
              <div class="outcome-table__cell-content">
                <filter-dropdown
                  :filter-name="t('category')"
                  is-multiple-choice
                  :filter-values="categoryList"
                  @filter-select="categorySelectHandler"
                >
                  <template #icon>
                    <svg width="9" height="8" viewBox="0 0 9 8"
                         fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
                      0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
                      0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674 1.02037C0.0384408
                      1.18854 0.113844 1.34522 0.228327 1.47137L3.13656 4.6698V7.68627C3.13658
                      7.74523 3.15323 7.803 3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
                      7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159 7.99994
                      3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499 6.67607C5.25168
                      6.6467 5.28129 6.60946 5.30166 6.56711C5.32202 6.52475 5.33261 6.47836 5.33264
                      6.43137V4.6698L8.24087 1.47137C8.35535 1.34522 8.43075 1.18854 8.45793
                      1.02037C8.4851 0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
                      8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025 0.000134153
                      7.58989 0Z" fill="#89909E"/>
                    </svg>

                  </template>
                </filter-dropdown>
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--article">
              <div class="outcome-table__cell-content">
                {{ categoryLabel }}
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--sum">
              <div class="outcome-table__cell-content">
                <secondary-button
                  dashed
                  uppercase
                  :label="t('amount')"
                  @click="sortOutcomes"
                >
                  <template #iconBefore>
                    <div
                      class="outcome-table__sort-icon"
                      :class="{
                        'outcome-table__sort-icon--desc': sortOrder === 'desc',
                      }"
                    >
                      <svg width="7" height="8" viewBox="0 0 7 8"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.5 8L7 4.5L6.299 3.8L4 6.098L4 2.62268e-07L3 3.49691e-07L3
                            6.098L0.701 3.8L-3.0598e-07 4.5L3.5 8Z"/>
                      </svg>
                    </div>
                  </template>
                </secondary-button>
              </div>
            </div>
          </div>
        </div>
        <div class="outcome-table__body">
          <div
            v-for="(outcome, id) in updatedOutcomes"
            :key="`${outcome.date}_${id}`"
            class="outcome-table__row"
          >
            <div class="outcome-table__cell outcome-table__cell--date">
              <div class="outcome-table__cell-content">
                {{ outcome.date }}
                <div class="outcome-table__additional--mobile">
                  <ui-label
                    :label="outcome.category.text"
                    :background="CATEGORY_COLORS[outcome.category.id]"
                  />
                </div>
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--category">
              <div class="outcome-table__cell-content">
                <ui-label
                  :label="outcome.category.text"
                  :background="CATEGORY_COLORS[outcome.category.id]"
                />
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--article">
              <div class="outcome-table__cell-content">
                {{ outcome.article }}
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--sum">
              <div class="outcome-table__cell-content">
                {{ outcome.amountText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="outcome-table__stub"
    >
      <div class="outcome-table__inner">
        <div class="outcome-table__header">
          <div class="outcome-table__row">
            <div class="outcome-table__cell outcome-table__cell--date">
              <div class="outcome-table__cell-content">
                {{ t('date') }}
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--category">
              <div class="outcome-table__cell-icon">
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.58989 0H0.879307C0.708949 0.000134153 0.542294 0.0497129
                    0.399565 0.142719C0.256837 0.235726 0.144173 0.368161 0.075246
                    0.523951C0.00631945 0.679742 -0.0159059 0.85219 0.0112674
                    1.02037C0.0384408 1.18854 0.113844 1.34522 0.228327
                    1.47137L3.13656 4.6698V7.68627C3.13658 7.74523 3.15323 7.803
                    3.18458 7.85294C3.21593 7.90288 3.26072 7.94297 3.31381
                    7.96862C3.35625 7.98955 3.40297 8.00029 3.45028 7.99999C3.52159
                    7.99994 3.59075 7.9756 3.64636 7.93098L4.2346 7.46039L5.21499
                    6.67607C5.25168 6.6467 5.28129 6.60946 5.30166 6.56711C5.32202
                    6.52475 5.33261 6.47836 5.33264 6.43137V4.6698L8.24087
                    1.47137C8.35535 1.34522 8.43075 1.18854 8.45793 1.02037C8.4851
                    0.85219 8.46288 0.679742 8.39395 0.523951C8.32502 0.368161
                    8.21236 0.235726 8.06963 0.142719C7.9269 0.0497129 7.76025
                    0.000134153 7.58989 0Z"
                    fill="#424551"
                  />
                </svg>
              </div>
              <div class="outcome-table__cell-content">
                {{ t('category') }}
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--article">
              <div class="outcome-table__cell-content">
                {{ categoryLabel }}
              </div>
            </div>
            <div class="outcome-table__cell outcome-table__cell--sum">
              <div class="outcome-table__cell-icon">
                <svg
                  width="7"
                  height="8"
                  viewBox="0 0 7 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.5 8L7 4.5L6.299 3.8L4 6.098L4 2.62268e-07L3 3.49691e-07L3
                    6.098L0.701 3.8L-3.0598e-07 4.5L3.5 8Z"
                    fill="#424551"
                  />
                </svg>
              </div>
              <div class="outcome-table__cell-content">
                {{ t('amount') }}
              </div>
            </div>
          </div>
        </div>
        <div class="outcome-table__body">
          <stub-block-no-data
            :title="t('stubTitle', { msg: isIncome ? t('income', 2) : t('outcome', 2) })"
            :sub-title="t('stubSubTitle', { msg: isIncome ? t('income', 2) : t('outcome', 2) })"
          >
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20V3H0V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946
                0.734784 22 1 22H24V20H2Z" fill="#89909E"/>
                <path d="M9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11
                8 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4
                12V19H9V12Z" fill="#89909E"/>
                <path d="M16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652
                7 15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11
                8V19H16V8Z" fill="#89909E"/>
                <path d="M23 4C23 3.73478 22.8946 3.48043 22.7071 3.29289C22.5196 3.10536 22.2652
                3 22 3H19C18.7348 3 18.4804 3.10536 18.2929 3.29289C18.1054 3.48043 18 3.73478 18
                4V19H23V4Z" fill="#89909E"/>
              </svg>
            </template>
          </stub-block-no-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import StubBlockNoData from '@/components/StubBlockNoData/StubBlockNoData.vue';
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown.vue';
import { updateMoneySum } from '@/helpers/common';
import { useI18n } from 'vue-i18n';
import translates from './translates';

const CATEGORY_COLORS = {
  372384: '#ECEEF1',
  372392: '#FFF4DE',
  372390: '#E9F5F0',
  372391: '#D3E3FF',
  372385: '#D3FFDD',
  372382: '#D3FFDD',
  630158: '#FFF4DE',
  372381: '#E9F5F0',
  630159: '#D3E3FF',
};

const updateOutcomesList = (list) => list.map((item) => ({
  ...item,
  amountText: `${updateMoneySum(Number(item.amount))} ₽`,
}));
export default {
  name: 'OutcomeTable',
  components: {
    StubBlockNoData,
    UiLabel,
    SecondaryButton,
    FilterDropdown,
  },
  props: {
    outcomes: {
      type: Array,
      default: () => [],
    },
    isIncome: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const updatedOutcomes = ref(props.outcomes && props.outcomes.length
      ? updateOutcomesList(props.outcomes)
      : []);
    const sortOrder = ref(null);
    const categoryList = ref([]);

    const sortOutcomes = () => {
      if (sortOrder.value === null) {
        sortOrder.value = 'desc';
      } else {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      }

      updatedOutcomes.value.sort((a, b) => sortOrder.value === 'asc'
        ? Number(a.amount) - Number(b.amount)
        : Number(b.amount) - Number(a.amount));
    };

    const categorySelectHandler = (categories) => {
      if (categories.length) {
        updatedOutcomes.value = updateOutcomesList(props.outcomes
          .filter((outcome) => categories.some((category) => category.id === outcome.category.id)));
        return;
      }

      updatedOutcomes.value = updateOutcomesList(props.outcomes);
    };

    const categoryLabel = computed(() => (props.isIncome ? t('incomeItem') : t('outcomeItem')));

    watch(() => props.outcomes, () => {
      updatedOutcomes.value = props.outcomes && props.outcomes.length
        ? updateOutcomesList(props.outcomes)
        : [];

      categoryList.value = [];

      props.outcomes.forEach((outcome) => {
        if (categoryList.value.findIndex((item) => item.id === outcome.category.id) < 0) {
          categoryList.value.push({
            id: outcome.category.id,
            label: outcome.category.text,
          });
        }
      });
    });

    return {
      t,
      CATEGORY_COLORS,
      updatedOutcomes,
      sortOrder,
      categoryList,
      categoryLabel,
      sortOutcomes,
      categorySelectHandler,
    };
  },
};
</script>

<style lang="scss">
.outcome-table {
  &__controls {
    display: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    color: $main-black;
    font-weight: $font-weight-regular;
    font-size: $font-size-regular;
  }

  &__header {
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    .secondary-button, .filter-button__label {
      height: 24px;
      color: $main-black;
      font-size: $font-size-tiny;
      font-weight: $font-weight-half-bold;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .filter-button__value {
      font-weight: $font-weight-medium;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    background-color: #fff;
  }

  &__cell {
    flex-grow: 1;
    text-align: left;

    &-content {
      display: flex;
      padding: 12px 24px 12px 12px;
    }

    &--date {
      max-width: 117px;
      min-width: 117px;
    }

    &--category {
      min-width: 186px;
      max-width: 186px;
    }

    &--article {
      flex-grow: 2;

      .outcome-table__cell-content {
        max-width: calc(100% - 50px);
      }
    }

    &--sum {
      max-width: 120px;
      min-width: 120px;
      text-align: right;
    }
  }

  &__additional {
    &--mobile {
      display: none;
    }
  }

  &__stub {
    .outcome-table {
      &__header {
        color: $grey400;
      }

      &__body {
        padding-top: 85px;
      }

      &__cell {
        &-icon {
          display: none;
        }
      }
    }
  }

  &__outcomes {
    .outcome-table {
      &__row {
        &:nth-child(odd) {
          background-color: $grey100;
        }
      }

      &__header {
        .outcome-table__row {
          background-color: #fff;
        }

        .outcome-table__cell {
          display: flex;
          align-items: center;
          width: 100%;

          .outcome-table__cell-content {
            width: 100%;
            padding-bottom: 6px;
          }

          &--sum {
            .outcome-table__cell-content {
              justify-content: flex-end;
              padding-right: 0;
            }
          }
        }
      }

      &__body {
        .outcome-table {
          &__cell {
            &--sum {
              .outcome-table__cell-content {
                justify-content: flex-end;
                padding-right: 14px;
              }
            }
          }
        }
      }
    }
  }

  &__sort-icon {
    &--desc {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .outcome-table {
    &__cell {
      &-content {
        padding: 16px 12px 10px;
      }

      &--article {
        .outcome-table__cell {
          &-content {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .outcome-table {
    &__controls {
      display: flex;
      gap: 0 12px;

      .filter-button {
        &__label {
          color: $main-black;
          font-weight: $font-weight-medium;
        }
      }

      .secondary-button {
        text-transform: none;
      }
    }

    &__outcomes {
      margin-top: 12px;
      overflow-x: auto;
    }

    &__inner {
      width: fit-content;
      max-width: initial;
    }

    &__header {
      display: none;
    }

    &__additional {
      &--mobile {
        margin-top: 4px;
        display: block;
      }
    }

    &__cell {
      &-content {
        flex-direction: column;
        font-size: $font-size-small;
      }

      &--category {
        display: none;
      }

      &--date {
        max-width: 150px;
        min-width: 150px;
      }

      &--article {
        max-width: 216px;
        min-width: 216px;
      }

      &--sum {
        max-width: 108px;
        min-width: 108px;
      }
    }

    &__stub {
      .outcome-table {
        &__body {
          padding-top: 0;
          margin-top: 20px;
        }

        &__header {
          display: flex;
        }

        &__cell {
          margin-left: 12px;
          flex-grow: 0;
          text-transform: capitalize;
          align-items: center;
          justify-content: center;
          padding: 11px 12px;
          border: 1px dashed $grey400;
          border-radius: 29px;

          &:first-child {
            margin-left: 0;
          }

          &-content {
            padding: 0;
          }

          &-icon {
            display: block;
            margin-right: 5px;

            svg {
              path {
                fill: $grey400;
              }
            }
          }

          &--date {
            display: none;
          }

          &--article {
            display: none;
          }

          &--category {
            display: flex;
            min-width: auto;
            max-width: none;
          }

          &--sum {
            display: flex;
            min-width: auto;
            max-width: none;
          }
        }
      }
    }
  }
}
</style>
