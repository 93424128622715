<template>
  <div class="password-change-form">
    <div
      v-if="!isSuccessView"
      class="password-change-form__from"
    >
      <h3 class="password-change-form__label">
        {{ t('changePassword') }}
      </h3>
      <form-field
        class="password-change-form__input-field"
        input-type="password"
        v-model="passwordOldFieldValue"
        :label="t('oldPassword')"
        :is-error="getOldPasswordFieldError"
        :is-show-message="getOldPasswordFieldError"
        :message="getOldPasswordFieldMessage"
        @focusout="checkOldPassword"
      />
      <div class="password-change-form__underline"></div>
      <form-field
        class="password-change-form__input-field"
        input-type="password"
        v-model="passwordNewFieldValue"
        :label="t('newPassword')"
        :is-error="isNewPasswordFieldError"
      />
      <form-field
        class="password-change-form__input-field"
        input-type="password"
        v-model="passwordNewRepeatFieldValue"
        :label="t('confirmPassword')"
        :is-error="isNewPasswordFieldError"
        :is-show-message="isNewPasswordFieldError"
        :message="passwordNewFieldMessage"
        @focusout="checkNewPassword"
      />
      <div class="password-change-form__underline"></div>
      <div class="password-change-form__buttons-wrapper">
        <SecondaryButton
          class="password-change-form__button"
          :label="t('cancel')"
          @click="cancelPasswordChange"
        >
          <template #iconBefore>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75729 1.75736C-0.582197 4.09685
              -0.582197 7.90315 1.75729 10.2426C4.09677
              12.5821 7.90308 12.5821 10.2426
              10.2426C12.5821 7.90315 12.5821
              4.09685 10.2426 1.75736C7.90308
              -0.582126 4.09677 -0.582126 1.75729
              1.75736ZM8.20962 4.49738L6.70699
              6L8.20962 7.50262C8.4048 7.69781
              8.4048 8.0145 8.20962 8.20969C8.01443
              8.40487 7.69774 8.40487 7.50255
              8.20969L5.99993 6.70706L4.49731
              8.20969C4.30212 8.40487 3.98543 8.40487
              3.79024 8.20969C3.59506 8.0145 3.59506
              7.69781 3.79024 7.50262L5.29287 6L3.79024
              4.49738C3.59506 4.30219 3.59506 3.9855
              3.79024 3.79031C3.98543 3.59513 4.30212
              3.59513 4.49731 3.79031L5.99993
              5.29294L7.50255 3.79031C7.69774 3.59513
              8.01443 3.59513 8.20962 3.79031C8.4048
              3.9855 8.4048 4.30219 8.20962 4.49738Z"
                fill="#89909E"
              />
            </svg>
          </template>
        </SecondaryButton>
        <SecondaryButton
          class="password-change-form__button"
          :label="t('update')"
          green
          @click="tryToChange"
        >
          <template #iconBefore>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 0C2.69147
              0 0 2.69147 0 6C0 9.30853 2.69147 12 6
              12C9.30853 12 12 9.30853 12 6C12 2.69147
              9.30853 0 6 0ZM9.04102 4.72852L5.79099
              7.97845C5.69348 8.07596 5.56549 8.12503
              5.4375 8.12503C5.30951 8.12503 5.18152
              8.07596 5.08401 7.97845L3.45905
              6.35349C3.26349 6.15802 3.26349
              5.84198 3.45905 5.64651C3.65451
              5.45096 3.97046 5.45096 4.16602
              5.64651L5.4375 6.918L8.33405 4.02155C8.52951
              3.82599 8.84546 3.82599 9.04102
              4.02155C9.23648 4.21701 9.23648 4.53296
              9.04102 4.72852Z"
                fill="#5DC397"
              />
            </svg>
          </template>
        </SecondaryButton>
      </div>
    </div>
    <div
      v-else
      class="password-change-form__success-view"
    >
      <div class="password-change-form__success-view-title">
        <span class="password-change-form__success-view-title-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3337_2622)">
            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0
            5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#5DC397"/>
            <path d="M22.0501 5.45605L11.4375 16.4436L11.4563 17.6249H11.9438L23.4376
            8.36231C23.1001 7.33108 22.6313 6.33732 22.0501 5.45605Z" fill="#219C67"/>
            <path d="M23.775 4.93109L21.6562 2.83107C21.3749 2.5498 20.9062 2.5498 20.6062
            2.83107L11.5499 12.1874L7.63108 8.32485C7.34982 8.04359 6.88107 8.04359 6.5811
            8.32485L4.70607 10.1811C4.4248 10.4624 4.4248 10.9311 4.70607 11.2124L10.9686
            17.4186C11.1374 17.5874 11.3624 17.6436 11.5874 17.6249C11.8124 17.6436 12.0374 17.5874
            12.2061 17.4186L23.775 5.98108C24.0562 5.68106 24.0562 5.21231 23.775 4.93109Z"
                  fill="#5063C3"/>
            <path d="M12.2064 17.4184L23.7752 5.98087C24.0565 5.6996 24.0565 5.23086 23.7752
            4.94959L23.4377 4.63086L11.5689 16.2934L5.00638 9.89965L4.72511 10.1809C4.44384 10.4622
            4.44384 10.9309 4.72511 11.2122L10.9877 17.4185C11.1564 17.5873 11.3814 17.6435 11.6064
            17.6247C11.8127 17.6434 12.0377 17.5872 12.2064 17.4184Z" fill="#5377BA"/>
          </g>
          <defs>
            <clipPath id="clip0_3337_2622">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        </span>
        <span class="password-change-form__success-view-title-text">
          {{ t('wasChange') }}
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import FormField from '@/ui-kit/FormField/FormField.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import {
  PASSWORD_VALID_REGEXP,
} from '@/constants/auth';
import {
  computed,
  inject,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import translate from './translate';

export default {
  name: 'PasswordChangeForm',
  emits: ['change-password', 'cancel-password-change'],
  components: {
    FormField,
    SecondaryButton,
  },
  props: {
    successView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translate.en);
    mergeLocaleMessage('ru', translate.ru);
    const globalErrorMessage = ref(inject('globalErrorMessage').value);
    const isSuccessView = ref(false);
    const isOldPasswordFieldError = ref(false);
    const isNewPasswordFieldError = ref(false);
    const passwordOldFieldMessage = ref('');
    const passwordNewFieldMessage = ref('');
    const passwordOldFieldValue = ref('');
    const passwordNewFieldValue = ref('');
    const passwordNewRepeatFieldValue = ref('');

    const getOldPasswordFieldMessage = computed(() => (globalErrorMessage.value.length
      ? globalErrorMessage.value : passwordOldFieldMessage.value));

    const getOldPasswordFieldError = computed(() => (!!globalErrorMessage.value.length
       || isOldPasswordFieldError.value));

    const cancelPasswordChange = () => {
      emit('cancel-password-change');
    };

    const checkOldPassword = () => {
      isOldPasswordFieldError.value = !passwordOldFieldValue.value.match(PASSWORD_VALID_REGEXP);
      passwordOldFieldMessage.value = isOldPasswordFieldError.value
        ? t('errors.passwordNotValid') : '';
    };

    const checkNewPassword = () => {
      // globalErrorMessage.value = '';
      const notValidPassword = !passwordNewFieldValue.value.match(PASSWORD_VALID_REGEXP);
      const wrongRepeated = passwordNewRepeatFieldValue.value
        !== passwordNewFieldValue.value;
      const oldIsSameAsNew = passwordOldFieldValue.value === passwordNewFieldValue.value;

      isNewPasswordFieldError.value = notValidPassword || wrongRepeated || oldIsSameAsNew;

      if (wrongRepeated) {
        passwordNewFieldMessage.value = t('errors.passwordRepeatIncorrect');
        return;
      }

      if (notValidPassword) {
        passwordNewFieldMessage.value = t('errors.passwordNotValid');
        return;
      }

      if (oldIsSameAsNew) {
        passwordNewFieldMessage.value = t('errors.passwordNewIsSameAsOld');
        return;
      }

      passwordNewFieldMessage.value = '';
    };

    const tryToChange = () => {
      checkOldPassword();
      checkNewPassword();

      if (isNewPasswordFieldError.value || isOldPasswordFieldError.value) {
        return;
      }

      emit('change-password', {
        oldPassword: passwordOldFieldValue.value,
        newPassword: passwordNewFieldValue.value,
      });
    };

    const resetForm = () => {
      isOldPasswordFieldError.value = false;
      isNewPasswordFieldError.value = false;
      passwordOldFieldMessage.value = '';
      passwordNewFieldMessage.value = '';
      passwordOldFieldValue.value = '';
      passwordNewFieldValue.value = '';
      passwordNewRepeatFieldValue.value = '';
    };

    watch(() => props.successView, () => {
      if (props.successView) {
        isSuccessView.value = true;
        resetForm();
        setTimeout(() => {
          isSuccessView.value = false;
          emit('cancel-password-change');
        }, 1000);
      }
    });

    return {
      t,
      isSuccessView,
      isOldPasswordFieldError,
      isNewPasswordFieldError,
      passwordOldFieldMessage,
      passwordNewFieldMessage,
      passwordOldFieldValue,
      passwordNewFieldValue,
      passwordNewRepeatFieldValue,
      getOldPasswordFieldMessage,
      getOldPasswordFieldError,
      checkNewPassword,
      checkOldPassword,
      tryToChange,
      cancelPasswordChange,
    };
  },
};
</script>

<style scoped lang="scss">
.password-change-form {
  width: 403px;

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__success-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;

    &-title {
      display: flex;
      align-items: center;
      justify-content: center;

      color: $main-black;
      font-size: $font-size-title;
      font-weight: $font-weight-bold;

      &-icon {
        height: 28px;
        margin-right: 12px;
      }

      &-text {
        line-height: 28px;
      }
    }
  }

  &__label {
    margin: 0;
    padding: 0;
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
    line-height: 28px;
  }

  &__input-field {
    margin-top: 24px;
  }

  &__underline {
    margin-top: 24px;
    width: 100%;
    border-bottom: 1px solid $grey200;
  }

  &__buttons-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 450px) {
  .password-change-form {
    width: 90vw;

    &__input-field {
      line-height: 20px;
      font-size: $font-size-regular;
    }
  }
}
</style>
