<template>
  <footer class="main-footer">
    <ul class="main-footer__external-links-list">
      <li class="main-footer__external-links-list-item">
        <a href="https://viewga.ltd/" class="main-footer__external-links-link" target="_blank">
          <span>{{ t('investWebsite') }}</span>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="#424551" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5557_2492)">
              <path d="M7.19861 3.97516H6.63359C6.51658 3.97516 6.42171 4.07002 6.42171
              4.18704V6.76311C6.42171 6.89985 6.31043 7.0111 6.1737 7.0111H1.23668C1.09997
              7.0111 0.988777 6.89985 0.988777 6.76311V1.82617C0.988777 1.68941 1.09997 1.57813
              1.23668 1.57813H3.94466C4.06167 1.57813 4.15654 1.48327 4.15654
              1.36625V0.801236C4.15654 0.684222 4.06167 0.589355 3.94466 0.589355H1.23668C0.554761
              0.589355 0 1.1442 0 1.82617V6.76314C0 7.44511 0.554789 7.9999 1.23668
              7.9999H6.17367C6.85565 7.9999 7.41046 7.44509 7.41046 6.76314V4.18706C7.41049 4.07002
              7.31562 3.97516 7.19861 3.97516Z"/>
              <path d="M7.78768 0H5.54025C5.42324 0 5.32837 0.0948661 5.32837
              0.211881V0.776896C5.32837
              0.893911 5.42324 0.988777 5.54025 0.988777H6.31161L3.40898 3.89135C3.32624 3.97409
              3.32624 4.10823 3.40898 4.191L3.80851 4.59055C3.84826 4.6303 3.90213 4.65262 3.95835
              4.65262C4.01454 4.65262 4.06844 4.6303 4.10816 4.59055L7.01079 1.68793V2.45926C7.01079
              2.57627 7.10565 2.67114 7.22267 2.67114H7.78768C7.9047 2.67114 7.99957 2.57627 7.99957
              2.45926V0.211881C7.99957 0.0948661 7.9047 0 7.78768 0Z"/>
            </g>
            <defs>
              <clipPath id="clip0_5557_2492">
                <rect width="8" height="8" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </li>
      <li class="main-footer__external-links-list-item">
        <a href="https://view-ga.ru/" class="main-footer__external-links-link" target="_blank">
          <span>{{ t('guestWebsite') }}</span>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="#424551" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5557_2492)">
              <path d="M7.19861 3.97516H6.63359C6.51658 3.97516 6.42171 4.07002 6.42171
              4.18704V6.76311C6.42171 6.89985 6.31043 7.0111 6.1737 7.0111H1.23668C1.09997
              7.0111 0.988777 6.89985 0.988777 6.76311V1.82617C0.988777 1.68941 1.09997 1.57813
              1.23668 1.57813H3.94466C4.06167 1.57813 4.15654 1.48327 4.15654
              1.36625V0.801236C4.15654 0.684222 4.06167 0.589355 3.94466 0.589355H1.23668C0.554761
              0.589355 0 1.1442 0 1.82617V6.76314C0 7.44511 0.554789 7.9999 1.23668
              7.9999H6.17367C6.85565 7.9999 7.41046 7.44509 7.41046 6.76314V4.18706C7.41049 4.07002
              7.31562 3.97516 7.19861 3.97516Z"/>
              <path d="M7.78768 0H5.54025C5.42324 0 5.32837 0.0948661 5.32837
              0.211881V0.776896C5.32837
              0.893911 5.42324 0.988777 5.54025 0.988777H6.31161L3.40898 3.89135C3.32624 3.97409
              3.32624 4.10823 3.40898 4.191L3.80851 4.59055C3.84826 4.6303 3.90213 4.65262 3.95835
              4.65262C4.01454 4.65262 4.06844 4.6303 4.10816 4.59055L7.01079 1.68793V2.45926C7.01079
              2.57627 7.10565 2.67114 7.22267 2.67114H7.78768C7.9047 2.67114 7.99957 2.57627 7.99957
              2.45926V0.211881C7.99957 0.0948661 7.9047 0 7.78768 0Z"/>
            </g>
            <defs>
              <clipPath id="clip0_5557_2492">
                <rect width="8" height="8" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </li>
    </ul>
    <ul class="main-footer__links-list">
      <li class="main-footer__links-list-item">
        <a
          href="../../../policy.pdf"
          target="_blank"
          class="main-footer__links-link"
        >
          {{ t('privacy') }}
        </a>
      </li>
    </ul>
    <ul class="main-footer__credits">
      <li class="main-footer__credits-item">
        {{ t('madeBy') }} <a href="https://dewis.ltd" target="_blank">dewis group</a>
      </li>
      <li class="main-footer__credits-item main-footer__credits-item--copyright">
        ©2024 inwis
      </li>
    </ul>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';
import transcription from './transcription';

export default {
  name: 'main-footer',
  setup() {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', transcription.en);
    mergeLocaleMessage('ru', transcription.ru);

    return {
      t,
    };
  },
};
</script>

<style scoped lang="scss">
.main-footer {
  display: flex;
  width: 100%;
  padding: 12px 30px 12px 20px;
  margin-top: 32px;
  justify-content: space-between;
  align-items: center;
  background-color: $grey100;

  &__links-list, &__credits, &__external-links-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      color: $grey800;
      font-weight: $font-weight-medium;
      font-size: $font-size-small;
      line-height: 16px;
    }
  }

  &__links {
    &-list {
      &-item {
        margin: 0 18px;
      }
    }

    &-link {
      text-decoration: none;
      border-bottom: 1px solid $grey300;
      color: $grey800;
      cursor: pointer;
    }
  }

  &__credits {
    &-item {
      margin-left: 25px;

      a {
        color: #6a7383;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
        border-bottom: 1px solid $grey200;
        cursor: pointer;

        :visited {
          color: #6a7383;
          text-decoration: none;
        }
      }

      &--copyright {
        color: $main-black;
      }
    }
  }

  &__external {
    &-links {
      &-link {
        margin: 0;
        border-radius: 12px;
        background-color: transparent;
        color: $grey900;
        fill: $grey900;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        line-height: 18px;
        text-decoration: none;
        transition: 0.4s;

        span {
          margin-right: 4px;
        }

        &:hover {
          cursor: pointer;
          color: $blue600 !important;
          text-decoration: none;

          svg {
            fill: $blue600;
          }
        }
      }

      &-list {
        &-item {
          line-height: 18px;
          margin: 0 18px 0 0;
        }
      }
    }
  }

  a {
    &:hover {
      color: $blue500;

      border-color: $blue500;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .main-footer {
    margin: 0;

    &__external {
      &-links {
        &-list {
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .main-footer {
    flex-direction: column;
    padding: 11px 20px;
    justify-content: flex-start;
    align-items: flex-start;

    &__links-list {
      flex-direction: column;
      margin-top: 24px;

      &-item {
        line-height: 18px;
        margin: 0;
      }
    }

    &__credits {
      margin-top: 24px;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      &-item {
        margin: 0;

        a {
          line-height: 18px;
        }
      }
    }
  }
}
</style>
