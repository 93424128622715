<template>
  <div class="summary-info">
    <InfoHeader
      v-if="header && header.length"
     :text="header"
     :popupData="popupData"
    />
    <div class="summary-info__wrapper">
      <div class="summary-info__box summary-info__box--state-centered">
        <div class="summary-info__text-area">
          <p
            v-if="firstTitle && firstTitle.length"
            class="summary-info__title"
          >
            {{ firstTitle }}
          </p>
          <p class="summary-info__text-value loading-stub__spinner">
            {{ firstInfo }}
          </p>
        </div>
        <metric-result
          class="summary-info__metric-result"
          :status="firstMetric"
        />
      </div>
      <div
        v-if="secondInfo || secondTitle"
        class="summary-info__box summary-info__box--second"
      >
        <p
          v-if="secondTitle && secondTitle.length"
          class="summary-info__title"
        >
          {{ secondTitle }}
        </p>
        <div class="summary-info__text-area">
          <p class="summary-info__text-value loading-stub__spinner">
            {{ secondInfo }}
          </p>
          <p
            class="summary-info__subtitle"
            v-if="secondSubtitle && secondSubtitle.length"
          >
            {{ secondSubtitle }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import MetricResult from '@/ui-kit/MetricResult/MetricResult.vue';

export default {
  name: 'SummaryInfo',
  components: {
    InfoHeader,
    MetricResult,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    firstTitle: {
      type: String,
      default: '',
    },
    firstInfo: {
      type: String,
      default: '',
    },
    firstMetric: {
      type: String,
      default: '',
    },
    secondTitle: {
      type: String,
      default: '',
    },
    secondInfo: {
      type: String,
      default: '',
    },
    secondSubtitle: {
      type: String,
      default: '',
    },
    popupData: {
      type: Object,
      default: () => {},
    },
  },
};

</script>

<style scoped lang="scss">
.summary-info {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;

  &__wrapper {
    display: flex;
    gap: 0 49px;
    margin-top: 25px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    color: $main-black;
    gap: 12px 0;

    &--second {
      color: $grey800;
    }

    &--state-centered {
      justify-content: center;
      flex-direction: row;
    }

    &:not(:first-child) {
      padding-left: 24px;
      border-left: 1px solid $grey200;
    }
  }

  &__title {
    font-size: $font-size-small;
    line-height: 16px;
    margin: 0;
  }

  &__text-value {
    font-size: $font-size-title-large;
    line-height: 24px;
    margin: 0;
  }

  &__subtitle {
    color: $grey800;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    line-height: 16px;
    margin: 0;
    padding: 0;
    margin-top: 5px;
  }

  &__text-area {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
  }

  &__metric-result {
    margin-left: 12px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .summary-info {
    padding-bottom: 0;

    &__wrapper {
      margin-top: 16px;
      gap: 0 9px;

    }

    &__box {
      flex-basis: 0;
      flex-grow: 1;
      gap: 2px 0;

      &:not(:first-child) {
        padding-left: 9px;
      }

      &--state-centered {
        justify-content: flex-start;
      }
    }

    &__text-area {
      gap: 2px 0;
    }

    &__text-value {
      font-size: $font-size-title;
    }
  }
}

@import '/src/styles/loading-stub.scss';
</style>
