import { DOMAIN } from '@/models/constants';

export const EMAIL_VALID_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PASSWORD_VALID_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
export const LOGIN_VIEW = 'login';
export const SIGNUP_VIEW = 'signup';
export const SIGNUP_PASSWORD_CREATE_VIEW = 'password-create-sign-up';
export const RECOVERY_PASSWORD_CREATE_VIEW = 'password-create-recovery';
export const SIGNUP_SUCCESS_VIEW = 'signup-success';
export const RECOVERY_SUCCESS_VIEW = 'recovery-success';
export const PASSWORD_RECOVERY_VIEW = 'password-recovery';
export const USER_TOKEN_KEY = 'user_jwt_token';
export const USER_ROLE_KEY = 'user_role';
export const USER_CURRENT_LOCATION_ID = 'user_current_location_id';
export const USER_CURRENT_LOCALE = 'cookieLocale';
export const USER_CURRENT_ROLE = 'user_current_role';
export const CAPTCHA_SITE_KEY = DOMAIN === 'https://dewis-stage.space/'
  ? '6LeaU94pAAAAANZsRb5zGYzffufU4rKRUUz3n5Y4'
  : '6LeNkfUpAAAAAArRNBEz_OJdrpcIopSQ7c1Tpnta';

export const AUTH_TITLES = {
  [LOGIN_VIEW]: 'Вход',
  [SIGNUP_VIEW]: 'Регистрация',
  [SIGNUP_SUCCESS_VIEW]: 'Проверьте свой e-mail',
  [PASSWORD_RECOVERY_VIEW]: 'Восстановление пароля',
};

export const PLACEHOLDERS = {
  phone: '+7 (999) 000 00 00',
};

export const FORM_TABS = [
  {
    id: 'email',
    label: 'E-mail',
  },
  {
    id: 'phone',
    label: 'Телефон',
  },
];

export const FORM_FIELDS_LABELS = {
  email: 'E-mail',
  password: 'Пароль',
  phone: 'Телефон',
};

export const FIELD_MESSAGES = {
  errors: {
    emailValidation: 'Это непохоже на email-адрес.',
    phoneValidation: 'Некорректный номер телефона.',
    passwordEmpty: 'Введите пароль',
    passwordIncorrect: 'Пароль введен не верно',
    passwordRepeatIncorrect: 'Пароли не совпадают.',
    passwordNewIsSameAsOld: 'Новый пароль должен отличаться от старого',
    passwordNotValid: 'Введите от 6 до 20 символов. Пароль должен содержать хотя бы одну цифру, одну заглавную и строчную букву',
    loginError: 'Пользователь с такой комбинацией логина и пароля не найден.',
  },
  info: {
    emailSignup: 'Введите e-mail, который указан в договоре с управляющей компанией',
    phoneSignup: 'Введите телефон, который указан в договоре с управляющей компанией',
  },
};

export const AUTH_SUCCESS_MESSAGES = {
  signUp: 'Мы отправили вам письмо с ссылкой на создание аккаунта',
  recovery: 'Мы отправили вам письмо с ссылкой на создание нового пароля',
};

export const VALID_VIEW_TYPES = [
  LOGIN_VIEW,
  SIGNUP_VIEW,
  SIGNUP_PASSWORD_CREATE_VIEW,
  RECOVERY_PASSWORD_CREATE_VIEW,
  SIGNUP_SUCCESS_VIEW,
  PASSWORD_RECOVERY_VIEW,
  RECOVERY_SUCCESS_VIEW,
];

export const PASSWORD_LABELS = {
  signUp: 'Пароль',
  recovery: 'Новый пароль',
};

export const PASSWORD_REPEAT_LABELS = {
  signUp: 'Пароль еще раз',
  recovery: 'Новый пароль еще раз',
};

export const BUTTON_LABELS = {
  signUp: 'Войти в личный кабинет',
  recovery: 'Сохранить пароль',
};

export const ORIGIN_PROP_LABELS = {
  signUp: 'signUp',
  recovery: 'recovery',
};
