import { sendRequest } from '@/helpers/request';
import {
  API_BOOKING_STATISTICS,
  API_BOOKING_UNITS,
  API_BOOKING_PERIPHERY, API_BOOKING_STATISTICS_PERIPHERY,
  API_BOOKING_LOCATIONS,
} from './api_pathes';

async function getUnitsBooking(payload = {}) {
  if (!payload.location && !payload.startDate) {
    throw new Error('[BOOKING] Have no payload for get units booking.');
  }

  try {
    return await sendRequest(API_BOOKING_UNITS, payload, 'GET');
  } catch (error) {
    console.error('[BOOKING] Something went wrong with request to get units booking API', error);
    return {};
  }
}
async function getPeripheryBooking(payload = {}) {
  if (!payload.location && !payload.startDate) {
    throw new Error('[BOOKING] Have no payload for get periphery booking.');
  }

  try {
    return await sendRequest(API_BOOKING_PERIPHERY, payload, 'GET');
  } catch (error) {
    console.error('[BOOKING] Something went wrong with request to get periphery booking API', error);
    return {};
  }
}

async function getBookingLocations() {
  try {
    return await sendRequest(API_BOOKING_LOCATIONS, null, 'GET');
  } catch (error) {
    console.error('[BOOKING] Something went wrong with request to get booking locations', error);
    return {};
  }
}

async function getBookingStatistics(id) {
  try {
    return await sendRequest(API_BOOKING_STATISTICS, { location: id }, 'GET');
  } catch (error) {
    console.error('[BOOKING] Something went wrong with request to get booking statistics', error);
    return {};
  }
}

async function getBookingPeripheryStatistics(id) {
  try {
    return await sendRequest(API_BOOKING_STATISTICS_PERIPHERY, { location: id }, 'GET');
  } catch (error) {
    console.error('[BOOKING] Something went wrong with request to get booking statistics', error);
    return {};
  }
}

export default {
  getUnitsBooking,
  getPeripheryBooking,
  getBookingPeripheryStatistics,
  getBookingStatistics,
  getBookingLocations,
};
