export default {
  en: {
    lender: 'Loans',
    investor: 'Investments',
  },
  ru: {
    lender: 'Займы',
    investor: 'Инвестиции',
  },
};
