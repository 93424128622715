<template>
  <div
    class="booking-table__main"
    :class="tableClasses"
  >
    <div
      v-if="isLoading"
      class="booking-table__loader"
    >
      <span class="booking-table__loader-icon"/>
    </div>
    <div
      class="booking-table__header"
      ref="bookingHeader"
      @scroll="onHeaderScrollHandler"
    >
      <div
        v-if="isHourly"
        class="booking-table__timeline"
      >
        <div
          class="booking-table__timeline-month-wrapper"
          v-for="month in timeline"
          :key="month.time"
        >
          <div class="booking-table__timeline-month-name">
            <span class="booking-table__label">{{ month.monthName }} {{ month.year }}</span>
          </div>
          <div class="booking-table__timeline-days-wrapper">
            <div
              v-for="day in month.days"
              :key="day.time"
              class="booking-table__timeline-day-block"
            >
              <div class="booking-table__timeline-day-name">
                <span class="booking-table__label">{{ day.date }} {{ day.name }}</span>
              </div>
              <ul
                class="booking-table__timeline-items"
              >
                <li
                  class="booking-table__timeline-item"
                  v-for="hour in 24"
                  :key="`${day.time}_${hour}`"
                  :class="{
                    'booking-table__timeline-item--now': isCurrentHour(hour, day.time),
                  }"
                >
                  <span class="booking-table__timeline-item-num">{{ hour - 1 }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="booking-table__timeline"
      >
        <div
          class="booking-table__timeline-month"
          v-for="month in timeline"
          :key="month.time"
        >
          <div class="booking-table__timeline-month-name">
            <span class="booking-table__label">{{ month.monthName }} {{ month.year }}</span>
          </div>
          <ul
            class="booking-table__timeline-items"
          >
            <li
              class="booking-table__timeline-item"
              :class="{
                  'booking-table__timeline-item--today': day.isToday,
                }"
              v-for="day in month.days"
              :key="day.time"
            >
              <span class="booking-table__timeline-item-num">{{ day.date }}</span>
              <span class="booking-table__timeline-item-name">{{ day.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="booking-table__days-wrapper"
      :class="{
        'booking-table__days-wrapper--padding-bottom': isSmallTable,
      }"
      ref="bookingTable"
      @scroll="onTableScrollHandler"
    >
      <div class="booking-table__days">
        <div
          v-for="(group, groupIndex) in groupedUnits"
          :key="groupIndex + getRandomKey"
          class="booking-table__days-group"
        >
          <div class="booking-table__raw booking-table__free-days">
            <div
              v-for="(item, index) in isHourly
                ? group.availablePeripheryPerHour
                : group.availableUnitsPerDay"
              :key="index + getRandomKey"
              class="booking-table__cell booking-table__cell--header"
              :class="{
                'booking-table__cell--today': item.isToday,
                'booking-table__cell--now': item.isCurrentHour,
              }"
            >
              {{ item.numberOfUnits }}
            </div>
          </div>
          <div
            class="booking-table__raw"
            v-for="(unit, index) in group.units"
            :key="index + getRandomKey"
          >
            <div
              v-for="(item, index) in isHourly ? unit.hours : unit.days"
              :key="index + getRandomKey"
              class="booking-table__cell"
              :class="{
                'booking-table__cell--now': item.isCurrentHour,
                'booking-table__cell--today': item.isToday,
                'booking-table__cell--start': item.isStart,
                'booking-table__cell--end': item.isEnd,
                'booking-table__cell--borderline': item.isBorderline,
              }"
            >
              <popup-wrapper
                v-if="!item.isEmpty"
                arrow
                hover
                :placement="isSmallTable ? 'bottom' : 'auto'"
              >
                <div
                  class="booking-table__book"
                  :class="bookingStatus(item)"
                >
                <span v-if="item.haveManyUnitInDay">{{ item.info.length }}</span>
                </div>
                <template #popperContent>
                  <div class="booking-table__tooltip">
                    <div
                      v-for="(info, index) in item.info"
                      :key="index + getRandomKey"
                      class="booking-table__tooltip-column"
                    >
                      <template
                        v-if="info.status !== 'repair'"
                      >
                        <div class="booking-table__tooltip-row">
                          <span class="booking-table__tooltip-key">
                            {{ isHourly ? t('accommodation') : t('booked') }}
                          </span>
                          <span class="booking-table__tooltip-value">
                            {{ info.datesRangeString }}
                          </span>
                        </div>
                        <div class="booking-table__tooltip-row">
                          <span class="booking-table__tooltip-key">{{ t('guests') }}:</span>
                          <span class="booking-table__tooltip-value">
                          {{ info.guestAmount }}
                        </span>
                        </div>
                        <div class="booking-table__tooltip-row">
                          <span class="booking-table__tooltip-key">{{ t('source') }}:</span>
                          <span class="booking-table__tooltip-value">
                          {{ info.source.name }}
                        </span>
                        </div>
                        <div class="booking-table__tooltip-row">
                          <span class="booking-table__tooltip-key">{{ t('paid') }}:</span>
                          <span class="booking-table__tooltip-value">
                          {{ info.paid }}₽ <span>из {{ info.sum }}₽</span>
                        </span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="booking-table__tooltip-row">
                          <span class="booking-table__tooltip-key">{{ t('renovation') }}:</span>
                          <span class="booking-table__tooltip-value">
                            {{ info.datesRangeString }}
                          </span>
                        </div>
                        <div class="booking-table__tooltip-row">
                          <span class="booking-table__tooltip-key">{{ t('description') }}:</span>
                          <span class="booking-table__tooltip-value">
                            {{ info.description }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </popup-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import popupWrapper from '@/components/PopupWrapper/PopupWrapper.vue';
import { isToday } from '@/helpers/datesAndTime';
import { useI18n } from 'vue-i18n';
import translates from '../translates';

export default {
  name: 'main-grid',
  components: {
    popupWrapper,
  },
  emits: ['scrolledToStart', 'scrolledToEnd'],
  props: {
    groupedUnits: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    timeline: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isHourly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const getRandomKey = computed(() => Math.ceil(Math.random() * 1000000));
    const bookingTable = ref(null);
    const bookingHeader = ref(null);
    let isHeaderScrolling = false;
    let isTableScrolling = false;
    let timeOut = 0;
    let lastScrollLeft = 0;
    let mayLoadAdditionalBookings = true;
    const isSmallTable = computed(() => props.groupedUnits
      .reduce((length, group) => length + group.units.length, 0) < 5);
    const tableClasses = computed(() => {
      const filterClasses = Object.keys(props.filters)
        .reduce((classes, filter) => {
          if (props.filters[filter] || props.filters[filter] === 0) {
            return {
              ...classes,
              [`booking-table-filter-${filter}`]: true,
              [`booking-table-filter-${filter}--${props.filters[filter]}`]: true,
            };
          }

          return classes;
        }, {});
      if (Object.keys(filterClasses).length) {
        filterClasses['booking-table__main--filtered'] = true;
      }

      return filterClasses;
    });

    const isCurrentHour = (hour, day) => {
      const now = new Date();
      return now.getHours() === hour - 1 && isToday(new Date(day));
    };

    const tryToLoadAdditionalBookings = (isNext) => {
      if (!mayLoadAdditionalBookings) {
        return;
      }

      if (isNext) {
        emit('scrolledToEnd');
      } else {
        emit('scrolledToStart');
      }
      mayLoadAdditionalBookings = false;
      setTimeout(() => {
        mayLoadAdditionalBookings = true;
      }, 1500);
    };

    const customDebounce = (tracker) => {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (tracker === 'header') {
          isHeaderScrolling = !isHeaderScrolling;
        } else {
          isTableScrolling = !isTableScrolling;
        }
      }, 700);
    };

    const checkExtremesScrollValues = (element) => {
      if (lastScrollLeft < element.scrollLeft) {
        if (element.offsetWidth + element.scrollLeft + 20 >= element.scrollWidth) {
          tryToLoadAdditionalBookings(true);
        }
      } else if (element.scrollLeft < 20) {
        tryToLoadAdditionalBookings(false);
      }
      lastScrollLeft = element.scrollLeft;
    };

    const onHeaderScrollHandler = (e) => {
      if (!isTableScrolling) {
        isHeaderScrolling = true;
        customDebounce('header');
        bookingTable.value.scrollLeft = e.target.scrollLeft;
        checkExtremesScrollValues(e.target);
      }
    };

    const onTableScrollHandler = (e) => {
      if (!isHeaderScrolling) {
        isTableScrolling = true;
        customDebounce('table');
        bookingHeader.value.scrollLeft = e.target.scrollLeft;
        checkExtremesScrollValues(e.target);
      }
    };

    const bookingStatus = (item) => {
      const filterSourceClasses = {};
      const statusClasses = {};
      if (item.info.length) {
        item.info.forEach((info, index) => {
          filterSourceClasses[`booking-table__book--filter-source-${info.source.type}`] = true;
          statusClasses[`booking-table__book--status-${info.status}-${index}`] = true;
        });
      }
      return {
        'booking-table__book--start': item.isStart,
        'booking-table__book--end': item.isEnd,
        'booking-table__book--full': item.isEnd && item.isStart,
        'booking-table__book--borderline': item.isBorderline,
        'booking-table__book--many': item.haveManyUnitInDay,
        [`booking-table__book--${item.status}`]: item.status,
        ...filterSourceClasses,
        ...statusClasses,
      };
    };

    return {
      t,
      bookingTable,
      bookingHeader,
      getRandomKey,
      tableClasses,
      isSmallTable,
      onHeaderScrollHandler,
      onTableScrollHandler,
      bookingStatus,
      isCurrentHour,
    };
  },
};
</script>
