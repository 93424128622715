<template>
    <MainLayout
      class="two-column-layout"
    >
      <template #navigation>
        <header-menu />
        <personal-menu
          :user-name="userName"
          :menu-items="personalMenuItems"
          :user-pic="userAvatar"
        >
          <template #secondaryContent>
            <user-manager
              :managerData="userManagerData"
            />
          </template>
        </personal-menu>
        <burger-menu
          withPopup
          :popupMenuData="tabletBurgerMenuItems"
        >
          <template #popupContent>
            <lang-toggler
              :preselected-value="currentLocale"
              :locale-list="localesList"
              @lang-change="toggleLangHandler"
            />
            <user-manager
              :managerData="userManagerData"
            />
          </template>
        </burger-menu>
        <lang-toggler
          class="two-column-layout__lang-toggler"
          :preselected-value="currentLocale"
          :locale-list="localesList"
          @lang-change="toggleLangHandler"
        />
        <burger-menu
          @burger-toggle="burgerToggleHandler"
          :is-close="triggerCloseBurger"
        />
      </template>
      <template #content>
        <div class="two-column-layout__mobile-controls">
          <slot name="mobile-controls"></slot>
        </div>
        <div class="two-column-layout__outer-content">
          <mobile-menu-dropdown
            :is-open="isOpenMenuDropdown"
            :item-list="mobileMenuListItems"
            @item-click="mobileMenuClickHandler"
          >
            <template #secondaryContent>
              <lang-toggler
                :preselected-value="currentLocale"
                :locale-list="localesList"
                @lang-change="toggleLangHandler"
              />
              <user-manager
                :managerData="userManagerData"
              />
            </template>
          </mobile-menu-dropdown>
        </div>
        <div class="two-column-layout__inner">
          <div
            class="two-column-layout__main"
            :class="{
            'content__main--no-padding': noPadding,
          }"
          >
            <slot name="main-content"></slot>
          </div>
          <div class="two-column-layout__side">
            <slot name="side-content"></slot>
          </div>
        </div>
        <div class="two-column-layout__after-inner">
          <slot name="after-inner-content"></slot>
        </div>
      </template>
    </MainLayout>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import HeaderMenu from '@/components/HeaderMenu/HeaderMenu.vue';
import PersonalMenu from '@/components/PersonalMenu/PersonalMenu.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import LangToggler from '@/components/LangToggler/LangToggler.vue';
import MobileMenuDropdown from '@/components/MobileMenuDropdown/MobileMenuDropdown.vue';
import { BURGER_MENU_ITEMS_LIST, TABLET_BURGER_MENU_ITEMS_LIST } from '@/constants/common';

export default {
  name: 'twoColumnLayout',
  components: {
    MainLayout,
    BurgerMenu,
    MobileMenuDropdown,
    UserManager,
    HeaderMenu,
    PersonalMenu,
    LangToggler,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const userState = store.state.user;
    const isOpenMenuDropdown = ref(false);
    const mobileMenuListItems = ref([]);
    const triggerCloseBurger = ref(false);
    const userManagerData = computed(() => userState.managerData);
    const currentLocale = computed(() => userState.currentLocale);
    const personalMenuItems = computed(() => userState.personalMenuItems);
    const userName = computed(() => userState.profileSettings.name);
    const userAvatar = computed(() => userState.profileSettings.avatar);
    const localesList = computed(() => userState.allLocales);
    const tabletBurgerMenuItems = ref(TABLET_BURGER_MENU_ITEMS_LIST);

    const initTwoColumnLayout = async () => {
      await store.dispatch('user/initUserLocale');
    };

    const burgerToggleHandler = () => {
      mobileMenuListItems.value = BURGER_MENU_ITEMS_LIST;
      isOpenMenuDropdown.value = !isOpenMenuDropdown.value;
    };

    const mobileMenuClickHandler = async (item) => {
      if (item === 'logout') {
        await store.dispatch('user/logout');
        await router.push('/login');
      }
    };

    const toggleLangHandler = (newLocale) => {
      store.dispatch('user/setLocale', newLocale);
    };

    initTwoColumnLayout();

    return {
      triggerCloseBurger,
      isOpenMenuDropdown,
      mobileMenuListItems,
      mobileMenuClickHandler,
      burgerToggleHandler,
      userManagerData,
      currentLocale,
      personalMenuItems,
      userName,
      userAvatar,
      localesList,
      tabletBurgerMenuItems,
      toggleLangHandler,
    };
  },
};
</script>

<style lang="scss">
.two-column-layout {
  &__lang-toggler {
    order: 2;
    margin-right: 44px;
  }

  &__inner {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    padding: 60px 32px;
  }

  &__after-inner {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
  }

  &__main {
    width: 74.5%;
  }

  &__side {
    flex-grow: 2;
    margin-left: 24px;
  }

  &__outer {
    .tabs {
      padding-left: 18px;

      &__list {
        border-top: none;
        border-bottom: 1px solid $grey200;
      }
    }

    &-content {
      display: none;
    }
  }

  &__mobile-controls {
    display: none;
  }
}

.personal-menu {
  &__secondary-content {
    display: none;
  }
}

@media screen and (max-width: $tablet-breakpoint ) {
  .two-column-layout {
    &__inner {
      flex-direction: column;
      padding: 0;
    }
    &__after-inner {
      flex-direction: column;
      padding: 0;
    }

    &__main {
      padding: 57px 24px 50px 24px;
      width: 100%;
    }

    &__mobile-controls {
      position: initial;
      width: 100%;
      height: initial;
      border-bottom: 1px solid $grey200;
    }

    &__outer {
      &-content {
        display: block;
        width: 100%;
        position: fixed;
        z-index: 3;
        top: 46px;
      }
    }
  }

  .personal-menu {
  &__secondary-content {
    display: none!important;
  }
}
}

@media screen and (max-width: $mobile-breakpoint) {
  .two-column-layout {
    &__outer {
      &-content {
        position: sticky;
        top: 44px;
      }
    }

    &__main {
      padding: 60px 18px;
    }

    &__mobile-controls {
      display: block;
      position: fixed;
      z-index: 3;
      border-bottom: 1px solid #eff2f6;
    }
  }
}
</style>
