<template>
  <div
    class="metric-box"
    :class="classes"
  >
    <svg
      class="metric-box__img"
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 0L0 3.5L0.701 4.2L3 1.902V8H4V1.902L6.299 4.2L7 3.5L3.5 0Z"
      fill="inherit"/>
    </svg>
    <p
      class="metric-box__result"
    >
    {{Math.abs(status)}}%
    </p>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'metric-result',
  props: {
    status: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const classes = computed(() => ({
      'metric-box--status-positive': props.status > 0,
      'metric-box--status-negative': props.status < 0,
    }));
    return {
      classes,
    };
  },
};

</script>

<style  scoped lang="scss">
.metric-box {
  display: none;
  align-items: center;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  line-height: 10px;
  border-radius: 4px;

  &--status-positive {
    display: flex;
    background-color: $green100;
    color: $green600;
    & .metric-box__img {
      display: block;
      fill: $green600;
      padding: 0 0 0 4px;
    }
  }

  &--status-negative {
    display: flex;
    background-color: $red200;
    color: $red500;
    & .metric-box__img {
      display: block;
      transform:rotate(180deg);
      fill: $red500;
      padding: 0 4px 0 0;
    }

  }

  &__result {
    margin: 0;
    padding: 4px 4px 4px 2px;
  }

  &__img {
    margin: 0;

    display: none;
  }
}
</style>
