<template>
  <div
    class="burger-menu__wrapper"
    v-if="withPopup"
  >
    <PopupWrapper
      arrow
      placement="bottom"
      @popup-opened="burgerPopupOpened"
      @popup-closed="burgerPopupClosed"
    >
      <div
        class="burger-menu"
        :class="{
          'burger-menu--open': isOpen,
        }"
      >
        <div
          class="burger-menu__button"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
      </div>
      <template #popperContent>
        <div class="burger-menu__popup-wrapper">
          <ul class="burger-menu__popup-list">
            <li
              class="burger-menu__popup-item"
              v-for="item in popupMenuData"
              :key="item.id"
            >
            <router-link
              class="burger-menu__popup-link"
              :to="item.path"
            >
              {{ $t(item.text) }}
            </router-link>
            </li>
          </ul>
          <slot name="popupContent">
          </slot>
        </div>
      </template>
    </PopupWrapper>
  </div>
  <div
    v-else
    class="burger-menu"
    :class="{
      'burger-menu--open': isOpen,
    }"
  >
    <button
      class="burger-menu__button"
      @click="toggleMenu"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import PopupWrapper from '../PopupWrapper/PopupWrapper.vue';

export default {
  name: 'BurgerMenu',
  components: {
    PopupWrapper,
  },
  props: {
    isClose: {
      type: Boolean,
      default: false,
    },
    withPopup: {
      type: Boolean,
      default: false,
    },
    popupMenuData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['burgerToggle'],
  setup(props, { emit }) {
    const isOpen = ref(false);

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
      emit('burgerToggle', isOpen.value);
    };

    watch(() => props.isClose, () => {
      if (isOpen.value) {
        isOpen.value = false;
      }
    });

    const burgerPopupOpened = () => {
      isOpen.value = true;
    };

    const burgerPopupClosed = () => {
      isOpen.value = false;
    };

    return {
      isOpen,
      toggleMenu,
      burgerPopupOpened,
      burgerPopupClosed,
    };
  },
};
</script>

<style lang="scss">
.burger-menu {
  display: none;
  margin-left: 16px;

  &__popup {
    &-wrapper {
      padding: 16px 28px;
    }

    &-list {
      margin: 12px -20px 0;
      padding: 0;
      list-style-type: none;
    }

    &-item {
      height: 26px;
      line-height: 26px;
      margin: 4px 0 0;
      padding: 0 20px;
      display: flex;
      align-items: center;

      &:first-child {
        margin: 0;
      }

      &:hover {
        background-color: $grey200;
        cursor: pointer;

        .personal-menu__link-btn {
          color: $main-black;
        }
      }
    }

    &-link {
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      color: $grey900;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      text-decoration: none;
      line-height: 18px;
      cursor: pointer;
    }
  }

  &__button {
    position: relative;
    display: block;
    padding: 4px;
    width: 12px;
    height: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $blue600;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2), &:nth-child(3) {
        top: 4px;
      }

      &:nth-child(4) {
        top: 8px;
      }
    }
  }

  &--open {
    .burger-menu__button {
      span {
        &:nth-child(1) {
          top: 4px;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 4px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .burger-menu {
    &__wrapper {
      .burger-menu {
        display: flex;
        border-radius: 50%;
        background-color: $blue100;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .burger-menu {
    display: block;
  }
}
</style>
