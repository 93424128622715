export default {
  en: {
    investWebsite: 'Investment website',
    guestWebsite: 'Hotel chain website',
    privacy: 'Privacy policy',
    madeBy: 'Made by',
  },
  ru: {
    investWebsite: 'Cайт инвестиций',
    guestWebsite: 'Сайт сети отелей',
    privacy: 'Политика конфиденциальности',
    madeBy: 'Сделано в',
  },
};
