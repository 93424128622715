import { sendRequest } from '@/helpers/request';
import { API_PROMO_SUGGESTIONS, API_PROMO_REQUEST_CALL } from './api_pathes';

async function getSuggestions() {
  try {
    return await sendRequest(API_PROMO_SUGGESTIONS, null, 'GET');
  } catch (error) {
    console.error('[PROMO] Something went wrong with request to get promo data', error);
    return {};
  }
}

async function requestCallback(phone) {
  try {
    return await sendRequest(API_PROMO_REQUEST_CALL, phone, 'POST');
  } catch (error) {
    console.error('[PROMO] Something went wrong with request to request callback', error);
    return {};
  }
}

export default {
  getSuggestions,
  requestCallback,
};
