<template>
  <content-layout
    class="profile-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #mobile-controls>
      <main-side-menu
        :item-list="menuItems"
      />
    </template>
    <template #main-content>
      <div class="profile-page__content">
        <div class="profile-page__avatar-block">
          <profile-avatar
            :user-name="userFIO"
            :contract-number="userContract"
            :avatar-url="userAvatar"
            @upload-avatar="uploadAvatar"
            @delete-avatar="deleteAvatar"
          />
        </div>
        <div class="profile-page__settings-block">
          <profile-settings
            requestOnly
            :title="t('profilePage.baseData')"
            :settings="baseSettings"
            @change-data="openRequestPopup('Запросить смену данных')"
          />
        </div>
        <div
          class="profile-page__settings-block"
          v-if="organizationType !== `Физ.лицо`"
        >
          <profile-settings
            requestOnly
            :title="t('profilePage.bankData')"
            :settings="bankSettings"
            @change-data="openRequestPopup('Запросить смену реквизитов')"
          />
        </div>
        <div class="profile-page__settings-block">
          <profile-password
            :title="t('profilePage.security')"
            @open-modal-window="openPopup"
          />
        </div>
        <modal-window
          :is-shown="modalIsShown"
          @close-window="closePopup"
        >
          <password-change-form
            :success-view="isSuccessPasswordChange"
            @change-password="changePasswordHandler"
            @cancel-password-change="closePopup"
          />
        </modal-window>
        <modal-window
          :is-shown="requestModalIsShown"
          @close-window="closeRequestPopup"
        >
          <request-form
            :title="requestPopupTitle"
            :success-view="isSuccessRequestSend"
            @send-request="sendRequestToChangeProfileSettings"
            @cancel-request="closeRequestPopup"
          />
        </modal-window>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed, provide, ref } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useStore } from 'vuex';
import ContentLayout from '@/layouts/ContentLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import ProfileSettings from '@/components/ProfileSettings/ProfileSettings.vue';
import ProfilePassword from '@/components/ProfilePassword/ProfilePassword.vue';
import ProfileAvatar from '@/components/ProfileAvatar/ProfileAvatar.vue';
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import PasswordChangeForm from '@/components/PasswordChangeForm/PasswordChangeForm.vue';
import RequestForm from '@/components/RequestForm/RequestForm.vue';
import { useRoute } from 'vue-router';
import userModel from '@/models/user';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';

export default {
  name: 'ProfilePage',
  components: {
    ContentLayout,
    ProfileSettings,
    ProfilePassword,
    ProfileAvatar,
    ModalWindow,
    PasswordChangeForm,
    MainSideMenu,
    RequestForm,
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const userState = store.state.user;
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const userFIO = computed(() => userState.profileSettings.fio);
    const currentUserLocation = computed(() => userState.currentLocation);
    const userContract = computed(() => userState.profileSettings.contractNumber);
    const userAvatar = computed(() => userState.profileSettings.avatar);
    const organizationType = computed(() => userState.organizationType);
    const baseSettings = computedAsync(() => store.getters['user/baseProfileSettings'], {});
    const bankSettings = computedAsync(() => organizationType.value === 'ИП'
      ? store.getters['user/bankProfileSettingsIP']
      : store.getters['user/bankProfileSettingsOOO'], {});
    const menuItems = computed(() => userState.sideMenuItems);
    const modalIsShown = ref(false);
    const isSuccessPasswordChange = ref(false);
    const requestModalIsShown = ref(false);
    const isSuccessRequestSend = ref(false);
    const errorMessage = ref('');
    const requestPopupTitle = ref('');
    provide('globalErrorMessage', computed(() => errorMessage));

    const initProfileSetting = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/getUserProfileSettings');
      await store.dispatch('user/initSideMenuItems');
    };

    const saveBaseSettings = async (settings) => {
      await store.dispatch('user/updateUserProfileSettings', settings);
    };

    const saveBankSettings = async (settings) => {
      await store.dispatch('user/updateUserProfileSettings', settings);
    };

    const uploadAvatar = async (avatarBase64) => {
      await store.dispatch('user/updateUserProfileSettings', [{
        id: 'avatar',
        currentValue: avatarBase64,
      }]);
    };

    const deleteAvatar = async () => {
      await store.dispatch('user/updateUserProfileSettings', [{
        id: 'avatar',
        currentValue: '',
      }]);
    };

    const changePasswordHandler = async (payload) => {
      const resp = await store.dispatch('user/changePassword', payload);

      if (resp.ok) {
        errorMessage.value = '';
        isSuccessPasswordChange.value = true;
      } else {
        errorMessage.value = resp.data.message;
      }
    };

    const closePopup = () => {
      modalIsShown.value = false;
    };

    const openPopup = () => {
      modalIsShown.value = true;
    };

    const openRequestPopup = (title) => {
      requestPopupTitle.value = title;
      requestModalIsShown.value = true;
    };

    const closeRequestPopup = () => {
      requestModalIsShown.value = false;
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initProfileSetting();
    };

    const sendRequestToChangeProfileSettings = async () => {
      const resp = await userModel.requestToChangeProfileSettings();

      if (resp.ok) {
        isSuccessRequestSend.value = true;
        setTimeout(() => {
          closeRequestPopup();
          isSuccessRequestSend.value = false;
        }, 2000);
      }
    };

    initProfileSetting();

    return {
      t,
      menuItems,
      userLocations,
      userContract,
      baseSettings,
      bankSettings,
      currentUserLocation,
      modalIsShown,
      isSuccessPasswordChange,
      saveBaseSettings,
      saveBankSettings,
      uploadAvatar,
      deleteAvatar,
      changePasswordHandler,
      openPopup,
      closePopup,
      openRequestPopup,
      closeRequestPopup,
      isSuccessRequestSend,
      requestModalIsShown,
      locationChangeHandler,
      organizationType,
      sendRequestToChangeProfileSettings,
      requestPopupTitle,
      userFIO,
      userAvatar,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-page {
  &__content {
    width: 100%;
  }

  &__settings-block {
    margin-top: 70px;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .header-menu {
    margin-right: 48px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .profile-page {
    &__content {
      padding-top: 40px;
    }

    &__settings-block {
      margin-top: 36px;
    }
  }
}
</style>
