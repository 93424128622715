import controlModel from '@/models/control';
import { prepareShortMonthDate } from '@/helpers/datesAndTime';
import { INSPECTIONS_TYPES } from '@/constants/control';
import { ONE_YEAR, ONE_HOUR } from '@/constants/dates';

const defineInspectionLabel = (item) => {
  if (item.patternName === 'Чек-лист управляющего гостиницей') {
    return 'Техническое состояние объекта';
  } if (item.patternName === 'Чек-лист сервисного обслуживания ') {
    return 'Проверка качества обслуживания';
  } return item.patternName;
};

const editDateForFilter = (item) => `${item.slice(0, -2)}01`;

const sortByDate = (array) => {
  const sortedArray = array.sort((a, b) => a.inspectionDateForFilter - b.inspectionDateForFilter);
  return sortedArray;
};

export default {
  namespaced: true,
  state: () => ({
    averageResult: '',
    allInspections: [],
    completedInspections: [],
    finishedInspectionCount: null,
    lastInspectionDate: '',
    upcomingInspections: [],
    inspectionTypes: [],
    chartData: [],
    chartBarColors: [],
    chartLegendData: [],
    currentFilterId: null,
    selectedDates: null,
    filteredCompletedInspections: [],
    filteredUpcomingInspections: [],
  }),
  mutations: {
    'set:control:data': function (state, data) {
      state.averageResult = data.averageResult;
      state.finishedInspectionCount = data.finishedInspectionCount;
    },
    'set:control:all-inspections': function (state, data) {
      state.allInspections = data;
    },
    'set:control:completed-inspections': function (state, data) {
      state.completedInspections = data;
    },
    'set:control:upcoming-inspections': function (state, data) {
      state.upcomingInspections = data;
    },
    'set:control:last-inspection-date': function (state, value) {
      state.lastInspectionDate = value;
    },
    'set:current:filter': function (state, { value }) {
      state.currentFilterId = value;
    },
    'set:chart:data': function (state, data) {
      state.chartData = data;
    },
    'set:chart:bar-colors': function (state, data) {
      state.chartBarColors = data;
    },
    'set:chart-legend:data': function (state, data) {
      state.chartLegendData = data;
    },
    'set:filter:selected-dates': function (state, dates) {
      state.selectedDates = dates;
    },
    'set:filtered-completed-inspections': function (state, data) {
      state.filteredCompletedInspections = data;
    },
    'set:filtered-upcoming-inspections': function (state, data) {
      state.filteredUpcomingInspections = data;
    },
  },
  actions: {
    async getControlPageData({ dispatch, commit }) {
      const resp = await controlModel.getControlAllChecks();
      const currentDate = new Date().getTime();
      const dateStart = new Date(currentDate - ONE_YEAR).getTime();
      const dateEnd = new Date(currentDate).getTime();
      dispatch('setSelectedDates', { dateStart, dateEnd });
      const completedInspectionsArray = resp.completedInspections.map((item) => ({
        ...item,
        isCompleted: true,
        statFails: item.statFails.toString(),
        percent: `(${item.percent}%)`,
        percentRaw: item.percent,
        result: `${item.factRate}/${item.maxRate}`,
        inspectionDateRaw: item.inspectionDate,
        inspectionDateForFilter:
          new Date(editDateForFilter(item.inspectionDate)).getTime() - (3 * ONE_HOUR),
        inspectionDate: prepareShortMonthDate(item.inspectionDate, {}),
        inspectionLabel: defineInspectionLabel(item),
        inspectionType: INSPECTIONS_TYPES[item.patternColor]
          ? {
            id: item.id,
            label: INSPECTIONS_TYPES[item.patternColor].label,
            color: INSPECTIONS_TYPES[item.patternColor].color,
            barColor: INSPECTIONS_TYPES[item.patternColor].barColor,
          }
          : {
            id: 'other',
            label: INSPECTIONS_TYPES.other.label,
            color: INSPECTIONS_TYPES.other.color,
            barColor: INSPECTIONS_TYPES[item.patternColor].barColor,
          },
      }));
      // eslint-disable-next-line
      const completedInspectionsArraySorted =
      completedInspectionsArray && completedInspectionsArray.length
        ? sortByDate(completedInspectionsArray) : [];
      const upcomingInspectionsArray = resp.upcomingInspections.map((item) => ({
        ...item,
        inspectionDateRaw: item.inspectionDate,
        inspectionDateForFilter:
          new Date(editDateForFilter(item.inspectionDate)).getTime() - (3 * ONE_HOUR),
        inspectionDate: prepareShortMonthDate(
          item.inspectionDate,
        ),
        inspectionLabel: defineInspectionLabel(item),
        inspectionType: INSPECTIONS_TYPES[item.patternColor]
          ? {
            id: item.id,
            label: INSPECTIONS_TYPES[item.patternColor].label,
            color: INSPECTIONS_TYPES[item.patternColor].color,
            barColor: INSPECTIONS_TYPES[item.patternColor].barColor,
          }
          : {
            id: 'other',
            label: INSPECTIONS_TYPES.other.label,
            color: INSPECTIONS_TYPES.other.color,
            barColor: INSPECTIONS_TYPES[item.patternColor].barColor,
          },
      }));
      // eslint-disable-next-line
      const upcomingInspectionsArraySorted =
      upcomingInspectionsArray && upcomingInspectionsArray.length
        ? sortByDate(upcomingInspectionsArray) : [];
      const lastInspectionDate = prepareShortMonthDate(
        resp.lastInspectionDate,
        {},
      );
      const chartPreparedData = completedInspectionsArray.map((item) => ({
        name: item.inspectionLabel,
        color: item.inspectionType.color,
        barColor: item.inspectionType.barColor,
        id: item.id,
        label: item.inspectionDate,
        amount: item.percentRaw,
        fails: Number(item.statFails),
      }));
      const chartLegendPreparedData = chartPreparedData.reduce((acc, item) => {
        if (acc.some((f) => f.name === item.name)) {
          return acc;
        }
        acc.push(item);
        return acc;
      }, []);
      const chartBarColors = chartPreparedData.reduce((acc, item) => {
        const barColor = item.barColor;
        acc.push(barColor);
        return acc;
      }, []);
      commit('set:control:data', resp);
      commit('set:control:completed-inspections', completedInspectionsArraySorted);
      commit('set:control:upcoming-inspections', upcomingInspectionsArraySorted);
      commit('set:filtered-completed-inspections', completedInspectionsArray);
      commit('set:filtered-upcoming-inspections', upcomingInspectionsArray);
      commit('set:chart:data', chartPreparedData);
      commit('set:chart:bar-colors', chartBarColors);
      commit('set:chart-legend:data', chartLegendPreparedData);
      commit('set:control:all-inspections', [...completedInspectionsArray, ...upcomingInspectionsArray]);
      commit('set:control:last-inspection-date', lastInspectionDate);
    },

    setSelectedDates({ commit }, { dateStart, dateEnd }) {
      commit('set:filter:selected-dates', [dateStart, dateEnd]);
    },

    filterInspectionsByDate({ commit, state }) {
      // eslint-disable-next-line
      const filteredCompletedInspectionsArray =
      state.completedInspections && state.completedInspections.length
        ? state.completedInspections.reduce((filteredControls, item) => {
          if (state.selectedDates[0] <= item.inspectionDateForFilter
          && item.inspectionDateForFilter <= state.selectedDates[1]) {
            filteredControls.push(item);
            return filteredControls;
          }
          return filteredControls;
        }, [])
        : [];
      // eslint-disable-next-line
      const filteredUpcomingInspectionsArray =
      state.upcomingInspections && state.upcomingInspections.length
        ? state.upcomingInspections.reduce((filteredControls, item) => {
          if (state.selectedDates[0] <= item.inspectionDateForFilter
            && item.inspectionDateForFilter <= state.selectedDates[1]) {
            filteredControls.push(item);
            return filteredControls;
          }
          return filteredControls;
        }, [])
        : [];
      commit('set:filtered-completed-inspections', filteredCompletedInspectionsArray);
      commit('set:filtered-upcoming-inspections', filteredUpcomingInspectionsArray);
    },

    setFilter({ commit }, { value }) {
      commit('set:current:filter', { value });
    },
  },
};
