<template>
  <div class="check-card">
    <div class="check-card__row">
      <span class="check-card__date">{{ date }}</span>
      <more-link
        :path="{ path: pathName }"
        label="Подробнее"
        v-if="withRoute"
      />
    </div>
    <h4 class="check-card__title">{{ title }}</h4>
    <div class="check-card__row">
      <ul class="check-card__info-list">
        <li class="check-card__info-item">
          <span class="check-card__info-label">Тип проверки:</span>
          <span class="check-card__info-value">
            <ui-label
              :label="type.label"
              :background="type.color"
            />
          </span>
        </li>
        <li
          class="check-card__info-item"
          v-if="result"
        >
          <span class="check-card__info-label">Результат:</span>
          <span class="check-card__info-value">
            {{ result }}
          </span>
          <span class="check-card__info-value check-card__info-value--grey">
            {{ resultPercent }}
          </span>
        </li>
        <li
          class="check-card__info-item"
          v-if="violation"
        >
          <span class="check-card__info-label">Нарушений:</span>
          <span class="check-card__info-value">
            {{ violation }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';
import MoreLink from '@/ui-kit/MoreLink/MoreLink.vue';

export default {
  name: 'CheckCard',
  components: {
    UiLabel,
    MoreLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    withRoute: {
      type: Boolean,
      default: false,
    },
    pathName: {
      type: String,
      default: '',
    },
    type: {
      type: Object,
      default: () => ({}),
    },
    result: {
      type: String,
      default: '',
    },
    resultPercent: {
      type: String,
      default: '',
    },
    violation: {
      type: Number,
      default: 0,
    },
  },
};

</script>

<style lang="scss">
.check-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 35px 20px 33px 0;

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;
  }

  &__date, .check-card__info-label {
    color: $grey800;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    line-height: 18px;
  }

  &__title {
    margin: 7px 0 40px;
    color: $main-black;
    font-size: $font-size-title-small;
    font-weight: $font-weight-bold;
  }

  &__info {
    &-list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-item {
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-label {
      display: inline-block;
      min-width: 100px;
      margin-right: 16px;
    }

    &-value {
      display: flex;
      align-items: center;
      font-size: $font-size-regular;
      &--grey {
        color: $grey800;
        margin-left: 5px;
      }

      .ui-label {
        font-size: $font-size-regular;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .check-card {
    padding: 32px 0;

    &__row {
      .more-link {
        padding: 3px 0;
        font-size: $font-size-small;
      }
    }

    &__date, .check-card__info-label {
      line-height: 16px;
      font-size: $font-size-small;
    }

    &__title {
      line-height: 24px;
      font-size: $font-size-medium;
      margin: 4px 0 24px;
    }

    &__info-value {
      line-height: 18px;
      font-size: $font-size-small;

      .ui-label {
        font-size: $font-size-small;
      }
    }
  }
}
</style>
