<template>
  <div class="summary-table">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SummaryTable',
};
</script>

<style lang="scss">
.summary-table {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 24px;

  &__row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $grey200;

    &:first-child {
      border-top: 1px solid $grey200;
    }

    &--triple {
      .summary-table {
        &__block {
          width: 33.33%;
        }
      }

      border-bottom: none;
    }

    &--bordered {
      border-top: 1px solid $grey200;
    }
  }

  &__title {
    margin-bottom: 40px;
  }

  &__block {
    flex-grow: 1;
    padding: 15px 12px 10px;
    border-right: 1px solid $grey200;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: none;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .summary-table {
    display: flex;
    flex-direction: row;

    &__block {
      padding: 15px 0;
      border: none;

      &:last-child {
        border-top: 1px solid $grey200;
      }
      &:first-child {
        border-top: none;
      }
    }

    &__row {
      flex-direction: column;
      width: 50%;
      border-top: 1px solid $grey200;

      &:not(:first-child) {
        border-left: 1px solid $grey200;
        .summary-table__block {
          padding: 15px 12px;
        }
      }

      &--triple {
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid $grey200;

        .summary-table__block {
          min-width: 50%;

          &:first-child {
            min-width: 100%;
          }

          &:not(:first-child) {
            border-top: 1px solid $grey200;
            padding-bottom: 0;
          }

          &:last-child {
            padding-left: 12px;
            border-left: 1px solid $grey200;
          }
        }
      }
    }

    &__title {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .summary-table {
    flex-direction: column;

    &__block {
      padding: 19px 0 16px;
      border-top: 1px solid $grey200;
      border-bottom: none;

      &:first-child {
        border-top: none;
      }

      .unit-income {
        padding: 0 !important;
      }
    }

    &__row {
      width: 100%;
      border-top: none;
      border-top: 1px solid $grey200;
      border-bottom: none;

      &:not(:first-child) {
        border-left: none;

        .summary-table__block {
          padding: 19px 0 16px;
        }
      }

      &:last-child {
        border-bottom: 1px solid $grey200;
      }

      &--triple {
        border-top: 1px solid $grey200 !important;
        .summary-table__block {
          width: 100%;

          &:last-child {
            padding-left: 0;
            border-left: none;
          }
        }
      }
    }
  }
}
</style>
