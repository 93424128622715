<template>
  <div class="profile-settings">
    <div class="profile-settings__header">
      <title-secondary
        :text="title"
        class="profile-settings__header-text"
      />
      <div class="profile-settings__header-buttons">
        <secondary-button
          class="profile-settings__header-button"
          :label="$t('changePassword')"
          @click="openModal"
        >
          <template #iconBefore>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1895_5192)">
                <path d="M9.82702 7.73651C9.50465 7.73651 9.2441 7.99709 9.2441
                8.31944V10.1505C9.2441 10.4723 8.98294 10.7334
                8.66117 10.7334H2.24881C1.92701 10.7334 1.66588 10.4722 1.66588
                10.1505V2.57227C1.66588
                2.25048 1.92704 1.98934 2.24881 1.98934H5.63568C5.95803 1.98934 6.2186 1.72877
                6.2186 1.40641C6.2186 1.08406 5.95803 0.823486 5.63568 0.823486H2.24881C1.28463
                0.823439 0.5 1.60809 0.5 2.57227V10.1505C0.5 11.1146 1.28463 11.8993 2.24881
                11.8993H8.66112C9.6253 11.8993 10.4099 11.1152 10.4099 10.1505V8.31946C10.41
                7.99709 10.1494 7.73651 9.82702 7.73651Z"
                />
                <path d="M11.8483 0.749479C11.4251 0.326275 10.8724 0.112904 10.3157
                0.101256C9.72986 0.0884356 9.14051 0.300612 8.69748 0.744229L4.75098
                4.69073C4.17504 5.26902 3.85791 6.03616 3.85791 6.85109V8.15687C3.85791
                8.47922 4.11848 8.73979 4.44084 8.73979H5.74661C6.56157 8.73979 7.32871
                8.42267 7.90756 7.84614L11.8488 3.90488C12.7174 3.03577 12.7174 1.61923
                11.8483 0.749479ZM7.08391 7.02134C6.72656 7.37751 6.25088 7.57396 5.74666
                7.57396H5.02379V6.85111C5.02379 6.34687 5.22024 5.87119 5.57583 5.51443L8.17748
                2.91277L9.68496 4.42024L7.08391 7.02134ZM11.024 3.08123L10.5092 3.59598L9.00176
                2.08851L9.51649 1.57378C9.93271 1.15757 10.6089 1.15874 11.024 1.57378C11.439
                1.98939 11.439 2.6656 11.024 3.08123Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_1895_5192">
                  <rect
                    width="12"
                    height="12"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </template>
        </secondary-button>
      </div>
    </div>
    <div class="profile-settings__body">
      <p class="profile-settings__label">{{ $t('password') }}</p>
      <p class="profile-settings__value">***********</p>
    </div>
  </div>
</template>

<script>
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';

export default {
  name: 'ProfilePassword',
  components: {
    TitleSecondary,
    SecondaryButton,
  },
  emits: ['open-modal-window'],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const openModal = () => {
      emit('open-modal-window');
    };

    return {
      openModal,
    };
  },
};
</script>

<style scoped lang="scss">
.profile-settings {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid $grey200;

    &-buttons {
      display: flex;
    }

    &-button {
      margin-right: 12px;
    }
  }

  &__body {
    margin-top: 36px;
    display: flex;
    align-items: center;
  }

  &__label {
    margin: 0;
    padding: 0;
    width: 210px;
    padding-right: 8px;
    overflow: hidden;
    font-size: $font-size-medium;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__value {
    margin: 0;
    padding: 0;
    color: $grey800;
    height: 22px;
    align-self: flex-end;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .profile-settings {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      border: none;
    }

    &__header-text {
      font-size: $font-size-title-small;
      line-height: 24px;
      display: flex;

      &::before {
        content: url('@/assets/mobileSecTitleBefore.svg');
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
        padding-top: 1px;
      }
    }

    &__header-buttons {
      font-size: $font-size-small;
      margin-top: 10px;
    }

    &__body {
      flex-direction: column;
      align-items: flex-start;
    }

    &__label {
      width: 100%;
      padding-right: 0;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      line-height: 20px;
    }

    &__value {
      color: $grey800;
      height: 20px;
      margin-top: 11px;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      align-self: inherit;
    }
  }
}
</style>
