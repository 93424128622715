<template>
  <ul class="legend-list">
    <li
      class="legend-list__item"
      v-for="item in itemsList"
      :key="item.id"
    >
      <div class="legend-list__circle">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="6"
            cy="6"
            r="6"
            :fill="item.color"
          />
        </svg>
        <p class="legend-list__text">{{ item.label }}</p>
      </div>
      <p
        class="legend-list__nums loading-stub__shimmer"
      >
        {{ item.amount }}
        <span
          v-if="maxAmount"
        >
           / {{ maxAmount }}
        </span>
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'LegendList',
  props: {
    maxAmount: {
      type: Number,
      default: null,
    },
    itemsList: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid $grey200;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__circle {
    display: flex;
    gap: 0 8px;
    align-items: center;
    margin:0;
    padding: 0;
  }

  &__text {
    margin: 0;
    padding: 0;
    color: $main-black;
    line-height: 10px;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
  }

  &__nums {
    color: $grey800;
    margin:0;
    padding: 0;
    line-height: 16px;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    text-align: right;
  }

  .loading-stub {
    &__shimmer {
      width: 40px;
      height: 12px;
    }
  }
}
</style>
