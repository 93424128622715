export const updateMoneySum = (sum) => {
  if (!sum || (sum < 3 && sum > -1)) {
    return sum;
  }

  const fixedSum = parseFloat(sum).toFixed(0);
  const strSum = String(fixedSum);
  const decimalIndex = strSum.indexOf('.');
  let updatedStr = '';

  const integerPart = decimalIndex !== -1 ? strSum.substring(0, decimalIndex) : strSum;
  const decimalPart = decimalIndex !== -1 ? strSum.substring(decimalIndex) : '';
  const strLength = integerPart.length;
  for (let i = strLength; i > 0; i -= 3) {
    if (i > 3) {
      updatedStr = `${integerPart.substring(i - 3, i)}${updatedStr ? `\u202F${updatedStr}` : updatedStr}`;
    } else {
      updatedStr = `${integerPart.substring(0, i)}\u202F${updatedStr}`;
    }
  }
  return decimalPart === '.00' ? updatedStr : updatedStr + decimalPart;
};

export const openDownloadFilePage = (blob, fileName) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);

  link.click();

  URL.revokeObjectURL(link.href);
};

export const isObjectEmpty = (objectToCheck) => objectToCheck.constructor === Object
  && Object.keys(objectToCheck).length === 0;
