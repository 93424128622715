<template>
  <div
    class="filter-date"
    :class="{
      'filter-date--disabled': disabled,
    }"
  >
    <div class="filter-date__controls">
      <div
        v-if="!calendarOnly"
        class="filter-date__controls-past"
        :class="{
          'filter-date__controls-past--active': listedDatesSelectedItem.id,
        }"
      >
        <popup-dropdown
          :data="listedDatesOptions"
          :active-item-id="listedDatesSelectedItem.id || ''"
          @select-dropdown-item="listedDatesSelectHandler"
          :placement="popupPosition"
        >
          <secondary-button
            :label="listedDateLabel"
          >
            <template #iconAfter>
              <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
                  1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                  -0.0719468 1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496
                  0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742 0.21598C11.0753
                  0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
                  5.69705 6 5.49998 6Z"/>
              </svg>
            </template>
          </secondary-button>
        </popup-dropdown>
      </div>
      <div
        class="filter-date__controls-calendar"
        :class="{
          'filter-date__controls-calendar--no-radius': !calendarOnly,
        }"
      >
        <calendar-date-picker
          :preselected-date="isRange ? null : preSelectedCalendarDate"
          :preselected-range="isRange ? preselectedCalendarRange : []"
          :is-range="isRange"
          :is-month-picker="isMonthPicker"
          @select-date="calendarDateSelectHandler"
          :popupPosition="calendarDatePopupPosition"
        >
          <template #toggle>
            <secondary-button
              :label="calendarDateLabel"
            >
              <template
                #iconBefore
                v-if="calendarIcon"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2 1.8H9.6V0.6C9.6 0.44087 9.53679 0.288258 9.42426 0.175736C9.31174
                    0.0632141 9.15913 0 9 0C8.84087 0 8.68826 0.0632141 8.57574 0.175736C8.46321
                    0.288258 8.4 0.44087 8.4 0.6V1.8H3.6V0.6C3.6 0.44087 3.53679 0.288258 3.42426
                    0.175736C3.31174 0.0632141 3.15913 0 3 0C2.84087 0 2.68826 0.0632141 2.57574
                    0.175736C2.46321 0.288258 2.4 0.44087 2.4 0.6V1.8H1.8C1.32261 1.8 0.864773
                    1.98964 0.527208 2.32721C0.189642 2.66477 0 3.12261 0 3.6V4.2H12V3.6C12
                    3.12261 11.8104 2.66477 11.4728 2.32721C11.1352 1.98964
                    10.6774 1.8 10.2 1.8Z"
                    fill="#424551"
                  />
                  <path
                    d="M0 10.2C0 10.6774 0.189642 11.1353 0.527208 11.4728C0.864773 11.8104
                    1.32261 12 1.8 12H10.2C10.6774 12 11.1352 11.8104 11.4728 11.4728C11.8104
                    11.1353 12 10.6774 12 10.2V5.40002H0V10.2Z"
                    fill="#424551"
                  />
                </svg>
              </template>
              <template
                #iconAfter
                v-if="arrowIcon"
              >
                <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
                    1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                    -0.0719468 1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496
                    0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742 0.21598C11.0753
                    0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
                    5.69705 6 5.49998 6Z"/>
                </svg>
              </template>
            </secondary-button>
          </template>
        </calendar-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, computed, watch,
} from 'vue';
import PopupDropdown from '@/components/PopupDropdown/PopupDropdown.vue';
import CalendarDatePicker from '@/ui-kit/CalendarDatePicker/CalendarDatePicker.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import { isSameDay, prepareShortMonthDate } from '@/helpers/datesAndTime';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FilterDate',
  components: {
    PopupDropdown,
    CalendarDatePicker,
    SecondaryButton,
  },
  props: {
    preselectedDate: {
      type: Number,
      default: null,
    },
    preselectedRange: {
      type: Array,
      default: () => [],
    },
    isMonthPicker: {
      type: Boolean,
      default: false,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    calendarOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    todayStart: {
      type: Boolean,
      default: false,
    },
    pastDatesOptions: {
      type: Array,
      default: () => [],
    },
    futureDatesOptions: {
      type: Array,
      default: () => [],
    },
    listedDateOptionPreselected: {
      type: Number,
      default: null,
    },
    noDayButton: {
      type: Boolean,
      default: false,
    },
    popupPosition: {
      type: String,
      default: 'bottom',
    },
    calendarDatePopupPosition: {
      type: String,
      default: 'center',
    },
    calendarIcon: {
      type: Boolean,
      default: false,
    },
    arrowIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filter-date-select'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const PLACEHOLDER_LISTED_DATE = t('selectedDate', 1);
    const PLACEHOLDER_CALENDAR_DATE = t('selectedDate', 2);
    const listedDateLabel = ref(PLACEHOLDER_LISTED_DATE);
    const calendarDateLabel = ref(props.preselectedDate
      ? prepareShortMonthDate(props.preselectedDate, { day: true })
      : PLACEHOLDER_CALENDAR_DATE);
    const preSelectedCalendarDate = ref(props.preselectedDate);
    const listedDatesSelectedItem = ref({});
    const preselectedCalendarRange = ref(props.preselectedRange);
    const listedDatesOptions = computed(() => [
      ...props.pastDatesOptions,
      ...props.futureDatesOptions.map((item) => ({
        ...item,
        underlined: true,
      }))]);

    const dateFormatOptions = props.isMonthPicker
      ? { day: true }
      : { year: true };

    const listedDatesSelectHandler = (selectedItem) => {
      const { id, label } = selectedItem[0];
      const todayDate = new Date();
      let selected;
      listedDateLabel.value = label;

      if (!props.isRange) {
        preSelectedCalendarDate.value = id;
        calendarDateLabel.value = prepareShortMonthDate(id);
        selected = id;
      } else {
        selected = [id, todayDate.getTime()];
        preselectedCalendarRange.value = selected;
        calendarDateLabel.value = `${prepareShortMonthDate(id, { year: true })}
        – ${prepareShortMonthDate(todayDate.getTime(), { year: true })}`;
      }

      emit('filter-date-select', selected);
    };

    const calendarDateSelectHandler = (selectedDate) => {
      if (Array.isArray(selectedDate)) {
        const startDay = props.isMonthPicker
          ? new Date(`${selectedDate[0].month + 1}/1/${selectedDate[0].year}`).getTime()
          : selectedDate[0];
        const endDay = props.isMonthPicker
          ? new Date(`${selectedDate[1].month + 1}/1/${selectedDate[1].year}`).getTime()
          : selectedDate[1];

        calendarDateLabel.value = `${prepareShortMonthDate(startDay, dateFormatOptions)}
        –  ${prepareShortMonthDate(endDay, dateFormatOptions)}`;

        listedDatesSelectedItem.value = {};
        listedDateLabel.value = PLACEHOLDER_LISTED_DATE;

        emit('filter-date-select', [startDay, endDay]);
      } else {
        const updatedDate = selectedDate.month >= 0 && selectedDate.year
          ? {
            ...selectedDate,
            epoch: new Date(`${selectedDate.month + 1}/1/${selectedDate.year}`).getTime(),
          }
          : selectedDate;
        calendarDateLabel.value = updatedDate.epoch
          ? prepareShortMonthDate(updatedDate.epoch, {
            day: props.noDayButton,
          })
          : prepareShortMonthDate(updatedDate, {
            day: props.noDayButton,
          });
        const sameDayInListedDates = listedDatesOptions.value
          .filter((option) => isSameDay(new Date(option.id), new Date(updatedDate.epoch)));

        if (sameDayInListedDates.length) {
          listedDatesSelectedItem.value = sameDayInListedDates[0];
          listedDateLabel.value = sameDayInListedDates[0].label;
        } else {
          listedDatesSelectedItem.value = {};
          listedDateLabel.value = PLACEHOLDER_LISTED_DATE;
        }

        emit('filter-date-select', updatedDate);
      }
    };

    onMounted(() => {
      if (!props.isRange && !props.preselectedDate) {
        const todayDate = new Date();

        if (!props.todayStart && !props.calendarOnly) {
          listedDatesSelectedItem.value = listedDatesOptions.value[0];
          listedDateLabel.value = listedDatesSelectedItem.value.label;
          preSelectedCalendarDate.value = listedDatesSelectedItem.value.id;
          calendarDateLabel.value = prepareShortMonthDate(listedDatesSelectedItem.value.id, {
            day: props.isMonthPicker,
          });
        } else {
          listedDatesSelectedItem.value = {};
          listedDateLabel.value = PLACEHOLDER_LISTED_DATE;
          preSelectedCalendarDate.value = todayDate.getTime();
          calendarDateLabel.value = prepareShortMonthDate(todayDate.getTime(), {
            day: props.isMonthPicker,
          });
        }
      }

      if (props.isRange && props.preselectedRange && props.preselectedRange.length) {
        calendarDateLabel.value = `${prepareShortMonthDate(props.preselectedRange[0], dateFormatOptions)}
        – ${prepareShortMonthDate(props.preselectedRange[1], dateFormatOptions)}`;
      }

      if (listedDatesOptions.value.length && props.listedDateOptionPreselected !== null) {
        listedDatesSelectedItem.value = listedDatesOptions.value[props.listedDateOptionPreselected];
        listedDateLabel.value = listedDatesSelectedItem.value.label;
      }
    });
    watch(props.preselectedRange, () => {
      preselectedCalendarRange.value = props.preselectedRange;
    });

    return {
      listedDatesOptions,
      listedDateLabel,
      listedDatesSelectedItem,
      calendarDateLabel,
      preSelectedCalendarDate,
      preselectedCalendarRange,
      listedDatesSelectHandler,
      calendarDateSelectHandler,
    };
  },
};
</script>

<style lang="scss">
.filter-date {
  display: flex;
  padding-right: 1px;

  &__controls {
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;

    &-past {
      .secondary-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07),
            0 0 0 1px rgba(160, 164, 176, 0.12),
            0 3px 0 rgba(83, 89, 109, 0.06);
      }

      &--active {
        .secondary-button {
          color: $grey900;
        }
      }
    }

    &-calendar {
      &--no-radius {
        .secondary-button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07),
            0 0 0 1px rgba(160, 164, 176, 0.12),
            0 3px 0 rgba(83, 89, 109, 0.06);
        }
      }
    }
  }

  &__calendar{
    display: flex;
    align-items: center;

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      color: $grey900;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      line-height: 18px;
      cursor: pointer;

      &:before {
        content: '';
        width: 12px;
        height: 12px;
        margin-right: 8px;
        background-image: url(../../assets/calendar.svg);
      }
    }

  }

  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .filter-date {
    &__controls {
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      line-height: 18px;
      color: $main-black;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      cursor: pointer;
      transition: all 0.3s ease;
      fill: $grey900;

      &-calendar {
        padding: 7px 12px 7px 0;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .dp {
    &__menu {
      max-width: 100%;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }

  .filter-date {
    &-calendar {
      padding: 7px 0;
    }

    &__calendar-button {
      justify-content: flex-start;
      padding: 4px 0;
      font-size: $font-size-small;
    }
  }
}
</style>
