<template>
  <div
    class="filter-button"
    :class="{
      'filter-button--active': value.length,
    }"
  >
    <button
      class="filter-button__label"
      @click="toggleHandler"
    >
      <slot name="beforeIcon"/>
      <span class="filter-button__label-text">
        {{ label }}
      </span>
      <span
        v-if="value.length"
      >
        :&nbsp;
      </span>
      <span
        v-if="value.length"
        class="filter-button__value"
      >
        {{ value }}
      </span>
    </button>
    <button
      v-if="value.length"
      class="filter-button__close"
      @click="clearHandler"
    />
  </div>
</template>

<script>

export default {
  name: 'filter-button',
  emits: ['toggle-filter-dropdown', 'clear-filters'],
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const toggleHandler = () => {
      emit('toggle-filter-dropdown');
    };
    const clearHandler = () => {
      emit('clear-filters');
    };

    return {
      toggleHandler,
      clearHandler,
    };
  },
};

</script>

<style lang="scss">
.filter-button {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  background: #fff;
  border: 1px dashed $grey300;
  border-radius: 12px;
  font-size: $font-size-small;
  cursor: pointer;
  transition: all 0.2s;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    line-height: 16px;
    font-size: $font-size-small;
    color: $grey800;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &-text {
      margin: 0 2px;
    }
  }

  &__value {
    padding: 0 8px 0 0;
    line-height: 16px;
    font-size: $font-size-small;
    color: $blue400;
    background-color: transparent;
    border: none;
    border-right: 1px solid $grey300;
    outline: none;
  }

  &__close  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 0 0 8px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background-image: url('../../assets/round-plus.svg');
      background-repeat: no-repeat;
      background-color: transparent;
      transform: rotate(45deg);
    }
  }

  &:hover {
    background: $grey100;
  }

}

@media screen and (max-width: $tablet-breakpoint) {
  .filter-button {
    height: 33px;
    border-radius: 17px;

    &__label {
      font-size: $font-size-regular;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .filter-button {
    height: 40px;
    border-radius: 29px;
  }
}
</style>
