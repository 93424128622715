<template>
  <div
    class="avatar-icon"
    :class="{
      'avatar-icon--big': big,
    }"
  >
    <span v-if="!pictureUrl" class="avatar-icon__text">{{ userInitials }}</span>
    <img v-else
         :src="pictureUrl"
         class="avatar-icon__image"
         alt="avatar icon image"
    >
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'AvatarIcon',
  props: {
    userName: {
      type: String,
      default: '',
    },
    pictureUrl: {
      type: String,
      default: '',
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const userInitials = computed(() => {
      if (!props.userName.length) {
        return '';
      }

      const splittedName = props.userName.trim().split(' ');
      return splittedName.length > 1
        ? `${splittedName[0][0]}${splittedName[1][0]}`.toUpperCase()
        : `${splittedName[0][0].toUpperCase()}${splittedName[0][1]}`;
    });

    return {
      userInitials,
    };
  },
};
</script>

<style scoped lang="scss">
.avatar-icon {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  background-color: $blue100;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  font-size: $font-size-medium;

  &--big {
    font-size: 44px;
  }

  &__image {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    margin: 0;
    padding: 0;
    color: $blue500;
    font-weight: $font-weight-medium;
    line-height: 12px;
  }
}

</style>
