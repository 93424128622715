<template>
  <div class="report-list">
    <div class="report-list__main-wrapper">
      <ul class="report-list__wrapper">
        <li
          v-for="item in preparedTestsArray"
          :key="item.id"
          class="report-list__header-block"
        >
          <div
            class="report-list__header-block-wrapper"
            :ref="`anchor${item.id}`"
          >
            <div class="report-list__header-block-title">
              {{ item.title }}
            </div>
            <div class="report-list__count">
              {{ item.count }} из {{ item.fullAmount }}
              ({{ Math.ceil(item.count/item.fullAmount*100) }}%)
            </div>
          </div>
          <ul class="report-list__sub">
            <li
              v-for="subItem in item.tests"
              :key="subItem.id"
              class="report-list__sub-list"
            >
              <div
                class="report-list__sub-wrapper"
                :ref="`anchor${item.id}${subItem.id}`"
              >
                <div class="report-list__sub-title">
                  {{ subItem.title }}
                </div>
                <div class="report-list__count">
                  {{ subItem.innerCount }} из {{ subItem.testsList.length }}
                   ({{ Math.ceil(subItem.innerCount/subItem.testsList.length*100) }}%)
                </div>
              </div>
              <ul class="report-list__inner">
                <li
                  v-for="innerItem in subItem.testsList"
                  :key="innerItem.id"
                  class="report-list__inner-wrapper"
                >
                  <div class="report-list__inner-title">
                    {{ innerItem.test }}
                  </div>
                  <div
                    class="report-list__inner-status"
                    v-if="innerItem.status === 1"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16
                        8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114
                        0 8 0ZM12.0547 6.30469L7.72131 10.6379C7.59131
                        10.7679 7.42065 10.8334 7.25 10.8334C7.07935
                        10.8334 6.90869 10.7679 6.77869 10.6379L4.61206
                        8.47131C4.35132 8.21069 4.35132 7.78931 4.61206
                        7.52869C4.87268 7.26794 5.29395 7.26794 5.55469
                        7.52869L7.25 9.224L11.1121 5.36206C11.3727
                        5.10132 11.7939 5.10132 12.0547 5.36206C12.3153
                        5.62268 12.3153 6.04395 12.0547 6.30469Z"
                        fill="#5DC397"
                      />
                    </svg>
                    <div class="report-list__inner-status-text">
                      Да
                    </div>
                  </div>
                  <div
                    class="report-list__inner-status"
                    v-if="innerItem.status === 0"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.34056 2.34056C-0.780186 5.4613 -0.780186
                      10.5387 2.34056 13.6594C5.4613 16.7802 10.5387
                      16.7802 13.6594 13.6594C16.7802 10.5387 16.7802
                      5.4613 13.6594 2.34056C10.5387 -0.780186 5.4613
                      -0.780186 2.34056 2.34056ZM10.9476 5.99559L8.94318
                      8L10.9476 10.0044C11.208 10.2648 11.208 10.6872
                      10.9476 10.9476C10.6872 11.208 10.2648 11.208
                      10.0044 10.9476L8 8.94318L5.99559 10.9476C5.73522
                      11.208 5.31277 11.208 5.0524 10.9476C4.79204
                      10.6872 4.79204 10.2648 5.0524 10.0044L7.05682
                      8L5.0524 5.99559C4.79204 5.73522 4.79204 5.31277
                      5.0524 5.0524C5.31277 4.79204 5.73522 4.79204
                      5.99559 5.0524L8 7.05682L10.0044 5.0524C10.2648
                      4.79204 10.6872 4.79204 10.9476 5.0524C11.208
                      5.31277 11.208 5.73522 10.9476 5.99559Z"
                      fill="#F48575"
                    />
                    </svg>
                    <div class="report-list__inner-status-text">
                      Нет
                    </div>
                  </div>
                  <div
                    class="report-list__inner-media"
                    v-if="innerItem.media.length"
                  >
                    <media-link
                      linkText="Открыть фото"
                      buttonColor="#FFF"
                      :slides="innerItem.media"
                    />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <div class="report-list__side-wrapper">
        <ul class="report-list__side-menu-list">
          <li
            class="report-list__side-menu-element-wrapper"
            v-for="item in preparedTestsArray"
            :key="item.id"
          >
            <div class="report-list__side-menu-element">
              <div class="report-list__side-menu-link-wrapper">
                <a
                  class="report-list__side-menu-link
                  report-list__side-menu-link--bold"
                  @click="moveToAnchor(`anchor${item.id}`)"
                >
                  {{ item.title }}
                </a>
              </div>
              <div class="report-list__side-menu-element-count">
                {{ item.fullAmount }}
              </div>
            </div>
            <ul
              class="report-list__side-menu-inner-wrapper"
              v-for="innerItem in item.tests"
              :key="innerItem.id"
            >
              <div class="report-list__side-menu-element">
                <div class="report-list__side-menu-link-wrapper">
                  <a
                    @click="moveToAnchor(`anchor${item.id}${innerItem.id}`)"
                    class="report-list__side-menu-link"
                  >
                    {{ innerItem.title }}
                  </a>
                </div>
                <div class="report-list__side-menu-element-count">
                  {{ innerItem.testsList.length }}
                </div>
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <TransitionGroup
      class="report-list__tablet-wrapper"
      tag="ul"
      name="slide-two"
    >
      <li
        v-for="( item, index ) in preparedTestsArray"
        :key="item.id"
        class="report-list__header-block"
      >
        <div
          class="report-list__header-block-wrapper"
          @click="toggleLine(index)"
        >
          <div class="report-list__header-block-title">
            {{ item.title }}
          </div>
          <div class="report-list__right-block-wrapper">
            <div class="report-list__count">
              {{ item.count }} из {{ item.fullAmount }}
              ({{ Math.ceil(item.count/item.fullAmount*100) }}%)
            </div>
            <div
              class="report-list__icon-container"
              :class="{'report-list__icon-container--state-open' : item.isOpen}"
            >
              <svg
                class="report-list__icon"
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="#424551"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542
                  5.78422L0.225656 1.25832C-0.0752187
                  0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                  -0.0719468 1.01413 -0.0719468
                  1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584
                  -0.0718069 10.4735 -0.0718069
                  10.7742 0.21598C11.0753 0.503767 11.0753 0.970553
                  10.7742 1.25846L6.04455
                  5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z"
                  fill="#424551"
                />
              </svg>
            </div>
          </div>
        </div>
        <Transition name="inner">
          <TransitionGroup
            v-if="item.isOpen"
            class="report-list__sub"
            tag="ul"
            name="slide-three"
          >
            <li
              v-for="( subItem, subIndex ) in item.tests"
              :key="subItem.id"
              class="report-list__sub-list"
            >
              <div
                class="report-list__sub-wrapper"
                @click="toggleSubLine(index,subIndex)"
              >
                <div class="report-list__sub-title">
                  {{ subItem.title }}
                </div>
                <div class="report-list__right-block-wrapper">
                  <div class="report-list__count">
                    {{ subItem.innerCount }} из {{ subItem.testsList.length }}
                    ({{ Math.ceil(subItem.innerCount/subItem.testsList.length*100) }}%)
                  </div>
                  <div
                    class="report-list__icon-container"
                    :class="{'report-list__icon-container--state-open' : subItem.isOpen}"
                  >
                    <svg
                      class="report-list__icon"
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="#424551"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542
                        5.78422L0.225656 1.25832C-0.0752187
                        0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                        -0.0719468 1.01413 -0.0719468
                        1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584
                        -0.0718069 10.4735 -0.0718069
                        10.7742 0.21598C11.0753 0.503767 11.0753 0.970553
                        10.7742 1.25846L6.04455
                        5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z"
                        fill="#424551"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <Transition name="inner">
                <ul
                  v-if="subItem.isOpen"
                  class="report-list__inner"
                >
                  <li
                    v-for="innerItem in subItem.testsList"
                    :key="innerItem.id"
                    class="report-list__inner-wrapper"
                  >
                    <div class="report-list__inner-title">
                      {{ innerItem.test }}
                    </div>
                    <div
                      class="report-list__inner-status"
                      v-if="innerItem.status === 1"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16
                          8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114
                          0 8 0ZM12.0547 6.30469L7.72131 10.6379C7.59131
                          10.7679 7.42065 10.8334 7.25 10.8334C7.07935
                          10.8334 6.90869 10.7679 6.77869 10.6379L4.61206
                          8.47131C4.35132 8.21069 4.35132 7.78931 4.61206
                          7.52869C4.87268 7.26794 5.29395 7.26794 5.55469
                          7.52869L7.25 9.224L11.1121 5.36206C11.3727
                          5.10132 11.7939 5.10132 12.0547 5.36206C12.3153
                          5.62268 12.3153 6.04395 12.0547 6.30469Z"
                          fill="#5DC397"
                        />
                      </svg>
                      <div class="report-list__inner-status-text">
                        Да
                      </div>
                    </div>
                    <div
                      class="report-list__inner-status"
                      v-if="innerItem.status === 0"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.34056 2.34056C-0.780186 5.4613 -0.780186
                        10.5387 2.34056 13.6594C5.4613 16.7802 10.5387
                        16.7802 13.6594 13.6594C16.7802 10.5387 16.7802
                        5.4613 13.6594 2.34056C10.5387 -0.780186 5.4613
                        -0.780186 2.34056 2.34056ZM10.9476 5.99559L8.94318
                        8L10.9476 10.0044C11.208 10.2648 11.208 10.6872
                        10.9476 10.9476C10.6872 11.208 10.2648 11.208
                        10.0044 10.9476L8 8.94318L5.99559 10.9476C5.73522
                        11.208 5.31277 11.208 5.0524 10.9476C4.79204
                        10.6872 4.79204 10.2648 5.0524 10.0044L7.05682
                        8L5.0524 5.99559C4.79204 5.73522 4.79204 5.31277
                        5.0524 5.0524C5.31277 4.79204 5.73522 4.79204
                        5.99559 5.0524L8 7.05682L10.0044 5.0524C10.2648
                        4.79204 10.6872 4.79204 10.9476 5.0524C11.208
                        5.31277 11.208 5.73522 10.9476 5.99559Z"
                        fill="#F48575"
                      />
                      </svg>
                      <div class="report-list__inner-status-text">
                        Нет
                      </div>
                    </div>
                    <div
                      class="report-list__inner-media"
                      v-if="innerItem.media"
                    >
                      <media-link
                        linkText="Открыть фото"
                        buttonColor="#FFF"
                        :slides="innerItem.media"
                      />
                    </div>
                  </li>
                </ul>
              </Transition>
            </li>
          </TransitionGroup>
        </Transition>
      </li>
    </TransitionGroup>
    <TransitionGroup
      class="report-list__mobile-wrapper"
      tag="ul"
      name="slide-four"
    >
      <li
        v-for="( item, index ) in preparedTestsArray"
        :key="item.id"
        class="report-list__header-block"
      >
        <div
          class="report-list__header-block-wrapper"
          @click="toggleLine(index)"
        >
          <div class="report-list__left-block-wrapper">
            <div class="report-list__header-block-title">
              {{ item.title }}
            </div>
            <div class="report-list__count">
              {{ item.count }} из {{ item.fullAmount }}
              ({{ Math.ceil(item.count/item.fullAmount*100) }}%)
            </div>
          </div>
          <div
            class="report-list__icon-container"
            :class="{'report-list__icon-container--state-open' : item.isOpen}"
          >
            <svg
              class="report-list__icon"
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="#424551"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542
                5.78422L0.225656 1.25832C-0.0752187
                0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                -0.0719468 1.01413 -0.0719468
                1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584
                -0.0718069 10.4735 -0.0718069
                10.7742 0.21598C11.0753 0.503767 11.0753 0.970553
                10.7742 1.25846L6.04455
                5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z"
                fill="#424551"
              />
            </svg>
          </div>
        </div>
        <Transition name="inner">
          <TransitionGroup
            v-if="item.isOpen"
            class="report-list__sub"
            tag="ul"
            name="slide-five"
          >
            <li
              v-for="( subItem, subIndex ) in item.tests"
              :key="subItem.id"
              class="report-list__sub-list"
            >
              <div
                class="report-list__sub-wrapper"
                @click="toggleSubLine(index,subIndex)"
              >
                <div class="report-list__left-block-wrapper">
                  <div class="report-list__sub-title">
                    {{ subItem.title }}
                  </div>
                  <div class="report-list__count">
                    {{ subItem.innerCount }} из {{ subItem.testsList.length }}
                    ({{ Math.ceil(subItem.innerCount/subItem.testsList.length*100) }}%)
                  </div>
                </div>
                <div
                  class="report-list__icon-container"
                  :class="{'report-list__icon-container--state-open' : subItem.isOpen}"
                >
                  <svg
                    class="report-list__icon"
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="#424551"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542
                      5.78422L0.225656 1.25832C-0.0752187
                      0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409
                      -0.0719468 1.01413 -0.0719468
                      1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584
                      -0.0718069 10.4735 -0.0718069
                      10.7742 0.21598C11.0753 0.503767 11.0753 0.970553
                      10.7742 1.25846L6.04455
                      5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z"
                      fill="#424551"
                    />
                  </svg>
                </div>
              </div>
              <Transition name="inner">
                <ul
                  v-if="subItem.isOpen"
                  class="report-list__inner"
                >
                  <li
                    v-for="innerItem in subItem.testsList"
                    :key="innerItem.id"
                    class="report-list__inner-wrapper"
                  >
                    <div class="report-list__inner-title">
                      {{ innerItem.test }}
                    </div>
                    <div
                      class="report-list__inner-status"
                      v-if="innerItem.status === 1"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16
                          8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114
                          0 8 0ZM12.0547 6.30469L7.72131 10.6379C7.59131
                          10.7679 7.42065 10.8334 7.25 10.8334C7.07935
                          10.8334 6.90869 10.7679 6.77869 10.6379L4.61206
                          8.47131C4.35132 8.21069 4.35132 7.78931 4.61206
                          7.52869C4.87268 7.26794 5.29395 7.26794 5.55469
                          7.52869L7.25 9.224L11.1121 5.36206C11.3727
                          5.10132 11.7939 5.10132 12.0547 5.36206C12.3153
                          5.62268 12.3153 6.04395 12.0547 6.30469Z"
                          fill="#5DC397"
                        />
                      </svg>
                      <div class="report-list__inner-status-text">
                        Да
                      </div>
                    </div>
                    <div
                      class="report-list__inner-status"
                      v-if="innerItem.status === 0"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.34056 2.34056C-0.780186 5.4613 -0.780186
                        10.5387 2.34056 13.6594C5.4613 16.7802 10.5387
                        16.7802 13.6594 13.6594C16.7802 10.5387 16.7802
                        5.4613 13.6594 2.34056C10.5387 -0.780186 5.4613
                        -0.780186 2.34056 2.34056ZM10.9476 5.99559L8.94318
                        8L10.9476 10.0044C11.208 10.2648 11.208 10.6872
                        10.9476 10.9476C10.6872 11.208 10.2648 11.208
                        10.0044 10.9476L8 8.94318L5.99559 10.9476C5.73522
                        11.208 5.31277 11.208 5.0524 10.9476C4.79204
                        10.6872 4.79204 10.2648 5.0524 10.0044L7.05682
                        8L5.0524 5.99559C4.79204 5.73522 4.79204 5.31277
                        5.0524 5.0524C5.31277 4.79204 5.73522 4.79204
                        5.99559 5.0524L8 7.05682L10.0044 5.0524C10.2648
                        4.79204 10.6872 4.79204 10.9476 5.0524C11.208
                        5.31277 11.208 5.73522 10.9476 5.99559Z"
                        fill="#F48575"
                      />
                      </svg>
                      <div class="report-list__inner-status-text">
                        Нет
                      </div>
                    </div>
                    <div
                      class="report-list__inner-media"
                      v-if="innerItem.media"
                    >
                      <media-link
                        linkText="Открыть фото"
                        buttonColor="#FFF"
                        :slides="innerItem.media"
                      />
                    </div>
                  </li>
                </ul>
              </Transition>
            </li>
          </TransitionGroup>
        </Transition>
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
import MediaLink from '@/ui-kit/MediaLink/MediaLink.vue';
import { ref, watch } from 'vue';

const prepareArray = (arr) => !arr.length
  ? []
  : arr.map((item) => {
    let count = 0;
    let fullAmount = 0;
    item.tests.forEach((el) => {
      fullAmount = el.testsList.length + fullAmount;
      el.testsList.forEach((countable) => {
        if (countable.status === 1) {
          count += 1;
        }
      });
    });
    const anchorName = `anchor${item.id}`;

    return {
      ...item,
      isOpen: true,
      fullAmount,
      count,
      anchorName,
      tests: item.tests.map((innerItem) => {
        const innerAnchorName = `anchor${item.id}${innerItem.id}`;
        let innerCount = 0;
        innerItem.testsList.forEach((countable) => {
          if (countable.status === 1) {
            innerCount += 1;
          }
        });

        return {
          ...innerItem,
          isOpen: false,
          innerCount,
          innerAnchorName,
        };
      }),
    };
  });

export default {
  name: 'ReportList',
  components: {
    MediaLink,
  },
  props: {
    testsArray: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const preparedTestsArray = ref(prepareArray(props.testsArray));

    const anchorsArray = preparedTestsArray.value.reduce((acc, item) => {
      if (!item.tests || !item.tests.length) {
        return {
          ...acc,
          [item.anchorName]: ref(null),
        };
      }

      const innerTests = item.tests.reduce((accTests, innerItem) => ({
        ...accTests,
        [innerItem.innerAnchorName]: ref(null),
      }), {});

      return {
        ...acc,
        [item.anchorName]: ref(null),
        ...innerTests,
      };
    }, {});

    const moveToAnchor = (refName) => {
      const el = anchorsArray[refName];
      el.value[0].scrollIntoView({ behavior: 'smooth' });
    };

    const toggleLine = (index) => {
      preparedTestsArray.value[index].isOpen = !preparedTestsArray.value[index].isOpen;
    };

    const toggleSubLine = (index, subIndex) => {
      preparedTestsArray
        .value[index]
        .tests[subIndex]
        .isOpen = !preparedTestsArray.value[index].tests[subIndex].isOpen;
    };

    watch(() => props.testsArray, () => {
      preparedTestsArray.value = prepareArray(props.testsArray);
    });

    return {
      preparedTestsArray,
      ...anchorsArray,
      toggleLine,
      toggleSubLine,
      moveToAnchor,
    };
  },
};
</script>

<style scoped lang="scss">
@import "ReportList";
</style>
