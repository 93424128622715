<template>
  <PopupWrapper
    :class="{
      'popup-dropdown--disabled': disabled,
    }"
    arrow
    :placement="placement"
    :show="isShow"
  >
    <div class="popup-dropdown__button-wrapper">
      <slot></slot>
    </div>
    <template #popperContent>
      <ul
        v-if="data.length"
        class="popup-dropdown"
      >
        <li
          v-for="(item, index) in selectOptions"
          :key="item.id"
          class="popup-dropdown__text-element"
          :class="{
            'popup-dropdown__text-element--bordered':
            item.underlined && !selectOptions[index - 1].underlined,
          }"
        >
          <button
            v-if="item.id"
            @click="selectHandler(item)"
            class="popup-dropdown__button"
            :class="{
              'popup-dropdown__button--state-active': item.selected,
            }"
          >
            {{item.label}}
          </button>
        </li>
      </ul>
    </template>
  </PopupWrapper>
</template>

<script>
import { ref, watch } from 'vue';
import PopupWrapper from '../PopupWrapper/PopupWrapper.vue';

export default {
  name: 'PopupDropdown',
  components: {
    PopupWrapper,
  },
  emits: ['select-dropdown-item'],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    manualShow: {
      type: [Boolean, null],
      default: null,
    },
    activeItemId: {
      type: String,
      default: '',
    },
    isMultipleChoice: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectOptions = ref(props.data);
    const isShow = ref(props.manualShow);
    const preSelectedItem = ref(selectOptions.value.find(
      (item) => item.id === props.activeItemId,
    ) || {});
    preSelectedItem.value.selected = true;

    const selectHandler = (clickedItem) => {
      preSelectedItem.value = {};
      selectOptions.value.forEach(
        (item, index) => {
          // eslint-disable-next-line
          selectOptions.value[index].selected = item.id === clickedItem.id
            ? props.isMultipleChoice ? !item.selected : true
            : props.isMultipleChoice ? item.selected : false;
        },
      );
      emit('select-dropdown-item', selectOptions.value.filter((item) => item.selected));
      if (!props.isMultipleChoice) {
        isShow.value = false;

        setTimeout(() => {
          isShow.value = null;
        }, 10);
      }
    };

    watch(() => props.manualShow, () => {
      isShow.value = props.manualShow;
    });

    watch(() => props.data, () => {
      selectOptions.value = props.data;
    });

    watch(() => props.activeItemId, () => {
      preSelectedItem.value = props.data.find((item) => item.id === props.activeItemId) || {};
      preSelectedItem.value.selected = true;
    });

    return {
      selectHandler,
      isShow,
      selectOptions,
    };
  },
};
</script>

<style lang="scss">
.popup-dropdown {
  margin: 12px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;

  &--disabled {
    pointer-events: none;
  }

  &__text-element {
    margin: 0;
    padding: 0;

    &--bordered {
      &:before {
        content: '';
        display: block;
        padding-top: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid $grey200;
      }
    }
  }

  &__button {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0 23px 0 24px;
    border: none;
    background-color: transparent;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 24px;
    color: $grey900;
    text-align: left;

    &:hover {
      cursor: pointer;
      background-color: $grey150;
    }

    &--state-active {
      color: $blue500;
      &::before {
        content:'';
        width: 10px;
        height: 7px;
        bottom: 8px;
        left: 9px;
        position: absolute;
        background-size: cover;
        background-image: url('../../assets/checked.svg');
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .popup-dropdown {
    &__text-element {
      margin-top: 10px;

      &:first-child {
        margin: 0;
      }
    }
  }
}
</style>
