import inspectionModel from '@/models/inspection';

export default {
  namespaced: true,
  state: () => ({
    data: {
      inspectionFails: '',
      inspectionMaxRate: '',
      inspectionFactRate: '',
      inspectionListName: '',
      inspectionDate: '',
      successPercentage: null,
    },
    inspectionsArray: [],
  }),
  mutations: {
    'set:inspection:data': function (state, data) {
      state.data.inspectionListName = data.inspection.patternName;
      state.data.inspectionFails = data.inspection.statFails;
      state.data.inspectionMaxRate = data.inspection.maxRate;
      state.data.inspectionFactRate = data.inspection.factRate;
      state.data.inspectionDate = data.inspection.inspectionDate;
      state.data.successPercentage = data.inspection.percent;
    },
    'set:inspection:inspections-array': function (state, data) {
      state.inspectionsArray = data;
    },
  },
  actions: {
    async getInspectionDataById({ commit }, inspectionId) {
      const resp = await inspectionModel.getInspectionById(inspectionId);
      const catchedData = resp;
      commit('set:inspection:data', catchedData);
      const preparedArray = catchedData.items.map((item) => ({
        id: item.id,
        title: item.name,
        tests: item.children.map((subItem) => ({
          id: subItem.id,
          title: subItem.name,
          testsList: subItem.children.map((innerItem) => {
            const media = innerItem.photos || [];
            if (innerItem.videos && innerItem.videos.length) {
              media.push(...innerItem.videos.map(({ url }) => ({
                url,
                isVideo: true,
              })));
            }
            return {
              id: innerItem.id,
              test: innerItem.name,
              status: innerItem.factRate,
              media,
            };
          }),
        })),
      }));
      commit('set:inspection:inspections-array', preparedArray);
    },
  },
};
