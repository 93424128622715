<template>
  <div
    class="calendar-date-picker"
  >
    <Datepicker
      class="calendar-date-picker__datepicker"
      :model-value="isMonthPicker ? datepickerMonthValue : datepickerDateValue"
      :menu-class-name="isMonthPicker ? 'calendar-date-picker__datepicker--month' : ''"
      ref="datepicker"
      auto-apply
      close-on-scroll
      locale="ru-RU"
      model-type="timestamp"
      month-name-format="long"
      :range="isRange"
      :month-picker="isMonthPicker"
      :month-change-on-scroll="false"
      :enable-time-picker="false"
      :clearable="false"
      :close-on-auto-apply="false"
      :day-names="dayNames"
      @update:modelValue="handleDate"
      :position="popupPosition"
    >
      <template #trigger>
        <button
          class="calendar-date-picker__toggle"
          @click="toggleHandler"
        >
          <slot name="toggle"></slot>
        </button>
      </template>
      <template #left-sidebar>
        <div
          v-if="isRange && selectedRangeStrings.length"
          class="calendar-date-picker__result-wrapper"
        >
          <div class="calendar-date-picker__result">
            {{ selectedRangeStrings[0] || '' }}
          </div>
          <span class="calendar-date-picker__result-separator"/>
          <div class="calendar-date-picker__result">
            {{ selectedRangeStrings[1] || '' }}
          </div>
        </div>
        <div
          v-else-if="!isRange && selectedDateString.length"
          class="calendar-date-picker__result-wrapper"
        >
          <div class="calendar-date-picker__result">
            {{ selectedDateString }}
          </div>
        </div>
      </template>
    </Datepicker>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { prepareNumericDate } from '@/helpers/datesAndTime';

export default {
  name: 'calendar-date-picker',
  emits: ['select-date'],
  components: {
    Datepicker,
  },
  props: {
    preselectedDate: {
      type: Number,
      default: null,
    },
    preselectedRange: {
      type: Array,
      default: () => [],
    },
    isMonthPicker: {
      type: Boolean,
      default: false,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    popupPosition: {
      type: String,
      default: 'center',
    },
  },
  setup(props, { emit }) {
    const datepickerDateValue = ref();
    const datepickerMonthValue = ref();
    const pickedDate = ref('');
    const pickedRange = ref([]);
    const datepicker = ref(null);
    const dayNames = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    const selectedDateSeparated = computed(() => {
      if (!pickedDate.value) {
        return {};
      }

      const dateObj = new Date(pickedDate.value);
      return {
        day: dateObj.getDay(),
        month: dateObj.getMonth() + 1,
        year: dateObj.getFullYear(),
      };
    });

    const selectedDateString = computed(() => !pickedDate.value
      ? ''
      : prepareNumericDate(new Date(pickedDate.value), { day: props.isMonthPicker }));

    const selectedRangeStrings = computed(() => !pickedRange.value.length
      ? []
      : pickedRange.value
        .map((rangeDate) => prepareNumericDate(new Date(rangeDate), { day: props.isMonthPicker })));

    const updateSelectedDate = () => {
      if (props.isMonthPicker) {
        const date = new Date(props.preselectedDate);
        datepickerMonthValue.value = {
          month: date.getMonth(),
          year: date.getFullYear(),
        };
      } else {
        datepickerDateValue.value = props.preselectedDate;
      }

      pickedDate.value = props.preselectedDate;
    };

    const updateSelectedRange = () => {
      if (!props.preselectedRange.length) {
        return;
      }

      if (props.isMonthPicker) {
        const dates = props.preselectedRange.map((item) => new Date(item));
        datepickerMonthValue.value = dates.map((date) => ({
          month: date.getMonth(),
          year: date.getFullYear(),
        }));
      } else {
        datepickerDateValue.value = props.preselectedRange;
      }

      pickedRange.value = props.preselectedRange;
    };

    const handleDate = (e) => {
      if (typeof e === 'number') {
        datepickerDateValue.value = e;
        pickedDate.value = e;
        emit('select-date', e);
      }
      if (typeof e.month === 'number') {
        datepickerMonthValue.value = e;
        pickedDate.value = new Date(`${e.month + 1}/1/${e.year}`).getTime();
        emit('select-date', e);
      }
      if (Array.isArray(e)) {
        if (props.isMonthPicker) {
          datepickerMonthValue.value = e;
        } else {
          datepickerDateValue.value = e;
        }
        if (e.some((date) => !date)) {
          return;
        }

        pickedRange.value = !props.isMonthPicker
          ? e
          : e.map((date) => new Date(`${date.month + 1}/1/${date.year}`).getTime());
        emit('select-date', e);
      }
    };

    onMounted(() => {
      if (!props.preselectedDate && !props.preselectedRange) {
        return;
      }

      if (props.preselectedDate && !props.isRange) {
        updateSelectedDate();
        return;
      }

      if (props.preselectedRange.length && props.isRange) {
        updateSelectedRange();
      }
    });

    watch(() => props.preselectedDate, () => {
      updateSelectedDate();
    });

    watch(() => props.preselectedRange, () => {
      updateSelectedRange();
    });

    return {
      handleDate,
      datepicker,
      pickedDate,
      pickedRange,
      datepickerDateValue,
      datepickerMonthValue,
      dayNames,
      selectedDateString,
      selectedRangeStrings,
      selectedDateSeparated,
    };
  },
};

</script>

<style lang="scss">
@import '../../../node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
.dp__theme_light {
  --dp-primary-color: #2B54E2; //$grey400
  --dp-hover-color: #EFF2F6; //$grey200
  --dp-hover-text-color: #6A7383; //$grey800
  --dp-text-color: #6A7383; //$grey800
  --dp-icon-color: #424551; //$grey900
}

.dp {
  &__instance_calendar {
    padding: 0 12px 18px;
  }

  &__today {
    border-color: transparent;
    background-color: $green100;
  }

  &__menu {
    width: 324px;
    box-sizing: border-box;

    &_content {
      &_wrapper {
        flex-direction: column;
      }
    }
  }

  &__sidebar {
    &_left {
      padding: 0 16px;
      border-right: none;
    }
  }

  &__month {
    &_picker {
      &_header {
        height: initial;
        padding: 0;
        margin: 16px 0;
        color: $grey900;
        font-weight: $font-weight-medium;
        font-size: $font-size-medium;
      }
    }

    &_year {
      &_row {
        height: initial;
        margin: 16px 0;
        justify-content: space-between;
        align-items: center;
      }

      &_select {
        text-transform: capitalize;
        height: 22px;
        width: initial;
        line-height: 22px;
        color: $grey900;
        font-weight: $font-weight-medium;
        font-size: $font-size-medium;
        cursor: default;
        pointer-events: none;

        &:last-child {
          margin-left: 5px;
        }
      }

      &_wrap {
        width: initial;
      }

      &_col {
        &_nav {
          svg {
            margin-left: -9px;
          }

          &:last-child {
            margin-left: 0;
            margin-right: -9px;
          }
        }
      }
    }
  }

  &__active_date {
    &.dp__today {
      background-color: var(--dp-primary-color);
      color: var(--dp-primary-text-color);
    }
  }

  &__active_date {
    &:hover {
      background-color: $blue500;
    }
  }

  &__calendar {
    width: 100%;

    &_row {
      margin: 0;
    }

    &_header {
      width: 100%;

      &_item {
        color: $grey800;
        font-family: 'Ubuntu', sans-serif;
        font-weight: $font-weight-regular;
        font-size: $font-size-small;
        text-transform: capitalize;
      }

      &_separator {
        display: none;
      }
    }

    &_item {
      display: flex;
      justify-content: center;
      width: 42px;
      line-height: 10px;
      color: $grey800;
      font-family: 'Ubuntu', sans-serif;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
    }
  }

  &__cell {
    &_in_between {
      background: var(--dp-primary-color);
      color: var(--dp-primary-text-color);

      &:hover {
        background: $blue500;
        color: var(--dp-primary-text-color);
      }
    }

    &_inner {
      width: 100%;
      border-radius: 0;
    }
  }

  &__overlay {
    border-radius: 8px;

    &_container {
      padding: 0 16px 16px;
    }

    &_row {
      max-height: 53px;
    }

    &_col {
      height: 53px;
      width: 33.33%;
      padding: 0;
    }

    &_cell {
      &_pad {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;
        border-radius: 0;
      }

      &_active {
        background: $blue500;

        &:hover {
          background: $blue500;
          color: var(--dp-primary-text-color);
        }
      }
    }
  }

  &__range {
    &_start, &_end {
      &:hover {
        background: $blue500;
      }
    }
    &_between {
      color: var(--dp-primary-text-color);
      background: var(--dp-primary-color);
      border-top: 1px solid var(--dp-primary-color);
      border-bottom: 1px solid var(--dp-primary-color);

      &:hover {
        background: $blue500;
      }
    }
  }

  &__inner {
    &_nav {
      width: 18px;
      height: 18px;

      &:hover {
        background: transparent;
        color: initial;
      }
    }
  }
}

.calendar-date-picker {
  &__toggle {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    &-label {
      color: $grey900;
      font-weight: $font-weight-medium;
      font-size: $font-size-medium;
      line-height: 22px;
      text-transform: capitalize;
    }
  }

  &__result {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid $grey300;
    border-radius: 4px;
    color: $grey900;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    line-height: 12px;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid $grey200;
    }

    &-separator {
      height: 2px;
      width: 16px;
      margin: 0 18px;
      background-color: $grey800;
    }
  }

  &__datepicker {
    &--month {
      .dp {
        &__instance_calendar {
          max-height: 287px;
        }
      }
    }
  }
}
</style>
