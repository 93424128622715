<template>
  <dashboard-layout
    class="outcome-periphery-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="income-outcome-periphery"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <div class="outcome-periphery-page__content-block">
        <div class="outcome-periphery-page__title-block">
          <div class="outcome-periphery-page__title-block-text-area">
            <title-main
              class="outcome-periphery-page__title"
              text="Доходы и расходы по коммерческой части"
              icon
            >
              <template #icon>
                <img src="../assets/circle-graph-icon.svg" alt="dashboard-icon">
              </template>
            </title-main>
            <div class="outcome-periphery-page__subtitle">
              Все показатели на этой странице транслируются
               напрямую из банковского аккаунта локации.
              Так вы можете видеть все операции, которые влияют на ваш доход.
            </div>
          </div>
          <div class="outcome-periphery-page__controls">
            <div class="outcome-periphery-page__controls-item">
              <filter-date
                arrow-icon
                calendar-only
                is-month-picker
                no-day-button
                @filter-date-select="getIncomeOutcomeByMonth"
              />
            </div>
          </div>
        </div>
        <summary-table>
          <div class="summary-table__row">
            <div class="summary-table__block">
              <summary-info
                header="Доходы комплекса"
                :firstInfo="incomeTotal"
                :firstMetric="incomePercent"
              />
            </div>
            <div class="summary-table__block">
              <summary-info
                header="Расходы комплекса (OPEX)"
                :firstInfo="outcomeTotal"
                :secondInfo="outcomePercent"
                secondSubtitle="от валовой выручки"
                :popupData="outcomePopupData.opex"
              />
            </div>
          </div>
          <div class="summary-table__row">
            <div class="summary-table__block">
              <summary-info
                header="GOP"
                :firstInfo="gopData"
                :secondInfo="gopPercent"
                secondSubtitle="от валовой выручки"
                :popupData="outcomePopupData.gop"
              />
            </div>
          </div>
        </summary-table>
        <div class="outcome-periphery-page__tabs-block">
          <ui-tabs
            :list="outcomeTabs"
            :preselectedTabId="outcomeTabs[0].id"
            hasContent
            flat
          >
            <!-- <template #buttons>
              <secondary-button
                label="Файл XLS"
                :is-disabled="haveNoData"
                @click="downloadHandler"
              >
                <template #iconBefore>
                  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.46968 0.221406C5.76255 -0.0738527
                    6.23745 -0.0738527 6.53032
                    0.221406L9.53032 3.24567C9.8232 3.54094 9.8232
                    4.01965 9.53032 4.31491C9.23745
                    4.61017 8.76255 4.61017 8.46968 4.31491L6.75
                    2.58133V8.35035C6.75 8.76793 6.41423
                    9.10642 6 9.10642C5.58577 9.10642 5.25 8.76793
                    5.25 8.35035V2.58133L3.53033
                    4.31491C3.23744 4.61017 2.76256 4.61017 2.46967
                    4.31491C2.17678 4.01965 2.17678
                    3.54094 2.46967 3.24567L5.46968 0.221406ZM0.75
                    8.21967C1.16421 8.21967 1.5 8.55816
                    1.5 8.97574V10.4879H10.5V8.97574C10.5 8.55816
                    10.8358 8.21967 11.25 8.21967C11.6642
                    8.21967 12 8.55816 12 8.97574V10.4879C12 11.323
                    11.3285 12 10.5 12H1.5C0.671572 12
                    0 11.323 0 10.4879V8.97574C0 8.55816 0.33579
                    8.21967 0.75 8.21967Z"/>
                  </svg>
                </template>
              </secondary-button>
            </template> -->
            <template #income>
              <outcome-table
                :outcomes="[]"
                isIncome
              />
            </template>
            <template #outcome>
              <outcome-table
                :outcomes="[]"
              />
            </template>
          </ui-tabs>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { updateMoneySum } from '@/helpers/common';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
// import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import FilterDate from '@/components/FilterDate/FilterDate.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import SummaryInfo from '@/components/SummaryInfo/SummaryInfo.vue';
import SummaryTable from '@/components/SummaryTable/SummaryTable.vue';
import OutcomeTable from '@/components/OutcomeTable/OutcomeTable.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { OUTCOME_PAGE_TAB_TITLES, OUTCOME_PAGE_POPUPS_DATA } from '@/constants/outcome';
import { useRoute } from 'vue-router';

export default {
  name: 'OutcomePeripheryPage',
  components: {
    TitleMain,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    FilterDate,
    // SecondaryButton,
    UserActives,
    OutcomeTable,
    SummaryInfo,
    SummaryTable,
    UserManager,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const outcomeState = store.state.outcome;
    const userState = store.state.user;
    const userManagerData = computed(() => userState.managerData);
    const menuItems = computed(() => userState.sideMenuItems);
    const outcomeTabs = ref(OUTCOME_PAGE_TAB_TITLES);
    const outcomePopupData = ref(OUTCOME_PAGE_POPUPS_DATA);
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    // const gopData = computed(() => (outcomeState.allData.gop
    // ? `${updateMoneySum(outcomeState.allData.gop)}\u202F₽`
    // : '0\u202F₽'));
    const gopData = computed(() => {
      if (outcomeState.allData.gop >= 0) {
        return (outcomeState.allData.gop
          ? `${updateMoneySum(outcomeState.allData.gop)}\u202F₽`
          : '0\u202F₽');
      }
      return (outcomeState.allData.gop
        ? `-${updateMoneySum(Math.abs(outcomeState.allData.gop))}\u202F₽`
        : '0\u202F₽');
    });
    const gopPercent = computed(() => `${outcomeState.allData.gopPercent}%`);
    const incomeTotal = computed(() => (outcomeState.allData.incomeTotal
      ? `${updateMoneySum(outcomeState.allData.incomeTotal)}\u202F₽`
      : '0\u202F₽'));
    const incomePercent = computed(() => `${outcomeState.allData.incomePercent}%`);
    const outcomeTotal = computed(() => (outcomeState.allData.outcomeTotal
      ? `${updateMoneySum(outcomeState.allData.outcomeTotal)}\u202F₽`
      : '0\u202F₽'));
    const outcomePercent = computed(() => `${outcomeState.allData.outcomePercent}%`);
    const incomesList = computed(() => outcomeState.incomesList);
    const outcomesList = computed(() => outcomeState.outcomesList);

    const initOutcomeData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      store.dispatch('outcome/setDefaultPeripheryData');
    };

    /* const downloadHandler = () => {
      store.dispatch('outcome/downloadOutcomeTable');
    };
    */
    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initOutcomeData();
    };

    const getIncomeOutcomeByMonth = ({ month, year }) => {
      store.dispatch('outcome/setSelectedPeripheryData', { year, month });
    };

    initOutcomeData();

    return {
      userUnits,
      menuItems,
      userLocations,
      currentUserLocation,
      outcomeTabs,
      gopData,
      gopPercent,
      incomeTotal,
      incomePercent,
      outcomeTotal,
      outcomePercent,
      incomesList,
      outcomesList,
      outcomePopupData,
      locationChangeHandler,
      // downloadHandler,
      getIncomeOutcomeByMonth,
      userManagerData,
    };
  },
};
</script>

<style lang="scss">
.outcome-periphery-page {

  &__content-block {
    padding-right: 25px;
    display: flex;
    flex-direction: column;
  }

  &__controls {
    display: flex;
    margin-top: 1px;
    border-bottom: 1px solid $grey200;

    &-item {
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      .secondary-button {
        text-transform: capitalize;
      }
      .popup-dropdown__button {
        text-transform: capitalize;
      }
    }
  }

  &__table {
    margin-top: 16px;
  }

  &__title {
    &-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &-text-area {
        display: flex;
        flex-direction: column;
        max-width: 740px;
      }
    }
  }

  &__subtitle {
    margin-top: 8px;
    color:$grey800;
    font-size: $font-size-regular;
    line-height: 18px;
    font-weight: $font-weight-regular;
  }

  &__tabs-block {
    margin-top: 60px;

    .tabs__list {
      border-bottom: 1px solid $grey200;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .outcome-periphery-page {
    &__table {
      margin-top: 24px;
    }

    &__amount-outcome {
      margin-top: 20px;
      .unit-income {
        padding: 0;

        &__info-wrapper {
          margin: 0;
        }
      }
    }
  }
}
</style>
