<template>
  <div class="stub-block-no-data__wrapper">
    <div class="stub-block-no-data">
      <div class="stub-block-no-data__icon-wrapper">
        <slot name="icon"></slot>
      </div>
      <div class="stub-block-no-data__title">
        {{ title }}
      </div>
      <div class="stub-block-no-data__subtitle">
        {{ subTitle }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StubBlockNoData',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.stub-block-no-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 265px;

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__title {
    margin-top: 24px;
    color: $main-black;
    font-size: $font-size-title-small;
    font-weight: $font-weight-bold;
  }

  &__subtitle {
    margin-top: 8px;
    line-height: 18px;
    color: $grey800;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
  }

  &__icon {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: $grey200;
      fill: $grey700;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .stub-block-no-data {
    align-items: center;
    text-align: center;
  }
}
</style>
