import { sendRequest } from '@/helpers/request';
import {
  API_LOCALE_GET_USER,
  API_LOCALE_SET_USER,
  API_LOCALE_GET_ALL,
} from './api_pathes';

async function getAllLocales() {
  try {
    const resp = await sendRequest(API_LOCALE_GET_ALL, null, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[LOCALE] Something went wrong with request to get all locales', error);
    return {};
  }
}

async function getUserLocale() {
  try {
    const resp = await sendRequest(API_LOCALE_GET_USER, null, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[LOCALE] Something went wrong with request to get user locale', error);
    return {};
  }
}

async function setUserLocale(userLocale) {
  try {
    const resp = await sendRequest(API_LOCALE_SET_USER, { userLocale }, 'POST', false, false, true);
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[LOCALE] Something went wrong with request to set user locale', error);
    return {};
  }
}

export default {
  getUserLocale,
  getAllLocales,
  setUserLocale,
};
