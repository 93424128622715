<template>
  <PopupWrapper
    arrow
    placement="top-start"
  >
  <slot></slot>
    <template #popperContent>
      <div class="popup-info">
        <p
          v-if="popupHeader"
          class="popup-info__header"
        >
          {{popupHeader}}
        </p>
        <p
          v-if="popupText"
          v-html="popupText"
          class="popup-info__text"
        >
        </p>
      </div>
    </template>
  </PopupWrapper>
</template>

<script>
import PopupWrapper from '../PopupWrapper/PopupWrapper.vue';

export default {
  name: 'PopupInfo',
  components: {
    PopupWrapper,
  },
  props: {
    popupHeader: {
      type: String,
      default: '',
    },
    popupText: {
      type: String,
      default: '',
    },
  },
};

</script>

<style scoped lang="scss">
.popup-info {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 12px 16px;
  font-size: $font-size-regular;
  line-height: 18px;
  color: $grey900;

  &__header {
    font-weight: $font-weight-bold;
    margin: 0 0 4px 0;
    padding: 0;

    &:last-child {
      margin: 0;
    }
  }

  &__text {
    font-weight: $font-weight-medium;
    padding: 0;
    margin: 0;
  }
}
</style>
