export const BASE_DATA = [
  {
    id: 'phone',
    label: 'Телефон',
    required: true,
    inputType: 'tel',
    editable: true,
  },
  {
    id: 'email',
    label: 'E-mail',
    required: true,
    editable: true,
  },
];

export const BANK_DATA_IP = [
  {
    id: 'inn',
    label: 'ИНН',
  },
  {
    id: 'ogrn',
    label: 'ОГРНИП',
  },
  {
    id: 'bik',
    label: 'БИК банка',
  },
  {
    id: 'bankName',
    label: 'Наименование банка',
  },
  {
    id: 'paymentAccount',
    label: 'Расчетный счет',
  },
  {
    id: 'address',
    label: 'Адрес для корреспонденции',
  },
];

export const BANK_DATA_OOO = [
  {
    id: 'inn',
    label: 'ИНН',
  },
  {
    id: 'kpp',
    label: 'КПП',
  },
  {
    id: 'ogrn',
    label: 'ОГРН',
  },
  {
    id: 'bik',
    label: 'БИК',
  },
  {
    id: 'bankName',
    label: 'Наименование банка',
  },
  {
    id: 'paymentAccount',
    label: 'Расчетный счет',
  },
  {
    id: 'jurAddress',
    label: 'Юридический адрес',
  },
  {
    id: 'address',
    label: 'Адрес для корреспонденции',
  },
];

export const PATHS_UNRELATED_TO_LOCATIONS = ['/faq', '/news', '/promo', '/profile'];
