<template>
  <info-block
    class="stub-download-report"
    :withLabel="false"
  >
    <template #BlockTitle>
      Промежуточный финансовый отчет о работе группы компаний dewis
    </template>
    <template #BlockButton>
      <primary-button
        label="Посмотреть отчет"
        @click="handleClick"
      />
    </template>
  </info-block>
</template>

<script>
import { useRouter } from 'vue-router';
import InfoBlock from '@/components/InfoBlock/InfoBlock.vue';
import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';

export default {
  name: 'StubDownloadReport',
  components: {
    InfoBlock,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const handleClick = () => {
      router.push('dashboard/report-2024');
    };

    return {
      handleClick,
    };
  },
};

</script>

<style lang="scss">
.stub-download-report {
  background-color: $grey100;

  .info-block {
    &__column {
      flex-direction: row;
      align-items: center;
      padding: 32px 20px;
      justify-content: space-between;
      width: 100%;
    }

    &__title {
      color: $main-black;
      line-height: 24px;
      font-size: $font-size-title-small;
    }
  }

  .primary-button {
    margin-left: 16px;
    width: 200px;
    color: $blue400;
    border: 1px solid $blue400;
    line-height: 18px;
    font-size: $font-size-regular;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .stub-download-report {
    .info-block {
      &__column {
        flex-direction: column;
        padding: 24px 32px;
      }

      &__title {
        line-height: 22px;
        font-size: $font-size-medium;
      }

      &__btn {
        width: 100%;
      }
    }

    .primary-button {
      width: 100%;
      margin-top: 16px;
      margin-left: 0;
    }
  }
}
</style>
