<template>
  <div class="unit-income">
    <div class="unit-income__header">
      <InfoHeader
        :text="headerText"
      />
      <slot name="link"/>
    </div>
    <div class="unit-income__info-wrapper loading-stub__spinner">
      <p class="unit-income__text">{{priceText}}</p>
      <MetricResult
        class="unit-income__metric"
        :status="metricNumber"
      />
    </div>
  </div>
</template>

<script>
import InfoHeader from '../../ui-kit/InfoHeader/InfoHeader.vue';
import MetricResult from '../../ui-kit/MetricResult/MetricResult.vue';

export default {
  name: 'UnitIncome',
  components: {
    InfoHeader,
    MetricResult,
  },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    priceText: {
      type: String,
      default: '',
    },
    metricNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.unit-income {
  display: flex;
  flex-direction: column;

  &__info-wrapper {
    margin: 32px 0 24px;
    display: flex;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    margin: 0;
    padding: 0;
    line-height: 24px;
    font-size: $font-size-title-large;
    font-weight: $font-weight-regular;
    color: $main-black;
  }

  &__metric {
    margin-left: 12px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .unit-income {
    &__text {
      font-size: $font-size-title;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .unit-income {
    padding: 24px 0;

    &__info-wrapper {
      margin: 12px 0 16px 0;
    }
  }
}

@import '/src/styles/loading-stub.scss';
</style>
