<template>
  <PopupWrapper
    arrow
    placement="bottom-end"
    class="personal-menu__wrapper"
  >
    <div
      class="personal-menu__pic"
      :class="{
        'personal-menu__pic--active': isCurrentPathProfile,
      }"
    >
      <avatar-icon
        :picture-url="userPic"
        :user-name="userName"
      />
    </div>
    <template #popperContent>
      <div class="personal-menu">
        <p class="personal-menu__title">{{ $t('investor') }}</p>
        <p class="personal-menu__name">{{ userName }}</p>
        <div class="personal-menu__line"></div>
        <ul class="personal-menu__links">
          <li
            class="personal-menu__link"
            v-for="item in menuItems"
            :key="item.id"
          >
            <router-link
              class="personal-menu__link-btn"
              :to="item.path"
            >
              {{ $t(item.text) }}
            </router-link>
          </li>
          <li class="personal-menu__link">
            <button
              class="personal-menu__link-btn"
              @click="logoutHandler"
            >
              {{ $t('exit') }}
            </button>
          </li>
        </ul>
        <div class="personal-menu__secondary-content">
          <slot name="secondaryContent">
          </slot>
        </div>
      </div>
    </template>
  </PopupWrapper>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import AvatarIcon from '../AvatarIcon/AvatarIcon.vue';
import PopupWrapper from '../PopupWrapper/PopupWrapper.vue';

const profilePagesPaths = ['/units/', '/profile/'];

export default {
  name: 'PersonalMenu',
  components: {
    PopupWrapper,
    AvatarIcon,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
    userPic: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const logoutHandler = async () => {
      await store.dispatch('user/logout');
      await router.push('/login');
    };

    const isCurrentPathProfile = computed(() => profilePagesPaths.includes(route.path));

    return {
      logoutHandler,
      isCurrentPathProfile,
    };
  },
};
</script>

<style scoped lang="scss">
.personal-menu {
  display: flex;
  flex-direction: column;
  min-width: 311px;
  padding: 16px 20px;
  box-sizing: border-box;

  &__pic {
    width: 36px;
    height: 36px;
    border: 1px solid transparent;
    border-radius: 50%;

    &:hover, &--active {
      border-color: $blue400;
      cursor: pointer;
    }
  }

  &__title {
    margin: 0;
    padding: 0;
    color: $grey700;
    font-size: $font-size-tiny;
    font-weight: $font-weight-half-bold;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__name {
    margin: 0;
    padding: 0 0 16px 0;
    color: $main-black;
    font-size: $font-size-title-small;
    font-weight: $font-weight-bold;
    line-height: 24px;
  }

  &__line {
    width: 100%;
    border-bottom: 1px solid $grey300;
  }

  &__links {
    margin: 12px -20px 0;
    padding: 0;
    list-style-type: none;
  }

  &__link {
    height: 26px;
    line-height: 26px;
    margin: 4px 0 0;
    padding: 0 20px;
    display: flex;
    align-items: center;

    &:first-child {
      margin: 0;
    }

    &:hover {
      background-color: $grey200;
      cursor: pointer;

      .personal-menu__link-btn {
        color: $main-black;
      }
    }
  }

  &__link-btn {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    color: $blue500;
    font-size: $font-size-regular;
    font-weight: $font-weight-medium;
    text-decoration: none;
    line-height: 18px;
    cursor: pointer;
  }

  &__secondary-content {
    display: none;
    margin-top: 0;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .personal-menu {

    &__links {
      padding: 12px 0 0;
    }

    &__secondary-content {
      display: block;
      margin: 0;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .personal-menu {
    &__wrapper {
      display: none !important;
    }
  }
}
</style>
