<template>
  <info-block
    class="stub-promo-code"
    :withLabel="false"
  >
    <template #BlockTitle>
      {{ $t('blockTitle') }}
    </template>
    <template #BlockText>
      <p>{{ $t('blockText') }}</p>
    </template>
    <template #BlockImage>
      <div class="stub-promo-code__img-wrapper">
        <img src="../../assets/promoBannerImg.png" alt="promo banner img">
      </div>
    </template>
    <template #BlockButton>
      <div class="stub-promo-code__btn-wrapper">
        <div
          class="stub-promo-code__code-area"
          @click="copyCode"
        >
          <info-header
            underlineDisabled
            :text="promoCode"
            :popupData="{
              text: `${$t('tooltipText')}`,
            }"
          />
        </div>
        <secondary-button
          blue
          :label="$t('buttonText')"
          @click="openLink"
        />
      </div>
    </template>
  </info-block>
</template>

<script>
import { ref } from 'vue';
import InfoBlock from '@/components/InfoBlock/InfoBlock.vue';
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import { useI18n } from 'vue-i18n';
import transcriptions from './transcriptions';

export default {
  name: 'StubPromoCode',
  components: {
    InfoBlock,
    SecondaryButton,
    InfoHeader,
  },
  props: {
    promoCode: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      deafult: '',
    },
  },
  setup(props) {
    const { mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', transcriptions.en);
    mergeLocaleMessage('ru', transcriptions.ru);
    const linkFromProps = ref(props.link);
    const preparedPromoCode = ref(props.promoCode);
    const openLink = () => {
      window.open(linkFromProps.value, '_blank');
    };
    const copyCode = () => {
      navigator.clipboard.writeText(preparedPromoCode.value);
    };

    return {
      openLink,
      copyCode,
    };
  },
};
</script>

<style lang="scss">
.stub-promo-code {
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: flex-end;

  &__code-area {
    cursor: pointer;
    padding: 0 16px;
    background-color: $white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .info-header {
      line-height: 22px;
      font-size: $font-size-medium;
      color: $grey900;
    }

    .popup-info {
      width: auto;
    }
  }

  &__btn {
    &-wrapper {
      display: flex;
      margin-top: 4px;
      align-items: stretch;

      .secondary-button {
        margin-left: 16px;
      }
    }
  }

  &__img {
    &-wrapper {
      width: 102px;
      height: 102px;
      margin: 28px 32px;

      img {
        object-fit: cover;
        width: inherit;
        height: inherit;
      }
    }
  }

  .info-block {
    &__btn {
      flex-grow: 1;
      display: flex;
      align-items: stretch;
    }

    &__column {
      flex-shrink: 1;
      padding: 28px 126px 28px 0;
    }

    &__title {
      font-size: $font-size-title-mid-small;
      line-height: 26px;
      font-weight: $font-weight-medium;
      color: $main-black;
      letter-spacing: -0.3px;
    }

    &__text {
      p {
        margin: 0;
        padding: 0;
        font-size: $font-size-regular;
        font-weight: $font-weight-regular;
        line-height: 18px;
        color: $grey800;
        letter-spacing: -0.3px;
      }
    }
  }
}

@media screen and (max-width:$tablet-breakpoint) {
  .stub-promo-code {
    margin-top: 35px;

    .info-block {
      &__column {
        flex-shrink: 1;
        padding: 28px 52px 28px 0;
      }
    }
  }
}

@media screen and (max-width:$mobile-breakpoint) {
  .stub-promo-code {
    margin-top: 0;
    width: calc(100% + 40px);
    margin-left: -20px;

    &__img-wrapper {
      display: none;
    }

    .secondary-button {
      margin-top: 16px;
      margin-left: 0;
      height: 38px;
      justify-content: center;
    }

    .info-block {
      &__column {
        padding: 24px 32px;
      }

      &__title {
        font-size: $font-size-medium;
        line-height: 22px;
      }

      &__text {
        p {
          font-size: $font-size-small;
          line-height: 16px;
        }
      }

      &__btn {
        flex-grow: 0;
        align-items: flex-start;
      }
    }

    &__btn-wrapper {
      flex-direction: column;
      width: 100%;
    }

    &__code-area {
      width: 133px;
      height: 42px;

      .popup-info {
        width: 220px;
        font-size: $font-size-small;
        padding: 5px 10px;
      }
    }
  }
}
</style>
