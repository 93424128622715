export default {
  en: {
    uploadPhoto: 'Upload a photo',
    deletePhoto: 'Delete a photo',
    formats: 'Formats: JPEG, JPG, PNG. Up to 2 MB',
  },
  ru: {
    uploadPhoto: 'Загрузить фото',
    deletePhoto: 'Удалить фото',
    formats: 'Формат jpeg, jpg, png. Размер до 2 МБ',
  },
};
