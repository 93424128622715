<template>
  <span
    class="ui-label"
    :style="labelStyle"
  >
    {{ label }}
  </span>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'UiLabel',
  props: {
    label: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const labelStyle = computed(() => !props.background
      ? ''
      : `background: ${props.background};`);

    return {
      labelStyle,
    };
  },
};
</script>

<style lang="scss">
.ui-label {
  display: inline-block;
  max-width: 100%;
  margin: 0;
  padding: 4px 10px;
  border-radius: 30px;
  line-height: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: $green100;
  color: rgba(16, 37, 62, 0.7);
  font-size: $font-size-small;
  font-weight: $font-weight-regular;

  &--error {
    background-color: $red200;
    color: $red500;
  }
}
</style>
