<template>
  <two-column-layout
    class="faq-page"
  >
    <template #main-content>
      <div class="faq-page__inner">
        <h2 class="faq-page__title">FAQ</h2>
        <div class="faq-page__table">
          <faq-question
            :questions-array="faqData"
          />
      </div>
      </div>
    </template>
    <template #after-inner-content>
      <div class="faq-page__question-block">
        <template
          v-if="!questionStatus"
        >
          <h2 class="faq-page__title faq-page__question-title">{{ t('faqPage.askQuestion') }}</h2>
          <div class="faq-page__question-text">
            {{ t('faqPage.questionText') }}
          </div>
          <textarea
            v-model="questionText"
            class="faq-page__question-textarea"
            name="ask_question"
            rows="5"
            :placeholder="t('faqPage.askYourQuestion')"
          />

          <div class="faq-page__question-controls">
            <primary-button
              :label="t('send')"
              green-light
              @click="sendQuestion"
            />
          </div>
        </template>
        <div
          v-else
          class="faq-page__question-status"
          :class="{
            'faq-page__question-status--ok': questionStatus,
            'faq-page__question-status--error': !questionStatus,
          }"
        >
          <div class="faq-page__question-status-row">
            <img src="../assets/gear_ok.svg" alt="ok">
            <h4>{{ t('faqPage.questionSent') }}</h4>
          </div>
          <div class="faq-page__question-status-row">
            <span>{{ t('faqPage.tnhx') }}</span>
          </div>
        </div>
      </div>
    </template>
  </two-column-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue';
import FaqQuestion from '@/components/FaqQuestion/FaqQuestion.vue';
import { useRoute } from 'vue-router';
import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';
import userModel from '@/models/user';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';

export default {
  name: 'FaqPage',
  components: {
    PrimaryButton,
    FaqQuestion,
    TwoColumnLayout,
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const userState = store.state.user;
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const faqData = computed(() => userState.faqData);
    const menuItems = computed(() => userState.sideMenuItems);
    const questionText = ref('');
    const questionStatus = ref(false);
    const questionStatusText = ref('');

    const initUnitPageData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/getUserUnitsFullInfo');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('user/getFaq');
    };

    const sendQuestion = async () => {
      if (questionText.value && questionText.value.length) {
        const resp = await userModel.sendQuestion({ question: questionText.value });

        if (resp) {
          questionStatus.value = true;
        }

        setTimeout(() => {
          questionStatus.value = false;
        }, 5000);
      }
    };

    initUnitPageData();
    return {
      t,
      menuItems,
      userLocations,
      currentUserLocation,
      faqData,
      questionText,
      questionStatus,
      questionStatusText,
      sendQuestion,
    };
  },
};
</script>

<style lang="scss" scoped>
.faq-page {
  &__title {
    margin: 0;
    color: $main-black;
    font-weight: $font-weight-bold;
    font-size: $font-size-title-large;
    line-height: 40px;
  }

  &__table {
    margin-top: 18px;
  }

  &__inner {
    padding-left: 68px;
  }

  &__question {
    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      min-height: 393px;
      width: 100%;
      padding: 60px 32px 60px 100px;
      background-color: $blue100;
    }

    &-text {
      max-width: 665px;
      margin-top: 4px;
      font-size: $font-size-medium;
      color: $grey900;
      font-weight: $font-weight-regular;
    }

    &-textarea {
      width: 100%;
      max-width: 1087px;
      margin-top: 32px;
      padding: 10px;
      border: 1px solid $grey300;
      border-radius: 4px;

      &:active, &:focus {
        outline: none;
      }

      &::placeholder {
        font-family: 'Inter';
        color: $grey400;
      }
    }

    &-controls {
      margin-top: 16px;
    }

    &-status {
      font-size: $font-size-medium;

      &-row {
        display: flex;

        h4 {
          font-size: $font-size-title;
        }

        img {
          margin-right: 8px;
        }
      }

      &--error {
        color: $red500;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .faq-page {
    &__title {
      font-size: $font-size-title;
      line-height: 28px;
    }

    &__inner {
      padding-left: 0;
    }

    &__question {
      &-block {
        padding: 57px 24px 50px 24px;
        width: 100%;
        min-height: 300px;
      }

      &-textarea {
        width: calc(100% - 32px);
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .faq-page {
    &__title {
      font-size: $font-size-title-mid-large;
      line-height: 24px;
    }

    &__question {
      &-block {
        padding: 48px 24px 48px 26px;
        width: 100%;
      }

      &-text {
        font-size: $font-size-regular;
        margin-top: 12px;
      }

      &-textarea {
        position: relative;
        margin-top: 32px;
        width: calc(100% - 22px);
      }

      &-controls {
        margin-top: 12px;
        width: 100%;

        .primary-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
