<template>
  <div
    class="minimized-area-chart"
    v-if="chartSeries.length"
  >
    <apexchart
      width="100%"
      height="100%"
      type="area"
      :series="chartSeries"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import VueApexcharts from 'vue3-apexcharts';

export default {
  name: 'MinimizedAreaChart',
  components: {
    apexchart: VueApexcharts,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const chartData = computed(() => props.chartData);
    const chartOptions = computed(() => {
      if (!props.chartData) {
        return [];
      }

      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          type: 'category',
          overwriteCategories: chartData.value.preparedLabels,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          axisBorder: false,
        },
        stroke: {
          curve: 'straight',
          width: 1,
        },
        grid: {
          show: true,
          borderColor: '#FC802F',
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: true,
          custom({ dataPointIndex, seriesIndex, w }) {
            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            return `<div class="minimized-area-chart__tooltip">
              <span class="minimized-area-chart__tooltip-label">${data.tooltipDate}</span>
              <span class="minimized-area-chart__tooltip-sum">${data.amount} ₽</span>
            </div>`;
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        colors: ['#093DC7'],
      };
    });

    const chartSeries = computed(() => {
      if (!props.chartData) {
        return [];
      }

      const preparedData = props.chartData.series.map((item) => ({
        x: item.date,
        y: item.amount,
        ...item,
      }));

      return [{
        data: preparedData,
      }];
    });

    return {
      chartOptions,
      chartSeries,
    };
  },
};
</script>

<style lang="scss">
.minimized-area-chart {
  width: 100%;
  height: 140px;

  &__tooltip {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 60px;
    padding: 12px 16px;
    color: $grey900;
    background: #fff;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #dbdcde 0%, #c5c6c9 100%);
    box-shadow: 0 7px 13px 0 #ACB0C25E;

    &-label {
      font-size: $font-size-small;
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-sum {
      margin-top: 4px;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
    }
  }

  .apexcharts {
    &-svg {
      overflow: visible;
    }

    &-inner {
      transform: scale(1.03);
    }

    &-xaxis {
      &-texts-g {
        .apexcharts-xaxis-label {
          fill: rgb(106, 115, 131);

          &:first-child {
            transform: translate(25px, 0);
          }

          &:last-child {
            transform: translate(-25px, 0);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .minimized-area-chart {
    .apexcharts {
      &-inner {
        transform: scale(1.2);
      }
    }
  }
}
</style>
