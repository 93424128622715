export const USER_ROLES = {
  investor: {
    id: 1,
    code: 'INVESTOR',
    name: 'Инвестор',
  },
  lender: {
    id: 2,
    code: 'LENDER',
    name: 'Займодатель',
  },
};
export const MAIN_SIDE_MENU_ITEMS_LIST = {
  [USER_ROLES.investor.code]: [
    {
      id: 'main',
      label: 'menuMain',
      path: '/dashboard/',
    },
    {
      id: 'income',
      label: 'menuIncome',
      path: '/dashboard/income/',
    },
    {
      id: 'timeline',
      label: 'menuTimeline',
      path: '/dashboard/timeline/',
    },
    {
      id: 'reports',
      label: 'menuReports',
      path: '/dashboard/reports/',
    },
    {
      subId: 'units',
      subMenuLabel: 'menuUnits',
      subMenuItems: [
        {
          id: 'booking-rooms',
          label: 'menuBooking',
          path: '/dashboard/booking/',
        },
        {
          id: 'income-outcome-rooms',
          label: 'menuIncomeOutcome',
          path: '/dashboard/outcome/',
          tooltip: {
            header: 'menuTooltipHeaderUnavailable',
            text: 'menuTooltipTextUnavailable',
          },
        },
      ],
    },
    {
      subId: 'peripheryUnits',
      subMenuLabel: 'menuPeriphery',
      subMenuItems: [
        {
          id: 'booking-periphery',
          label: 'menuBooking',
          path: '/dashboard/booking-periphery/',
        },
        {
          id: 'income-outcome-periphery',
          label: 'menuIncomeOutcome',
          path: '/dashboard/outcome-periphery/',
          tooltip: {
            header: 'menuTooltipHeaderUnavailable',
            text: 'menuTooltipTextUnavailable',
          },
        },
      ],
    },
    {
      subMenuLabel: 'menuControls',
      subMenuItems: [
        {
          id: 'cameras',
          label: 'menuCameras',
          path: '/*',
          soon: true,
        },
        {
          id: 'control',
          label: 'menuChecks',
          path: '/dashboard/control',
        },
        {
          id: 'inventory',
          label: 'menuInventory',
          path: '/inventory',
        },
      ],
    },
  ],
  [USER_ROLES.lender.code]: [
    {
      id: 'main',
      label: 'menuMain',
      path: '/dashboard/',
    },
    {
      id: 'income',
      label: 'menuIncome',
      path: '/dashboard/income/',
    },
    {
      id: 'timeline',
      label: 'menuTimeline',
      path: '/dashboard/timeline/',
    },
    {
      id: 'reports',
      label: 'menuReports',
      path: '/dashboard/reports/',
    },
    {
      subId: 'units',
      subMenuLabel: 'menuUnits',
      subMenuItems: [
        {
          id: 'booking-rooms',
          label: 'menuBooking',
          path: '/dashboard/booking/',
          tooltip: {
            header: 'lenderMenuTooltipHeaderUnavailabe',
            text: 'lenderMenuTooltipTextUnavailable',
          },
        },
        {
          id: 'income-outcome-rooms',
          label: 'menuIncomeOutcome',
          path: '/dashboard/outcome/',
          inactive: true,
          tooltip: {
            header: 'lenderMenuTooltipHeaderUnavailabe',
            text: 'lenderMenuTooltipTextUnavailable',
          },
        },
      ],
    },
    {
      subId: 'peripheryUnits',
      subMenuLabel: 'menuPeriphery',
      subMenuItems: [
        {
          id: 'booking-periphery',
          label: 'menuBooking',
          path: '/dashboard/booking-periphery/',
          tooltip: {
            header: 'lenderMenuTooltipHeaderUnavailabe',
            text: 'lenderMenuTooltipTextUnavailable',
          },
        },
        {
          id: 'income-outcome-periphery',
          label: 'menuIncomeOutcome',
          path: '/dashboard/outcome-periphery/',
          inactive: true,
          tooltip: {
            header: 'lenderMenuTooltipHeaderUnavailabe',
            text: 'lenderMenuTooltipTextUnavailable',
          },
        },
      ],
    },
    {
      subMenuLabel: 'menuControls',
      subMenuItems: [
        {
          id: 'cameras',
          label: 'menuCameras',
          path: '/*',
          soon: true,
        },
        {
          id: 'control',
          label: 'menuChecks',
          path: '/dashboard/control',
        },
        {
          id: 'inventory',
          label: 'menuInventory',
          path: '/*',
          inactive: true,
          tooltip: {
            header: 'lenderMenuTooltipHeaderUnavailabe',
            text: 'lenderMenuTooltipTextUnavailable',
          },
        },
      ],
    },
  ],
};

export const BURGER_MENU_ITEMS_LIST = [
  {
    id: 'profile',
    label: 'settings',
    path: '/profile/',
  },
  {
    id: 'units',
    label: 'actives',
    path: '/units/',
  },
  {
    id: 'news',
    label: 'news',
    path: '/news/',
  },
  // {
  //   id: 'promo',
  //   label: 'Предложения',
  //   path: '/promo/',
  // },
  {
    id: 'faq',
    label: 'faq',
    path: '/faq/',
  },
  {
    id: 'logout',
    label: 'exit',
  },
];

export const PERSONAL_MENU_ITEMS_LIST = {
  [USER_ROLES.investor.code]: [
    {
      id: '1',
      text: 'actives',
      path: '/units/',
    },
    {
      id: 'documents',
      text: 'documents',
      path: '/documents/',
    },
    {
      id: '2',
      text: 'settings',
      path: '/profile/',
    },
  ],
  [USER_ROLES.lender.code]: [
    {
      id: 'documents',
      text: 'documents',
      path: '/documents/',
    },
    {
      id: '2',
      text: 'settings',
      path: '/profile/',
    },
  ],
};

export const TABLET_BURGER_MENU_ITEMS_LIST = [
  {
    id: 'faq',
    text: 'faq',
    path: '/faq/',
  },
  {
    id: 'news',
    text: 'news',
    path: '/news/',
  },
];

export const NOT_ALLOWED_PATHS_FOR_LENDERS = [
  '/dashboard/outcome/',
  '/dashboard/outcome-periphery/',
  '/inventory',
];
