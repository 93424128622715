<template>
  <button
    :class="classes"
    @click="onClick"
  >
    <span class="secondary-button__icon secondary-button__icon--before">
      <slot name="iconBefore"></slot>
    </span>
    <span class="secondary-button__text">{{ label }}</span>
    <span class="secondary-button__icon secondary-button__icon--after">
      <slot name="iconAfter"></slot>
    </span>
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'secondary-button',
  props: {
    label: {
      type: String,
      default: '',
    },
    green: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      deafult: false,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    return {
      classes: computed(() => ({
        'secondary-button': true,
        'secondary-button--green': props.green,
        'secondary-button--blue': props.blue,
        'secondary-button--dashed': props.dashed,
        'secondary-button--uppercase': props.uppercase,
        'secondary-button--disabled': props.isDisabled,
      })),
      onClick() {
        emit('click');
      },
    };
  },
};
</script>

<style lang="scss">
.secondary-button {
  display: flex;
  padding: 4px 12px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #DBDCDE00 0%, #C5C6C900 100%);
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07),
    0 0 0 1px rgba(160, 164, 176, 0.12),
    0 3px 2px rgba(83, 89, 109, 0.06);
  line-height: 18px;
  color: $main-black;
  font-size: $font-size-regular;
  font-weight: $font-weight-medium;
  cursor: pointer;
  transition: all 0.3s ease;
  fill: $grey900;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07),
      0 0 0 1px rgba(160, 164, 176, 0.12),
      0 3px 2px rgba(83, 89, 109, 0.1);
    fill: $main-black;
  }

  &__icon {
    display: flex;
    &:empty {
      display: none;
    }

    &--before {
      margin-right: 5px;
    }
    &--after {
      margin-left: 5px;
    }
  }

  &--green {
    color: $green500;
    fill: $green400;
    &:hover {
      fill: $green500;
    }
  }

  &--blue {
    color: $white;
    background-color: $blue500;
    &:hover {
      background-color: $blue600;
    }
  }

  &--dashed {
    background: #fff;
    border: 1px dashed $grey300;
    border-radius: 17px;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  &--uppercase {
    line-height: 9px;
    font-size: $font-size-tiny;
    text-transform: uppercase;
  }

  &--disabled {
    color: $grey400;
    fill: $grey400;
    background-color: $grey100;
    pointer-events: none;
    cursor: default;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .secondary-button {
    padding: 11px 8px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .secondary-button {
    justify-content: flex-start;
    height: 40px;
    line-height: initial;
    padding: 0 8px;

    &__text {
      font-size: $font-size-small;
    }

    &--dashed {
      border-radius: 29px;
    }
  }
}
</style>
