<template>
  <ul class="chart-legend">
    <li
      v-for="item in items"
      :key="item.label"
      class="chart-legend__item"
    >
        <span class="chart-legend__circle"
              :style="{
              'background': `${item.color}`,
              'border-color': `${(item.borderColor ? item.borderColor : item.color)}`,
            }"
        />
      <span class="chart-legend__label">{{ item.label }}</span>
    </li>
  </ul>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'chart-legend',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const classes = computed(() => ({
      'chart-legend--status-positive': props.status > 0,
      'chart-legend--status-negative': props.status < 0,
    }));
    return {
      classes,
    };
  },
};

</script>

<style  scoped lang="scss">
.chart-legend {
  display: flex;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    margin-right: 12px;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__circle {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    border: 1px solid transparent;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__label {
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
  }
}
</style>
