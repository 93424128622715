<template>
  <InfoBlock
    class="stub-video"
    :withLabel="false"
  >
    <template #BlockTitle>
      {{ title }}
    </template>
    <template #BlockText>
      {{ text }}
    </template>
    <template #BlockImage>
      <div class="stub-video__media-box">
        <div class="stub-video__frame-wrapper">
          <iframe
            :src="videoSource"
            class="stub-video__frame"
            title="Youtube video"
          >
          </iframe>
        </div>
      </div>
    </template>
  </InfoBlock>
</template>

<script>
import InfoBlock from '../InfoBlock/InfoBlock.vue';

export default {
  name: 'StubVideo',
  components: {
    InfoBlock,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    videoSource: {
      type: String,
      default: '',
    },
  },
};

</script>

<style lang="scss">
.stub-video {
  flex-direction: row-reverse;
  border: 1px solid $grey200;
  background-color: $blue100;
  margin: 10px 0;

  &__media-box {
    padding: 20px 0 24px 20px;
    width: 100%;
  }

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;

    &-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      border-radius: 8px;
    }
  }

  .info-block {
    &__column {
      width: 35%;
      flex-shrink: 0;
      padding: 20px 40px 0 24px;
    }

    &__title {
      color: $main-black;
      font-size: $font-size-title-small;
      line-height: 24px;
    }

    &__text {
      color: $grey900;
      line-height: 18px;
      font-size: $font-size-regular;
      letter-spacing: 0.1px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .stub-video {
    flex-direction: column-reverse;
    width: calc(100% + 40px);
    margin-left: -20px;

    &__media-box {
      padding: 24px 32px 0 32px;
    }

    .info-block {
      &__column {
        width: 100%;
        padding: 24px 32px;
      }

      &__title {
        line-height: 22px;
        font-size: $font-size-medium;
      }

      &__text {
        line-height: 16px;
        font-size: $font-size-small;
      }
    }
  }
}
</style>
