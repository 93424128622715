import { sendRequest } from '@/helpers/request';
import {
  API_CONTROL_ALL_CHECKS,
} from './api_pathes';

async function getControlAllChecks(locationId = 1) {
  try {
    const resp = await sendRequest(`${API_CONTROL_ALL_CHECKS}`, { locationId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[CONTROL] Something went wrong with request to get all checks', error);
    return {};
  }
}

export default {
  getControlAllChecks,
};
