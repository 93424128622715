import { sendRequest, downloadFile } from '@/helpers/request';
import {
  API_INCOME_MONTHLY_DATA,
  API_INCOME_LENDERS_MONTHLY_DATA,
  API_INCOME_STATISTICS,
  API_INCOME_LENDERS_STATISTICS,
  API_INCOME_BY_UNITS,
  API_INCOME_LENDERS_BY_LOCATIONS,
  API_INCOME_DOWNLOAD_XLS,
  API_INCOME_LENDERS_DOWNLOAD_XLS,
  API_INCOME_CAPITALIZATION,
  API_INCOME_CONVERSION_REQUEST,
} from './api_pathes';

const apiByRole = {
  monthlyData: {
    investor: API_INCOME_MONTHLY_DATA,
    lender: API_INCOME_LENDERS_MONTHLY_DATA,
  },
  statistics: {
    investor: API_INCOME_STATISTICS,
    lender: API_INCOME_LENDERS_STATISTICS,
  },
  income: {
    investor: API_INCOME_BY_UNITS,
    lender: API_INCOME_LENDERS_BY_LOCATIONS,
  },
  download: {
    investor: API_INCOME_DOWNLOAD_XLS,
    lender: API_INCOME_LENDERS_DOWNLOAD_XLS,
  },
};

async function getIncomeStatistics(location, role) {
  try {
    return await sendRequest(apiByRole.statistics[role], { location }, 'GET');
  } catch (error) {
    console.error('[INCOME] Something went wrong with request to get income statistics', error);
    return {};
  }
}

async function getIncomeMonthlyData({ location, yearMonthFrom, yearMonthTo }, role) {
  try {
    return await sendRequest(apiByRole.monthlyData[role], { location, yearMonthFrom, yearMonthTo }, 'GET');
  } catch (error) {
    console.error('[INCOME] Something went wrong with request to get income monthly data', error);
    return {};
  }
}

async function getIncomeMonthlyUnits({ location, yearMonth }, role) {
  try {
    return await sendRequest(apiByRole.income[role], { location, yearMonth }, 'GET');
  } catch (error) {
    console.error('[INCOME] Something went wrong with request to get income monthly actives', error);
    return {};
  }
}

async function getIncomeCapitalizationGraphic({ unitId }) {
  try {
    return await sendRequest(API_INCOME_CAPITALIZATION, { unitId }, 'GET');
  } catch (error) {
    console.error('[INCOME] Something went wrong with request to get income capitalization graphic', error);
    return {};
  }
}

async function getBlobOfIncomeXls(location, role) {
  try {
    return await downloadFile(apiByRole.download[role], { location });
  } catch (error) {
    console.error('[INCOME] Something went wrong with request to download income table', error);
    return {};
  }
}

async function sendConversionRequest() {
  try {
    return await sendRequest(API_INCOME_CONVERSION_REQUEST, 'POST');
  } catch (error) {
    console.error('[INCOME] Something went wrong with request to send conversion request', error);
    return {};
  }
}

export default {
  getIncomeStatistics,
  getIncomeMonthlyData,
  getIncomeMonthlyUnits,
  getBlobOfIncomeXls,
  getIncomeCapitalizationGraphic,
  sendConversionRequest,
};
