import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/main.css';
import i18nConfig from '../i18n.config';

const i18n = createI18n({
  ...i18nConfig,
  locale: store.state.user.currentLocale,
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');
