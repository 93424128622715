<template>
  <h2 class="title-secondary">
    {{ text }}
  </h2>
</template>

<script>
export default {
  name: ' title-secondary',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.title-secondary {
  display: flex;
  margin: 0;
  font-size: $font-size-title-small;
  font-weight: $font-weight-bold;
}

@media screen and (max-width: $mobile-breakpoint) {
  .title-secondary {
    font-size: $font-size-medium;
    line-height: 28px;
  }
}
</style>
