<template>
  <div class="profile-avatar">
    <div class="profile-avatar__pic">
      <avatar-icon
        big
        :user-name="userName"
        :picture-url="avatarUrl"
      />
      <div
        v-if="croppingImageSrc"
        class="profile-avatar__preview"
      />
    </div>
    <div class="profile-avatar__block">
      <h2 class="profile-avatar__user-name">{{ userName }}</h2>
      <div class="profile-avatar__pic-state-mobile">
        <avatar-icon
          big
          :user-name="userName"
          :picture-url="avatarUrl"
        />
        <div
          v-if="croppingImageSrc"
          class="profile-avatar__preview-state-mobile"
        />
      </div>
      <div
        v-if="croppingImageSrc"
        class="profile-avatar__cropper-wrapper"
      >
        <div class="profile-avatar__cropper">
          <vue-cropper
            ref="cropper"
            :aspect-ratio="1"
            :src="croppingImageSrc"
            preview=".profile-avatar__preview"
          />
        </div>
        <primary-button
          green
          full-width
          @click="cropImage"
          label="OK"
          class="profile-avatar__cropper-button"
        />
      </div>
      <div class="profile-avatar__buttons">
        <secondary-button
          class="profile-avatar__button"
          :label="t('uploadPhoto')"
          @click="openCropper"
        >
          <template #iconBefore>
            <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9637 9.96427H11.5346V9.53571C11.5346 9.44998 11.5131 9.36786
    11.4738 9.2964V9.29285C11.388 9.12141 11.2056 9 10.9982 9C10.7908 9 10.6085
    9.12141 10.5226 9.29285V9.2964C10.4833 9.36785 10.4619 9.44998 10.4619
    9.53571V9.96427H10.0363C9.73959 9.96427 9.5 10.2036 9.5 10.5C9.5 10.7964 9.73959
    11.0357 10.0363 11.0357H10.4619V11.4643C10.4619 11.7607 10.7014 12 10.9982 12C11.295
    12 11.5346 11.7607 11.5346 11.4643V11.0357H11.9637C12.2604 11.0357 12.5 10.7964 12.5
    10.5C12.5 10.2036 12.2604 9.96427 11.9637 9.96427Z"
              />
              <path d="M8.86467 11C8.76362 10.8269 8.7053 10.6263 8.7053 10.4099C8.7053 10.1976
              8.76362 9.99301 8.86467 9.81992C9.04735 9.5013 9.37386 9.27318 9.75867 9.23775C9.79363
              8.84833 10.0191 8.51398 10.3339 8.32911C10.5049 8.22684 10.7032 8.16785 10.917
              8.16785C11.1307
    8.16785 11.329 8.22684 11.5 8.32911V3.72597C11.5 3.00614 10.9247 2.42395 10.2173
    2.42395H8.54206C8.4993 2.42395 8.45268 2.39644 8.43711 2.36101L8.12227 1.71591C7.9007
    1.27535 7.45762 1 6.97174 1H5.02829C4.55018 1 4.09931 1.2793 3.88164 1.71985L3.56679
    2.35315C3.54735 2.39644 3.5046 2.42395 3.45794 2.42395H1.78658C1.07526 2.42395 0.5 3.00614
    0.5 3.72597V9.70193C0.5 10.4178 1.07526 11 1.78658 11L8.86467 11ZM6 4.55336C7.07828 4.55336
    7.94993 5.44201 7.94993 6.5267C7.94993 7.61137 7.07828 8.5 6 8.5C4.92819 8.5 4.05007 7.61137
    4.05007 6.5267C4.05008 5.44201 4.92819 4.55336 6 4.55336Z"
              />
            </svg>
          </template>
        </secondary-button>

        <secondary-button
          v-if="avatarUrl"
          class="profile-avatar__button"
          :label="t('deletePhoto')"
          @click="deleteAvatar"
        >
          <template #iconBefore>
            <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2088_5492)">
                <path d="M2 10.5C2 11.3269 2.67272 12 3.49999 12H9.49999C10.3273 12 11 11.3269
                  11 10.5V3H2V10.5Z"
                />
                <path
                  d="M7.99999 0.749994V0H4.99999V0.749994H1.25V2.24998H11.75V0.749994H7.99999Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_2088_5492">
                  <rect width="12" height="12" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </template>
        </secondary-button>
      </div>
      <span class="profile-avatar__postscript">{{ t('formats') }}</span>
    </div>
    <input
      style="display: none;"
      ref="inputImage"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import VueCropper from 'vue-cropperjs';
import AvatarIcon from '@/components/AvatarIcon/AvatarIcon.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import PrimaryButton from '@/ui-kit/PrimaryButton/PrimaryButton.vue';
import 'cropperjs/dist/cropper.css';
import { useI18n } from 'vue-i18n';
import translate from './translate';

export default {
  name: 'profile-avatar',
  components: {
    AvatarIcon,
    VueCropper,
    SecondaryButton,
    PrimaryButton,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
    avatarUrl: {
      type: String,
      default: '',
    },
    contractNumber: {
      type: String,
      default: '',
    },
    contractDate: {
      type: String,
      default: '',
    },
  },
  emits: ['uploadAvatar', 'deleteAvatar'],
  setup(props, { emit }) {
    const { t, mergeLocaleMessage } = useI18n();
    const inputImage = ref(null);
    const cropper = ref(null);
    const croppingImageSrc = ref('');
    mergeLocaleMessage('en', translate.en);
    mergeLocaleMessage('ru', translate.ru);

    const openCropper = () => {
      inputImage.value.click();
    };

    const cropImage = () => {
      // get image data for post processing, e.g. upload or setting image src
      emit('uploadAvatar', cropper.value.getCroppedCanvas().toDataURL());
      croppingImageSrc.value = '';
    };

    const deleteAvatar = () => {
      emit('deleteAvatar');
    };

    const setImage = (e) => {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        console.error('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          croppingImageSrc.value = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        console.error('Sorry, FileReader API not supported');
      }
    };

    return {
      t,
      croppingImageSrc,
      inputImage,
      cropper,
      openCropper,
      setImage,
      cropImage,
      deleteAvatar,
    };
  },
};
</script>

<style scoped lang="scss">
.profile-avatar {
  display: flex;
  color: $main-black;

  &__pic {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 40px;
    border-radius: 50%;
    overflow: hidden;

    &-state-mobile {
      display: none;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__user-name {
    margin: 0;
    line-height: 28px;
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
  }

  &__subtitle {
    margin-top: 4px;
    line-height: 22px;
    color: $grey900;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
  }

  &__button {
    margin-left: 12px;

    &:first-child {
      margin: 0;
    }
  }

  &__postscript {
    margin-top: 8px;
    color: $grey700;
    font-size: $font-size-small;
  }

  &__cropper {
    margin-bottom: 12px;
    width: 400px;

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-state-mobile {
      display: none;
    }
  }

  .avatar-icon {
    cursor: default;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .profile-avatar {
    flex-direction: column;

    &__pic {
      display: none;

      &-state-mobile {
        display: block;
        position: relative;
        width: 102px;
        height: 102px;
        margin: 24px 0 0 0;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &__preview {
      display: none;

      &-state-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__block {
      align-items: center;
    }

    &__user-name {
      font-size: $font-size-title-small;
      line-height: 24px;
    }

    &__subtitle {
      margin: 2px 0 0 0;
      line-height: 20px;
    }

    &__buttons {
      margin-top: 16px;
      align-items: center;
    }

    &__cropper {
      width: 90%;
    }

    &__cropper-wrapper {
      align-items: center;
    }

    &__cropper-button {
      width: 80%;
    }
  }

  .cropper-container {
    width: 100%;
    height: inherit;
  }
}
</style>
