export const INSPECTIONS_TABS_LABELS = [
  {
    id: 'passed',
    label: 'Прошедшие',
  },
  {
    id: 'present',
    label: 'Предстоящие',
  },
];

export const INSPECTIONS_TYPES = {
  '2196f3': {
    label: 'Материальная база и эксплуатация',
    color: 'rgba(232,189,101, .2)',
    barColor: '#E8BD65',
  },
  '32A429': {
    label: 'Контроль сервиса',
    color: 'rgba(0,173,236, .2)',
    barColor: '#00ADEC',
  },
  other: {
    label: 'Другие проверки',
    color: '#C1C5CA',
    barColor: '#7D8289',
  },
};
