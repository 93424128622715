<template>
  <content-layout
    class="units-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #mobile-controls>
      <main-side-menu
        :item-list="menuItems"
      />
    </template>
    <template #main-content>
      <div class="units-page__content">
        <title-main
          class="units-page__header"
          :text="t('unitsPage.assets')"
        />
        <div class="units-page__locations">
          <div
            v-for="(location, index) in unitsByLocations"
            :key="`${location}_${index}`"
            class="units-page__location"
          >
            <title-secondary
              v-if="location.actives && location.actives.length"
              class="units-page__location-title"
              :text="location.locationName"
            />
            <div
              v-if="location.actives && location.actives.length"
              class="units-page__location-units"
            >
              <div
                v-for="(unit, index) in location.actives"
                :key="`${name}_${index}`"
                class="units-page__location-units-item"
                :id="`unit_${index}`"
              >
                <unit-card
                  :unit-type="unit.unitType"
                  :unit-name="unit.part === '1' ? unit.name : `${unit.part}\u202F${unit.name}`"
                  unit-link="/dashboard/income/"
                  :guest-count="unit.capacity"
                  :area-count="unit.area"
                  :invested-sum="unit.invested"
                  :date-of-invest="unit.dateOfInvest"
                  :current-status="unit.stage"
                  :will-be-ready="unit.dateOfLaunch"
                  :img-array="unit.imageUrls && unit.imageUrls.length ? unit.imageUrls.map((image)=>
                    ({ id: index, src: image })) : unit.imageUrls
                  "
                />
              </div>
              <div id="anchor2"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ContentLayout from '@/layouts/ContentLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import UnitCard from '@/components/UnitCard/UnitCard.vue';

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';

export default {
  name: 'UnitsPage',
  components: {
    UnitCard,
    ContentLayout,
    MainSideMenu,
    TitleMain,
    TitleSecondary,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const userState = store.state.user;
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation);
    const userContract = computed(() => userState.profileSettings.contract);
    const unitsByLocations = computed(() => userState.unitsByLocation);
    const menuItems = computed(() => userState.sideMenuItems);

    const initUnitPageData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/getUserUnitsFullInfo');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initUnitPageData();
    };

    initUnitPageData();
    return {
      t,
      userLocations,
      unitsByLocations,
      userContract,
      currentUserLocation,
      locationChangeHandler,
      menuItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.units-page {
  &__content {
    width: 100%;
  }

  &__header {
    padding-bottom: 16px;
    border-bottom: 1px solid $grey200;
  }

  &__location {
    margin-top: 60px;

    &-title {
      padding-bottom: 26px;
    }

    &-units {
      &-item {
        padding: 32px 0;
        border-top: 1px solid $grey200;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .units-page {
    &__location-units {
      &-item {
        border-top: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .units-page {
    &__header {
      display: none;
    }

    &__location {
      margin-top: 0;

      &-title {
        padding-left: 16px;
      }

      &-units {
        &-item {
          border-bottom: 1px solid $grey300;

          &:first-child {
            border-top: 1px solid $grey300;
          }
        }
      }
    }

    &__content {
      width: calc(100% + 48px);
      margin: 0 -24px 0;
    }
  }
}
</style>
