<template>
  <div
    class="media-link"
    @click="toggleModal"
  >
  {{ linkText }}
  </div>
  <modal-window
    :is-shown="isOpenModal"
    is-media
    @close-window="toggleModal"
  >
    <media-gallery
      :slides="slides"
      :buttonColor="buttonColor"
    />
  </modal-window>
</template>

<script>
import { ref } from 'vue';
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import MediaGallery from '@/components/MediaGallery/MediaGallery.vue';

export default {
  name: 'MediaLink',
  components: {
    ModalWindow,
    MediaGallery,
  },
  props: {
    linkText: {
      type: String,
      default: '',
    },
    slides: {
      type: Array,
      default: () => [],
    },
    buttonColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isOpenModal = ref(false);
    const toggleModal = () => {
      isOpenModal.value = !isOpenModal.value;
    };

    return {
      toggleModal,
      isOpenModal,
    };
  },
};

</script>

<style scoped lang="scss">
.media-link {
  display: inline;
  font-size: $font-size-regular;
  font-weight: $font-weight-medium;
  line-height: 18px;
  color: $grey800;
  border-bottom: 1px dashed $grey300;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .media-link {
    font-size: $font-size-small;
  }
}
</style>
