export default {
  en: {
    reportsPage: {
      title: 'Project reports',
      pageInfo: 'Here, you can view reports on facility readiness and management.',
    },
    dashboardPage: {
      requestSend: 'Request submitted',
      tableTitle: 'Statistics',
      invested: 'Your investment',
      earned: 'Total earned',
      roi: 'Average annual rate (ROI per year)',
      monthIncome: 'Average monthly income',
      dataTableTitle: 'Current data',
      income: 'Your income',
      booking: 'Reservations',
    },
    popupData: {
      roiPerYear: {
        header: 'ROI per year',
        text: `The average annual rate or the arithmetic
        average of monthly rates.`,
      },
    },
    incomePage: {
      statistics: 'Statistics',
      averageMonthlyIncome: 'Average monthly income',
      averagePercentForYear: 'Average annual rate (ROI per year)',
      averagePercentForMonth: 'Annual rate by month (ROI for the period)',
      youProvided: 'You have provided a loan of',
      youInvested: 'Your investment',
      totalEarned: 'Total earned',
      assetProfitability: 'Asset profitability',
      loanTerm: 'You have provided a loan for a term of up to',
      yourIncome: 'Your income',
      selectedUnit: 'Selected asset',
      saveAsXLS: 'Save as XLS',
      noIncomeDataTitle: 'No income data yet',
      noIncomeDataSubTitle: 'Income data will be displayed here.',
      incomeByAssets: 'Income by assets',
      noIncomeByAssetsTitle: 'No income data yet',
      noIncomeByAssetsSubTitle: 'Income data will be displayed here.',
      assetCapitalization: 'Asset capitalization',
      popups: {
        adr: {
          header: 'Средняя цена номера',
          text: 'Average daily room rate (ADR) —\n    средняя стоимость номера за сутки.\n    Вычисляется при помощи деления чистой выручки от продаж\n    номерного фонда на общее количество проданных номероночей.',
        },
        revpar: {
          header: 'Доход за номер',
          text: 'RevPar – средний доход за номер.\n    Вычисляется делением выручки от продажи номеров\n    на общее количество доступных номеров.',
        },
        roiPerYear: {
          header: 'ROI per year',
          text: 'The average annual rate or the arithmetic average of monthly rates.',
        },
        roiPerPeriod: {
          header: 'ROI for the period',
          text: 'Annual rate for the previous month.',
        },
        payback: {
          header: '',
          text: 'As soon as the VIEW GA \'Volochayevka\' complex is launched, all indicators will fluctuate based on the revenue and monthly income you receive from the assets. The average payback period for our units is approximately 42 months.',
        },
      },
    },
    bookingPage: {
      roomReservations: 'Room reservations',
      outbuildingsBookings: 'Outbuildings bookings',
      startDate: 'Start date',
      stubTitle: 'There is no booking data yet',
      stubSubTitle: 'As soon as they become available, you will see the chart on this page',
      infoHeaderPopupData: '<p>In the top table, you will find <b>a summary of the entire complex—both</b> for rooms and peripheral facilities (like saunas). The booking pages differ only in the lower section—the booking grid. On the \'Room Bookings\' page, you can see <b>the daily room occupancy</b>, while on the \'Peripheral Bookings\' page, you can see <b>the hourly sauna occupancy</b>.</p>',
    },
    outcomePage: {
      title: 'Location income and expenses',
      description: 'All indicators on this page are streamed directly from the location\'s bank account, so you can see all the transactions that affect your income.',
      stubTitle: 'No data on income and expenses yet',
      income: 'Income',
      outcome: 'Expenses',
    },
    inventoryPage: {
      inventory: 'Inventory',
      space: 'Space',
      stubTitle: 'No data',
      stubSubTitle: 'Information for this unit is not provided',
    },
    faqPage: {
      askQuestion: 'Ask a question',
      questionText: 'If you haven\'t found the answer to your question, you can ask it here. We\'ll reach out within 24 hours by email or phone number specified in your profile.',
      askYourQuestion: 'Write your question here',
      questionSent: 'Question submitted',
      tnhx: 'Thanks for your question',
    },
    profilePage: {
      baseData: 'Basic data',
      bankData: 'Bank details',
      security: 'Security',
    },
    documentsPage: {
      documents: 'Documents',
    },
    timelinePage: {
      title: 'Project timeline',
      info: 'Here, you can track the readiness of both your short-term rent unit and the complex infrastructure.',
      stubTitle: 'There is no data yet',
      stubSubTitle: 'On this page, you will see the stages of the object\'s readiness.',
    },
    newsPage: {
      title: 'News',
      prevPage: 'Previous page',
      nextPage: 'Next page',
      page: 'Page',
      of: 'of',
    },
    unitsPage: {
      assets: 'Assets',
    },
  },
  ru: {
    reportsPage: {
      title: 'Отчеты проекта',
      pageInfo: 'Здесь вы можете увидеть отчеты о готовности объекта и его управлении.',
    },
    dashboardPage: {
      requestSend: 'Заявка отправлена',
      tableTitle: 'Сводная таблица',
      invested: 'Вы инвестировали',
      earned: 'Всего заработано',
      roi: 'Средний процент годовых (ROI по году)',
      monthIncome: 'Среднемесячный доход',
      dataTableTitle: 'Актуальные данные',
      income: 'Ваш доход',
      booking: 'Бронирования',
    },
    popupData: {
      roiPerYear: {
        header: 'ROI по году',
        text: `Средний процент годовых, среднее
          арифметическое процентов за каждый месяц.`,
      },
    },
    incomePage: {
      statistics: 'Сводная таблица',
      averageMonthlyIncome: 'Среднемесячный доход',
      averagePercentForYear: 'Средний процент годовых (ROI по году)',
      averagePercentForMonth: 'Процент годовых по месяцу (ROI за период)',
      youProvided: 'Вы дали заем в размере',
      youInvested: 'Вы инвестировали',
      totalEarned: 'Всего заработано',
      assetProfitability: 'Окупаемость активов',
      loanTerm: 'Вы дали заем на срок до',
      yourIncome: 'Ваш доход',
      selectedUnit: 'Выбранный юнит',
      saveAsXLS: 'Файл XLS',
      noIncomeDataTitle: 'Нет данных за этот период',
      noIncomeDataSubTitle: 'В этот период доходы отсутствуют',
      incomeByAssets: 'Доход по активам',
      noIncomeByAssetsTitle: 'Пока нет данных о доходах',
      noIncomeByAssetsSubTitle: 'В данном месяце отсутствуют доходы.',
      assetCapitalization: 'Капитализация актива',
      popups: {
        adr: {
          header: 'Средняя цена номера',
          text: 'Average daily room rate (ADR) —\n    средняя стоимость номера за сутки.\n    Вычисляется при помощи деления чистой выручки от продаж\n    номерного фонда на общее количество проданных номероночей.',
        },
        revpar: {
          header: 'Доход за номер',
          text: 'RevPar – средний доход за номер.\n    Вычисляется делением выручки от продажи номеров\n    на общее количество доступных номеров.',
        },
        roiPerYear: {
          header: 'ROI по году',
          text: 'Средний процент годовых, среднее\n    арифметическое процентов за каждый месяц.',
        },
        roiPerPeriod: {
          header: 'ROI за период',
          text: 'Процент годовых за предыдущий месяц.',
        },
        payback: {
          header: '',
          text: 'Как только комплекс VIEW GA «Волочаевка» будет запущен, все показатели будут меняться в зависимости от выручки и ежемесячного дохода, который вы получаете от активов. Средняя окупаемость наших юнитов около 42 месяцев.',
        },
      },
    },
    bookingPage: {
      roomReservations: 'Брони номеров',
      outbuildingsBookings: 'Брони периферии',
      startDate: 'Начало просмотра',
      stubTitle: 'Пока нет данных о бронированиях',
      stubSubTitle: 'Как только они появятся, вы увидите график на этой странице.',
      infoHeaderPopupData: '<p>В верхней таблице представлены <b>сводные данные по всему комплексу</b> — и по номерам, и по периферии (баням). Страницы бронирований отличаются только нижней частью — шахматкой бронирований. На странице «Брони номеров» вы видите <b>загрузку номеров по дням</b>, а на странице «Брони периферии» — <b>загрузку бань по часам</b>.</p>',
    },
    outcomePage: {
      title: 'Доходы и расходы по коммерческой части',
      description: 'Все показатели на этой странице транслируются напрямую из банковского аккаунта локации. Так вы можете видеть все операции, которые влияют на ваш доход.',
      stubTitle: 'Пока нет данных о доходах и расходах',
      income: 'Доходы',
      outcome: 'Расходы',
    },
    inventoryPage: {
      inventory: 'Инвентаризация',
      space: 'Помещение',
      stubTitle: 'Нет данных',
      stubSubTitle: 'Для этого юнита информация не предоставлена',
    },
    faqPage: {
      askQuestion: 'Задать вопрос',
      questionText: 'Если вы не нашли ответ на свой вопрос, можете задать его здесь. Мы свяжемся с вами в течение суток по почте или телефону, указанным в профиле.',
      askYourQuestion: 'Напишите ваш вопрос',
      tnhx: 'Спасибо за ваш вопрос',
      questionSent: 'Вопрос отправлен',
    },
    profilePage: {
      baseData: 'Базовые данные',
      bankData: 'Банковские реквизиты',
      security: 'Безопасность',
    },
    documentsPage: {
      documents: 'Документы',
    },
    timelinePage: {
      title: 'Таймлайн проекта',
      info: 'На этой странице вы можете отслеживать готовность вашего юнита к сдаче посуточно и готовность инфраструктуры комплекса к запуску.',
      stubTitle: 'Пока нет данных',
      stubSubTitle: 'На этой странице вы увидите этапы готовности объекта.',
    },
    newsPage: {
      title: 'Новости проекта',
      prevPage: 'Предыдущая страница',
      nextPage: 'Следующая страница',
      page: 'Страница',
      of: 'из',
    },
    unitsPage: {
      assets: 'Активы',
    },
  },
};
