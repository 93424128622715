<template>
  <div class="booking-table-stub">
    <svg width="100%" height="100%" viewBox="0 0 995 329" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1559_30218)">
        <rect x="148.5" y="0.5" width="842" height="58" />
        <rect x="158" y="8" width="42" height="8" rx="4" fill="#EFF2F7"/>
        <rect x="232" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="602" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="417" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="787" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="306" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="676" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="491" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="861" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="935" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="195" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="565" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="380" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="750" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="158" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="528" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="343" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="713" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="269" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="639" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="454" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="824" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="898" y="30" width="36" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="148.5" y="0.5" width="842" height="58" stroke="#EFF2F7"/>
        <rect x="0.5" y="0.5" width="148" height="58" />
        <rect x="9" y="8" width="42" height="8" rx="4" fill="#EFF2F7"/>
        <rect x="9" y="30" width="102" height="24" rx="4" fill="#EFF2F7"/>
        <rect x="0.5" y="0.5" width="148" height="58" stroke="#EFF2F7"/>
        <g clip-path="url(#clip1_1559_30218)">
          <rect width="148" height="32" transform="translate(0 66)" />
          <rect x="8" y="74" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="1" height="32" transform="translate(0 66)" fill="#EFF2F7"/>
          <rect x="110" y="74" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 98)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 99)" />
          <rect width="0.999996" height="32" transform="translate(0 99)" fill="#EFF2F7"/>
          <rect x="8" y="107" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="107" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 131)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 132)" />
          <rect width="0.999996" height="32" transform="translate(0 132)" fill="#EFF2F7"/>
          <rect x="8" y="140" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="140" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 164)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 165)" />
          <rect width="0.999996" height="32" transform="translate(0 165)" fill="#EFF2F7"/>
          <rect x="8" y="173" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="173" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 197)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 198)" />
          <rect width="0.999996" height="32" transform="translate(0 198)" fill="#EFF2F7"/>
          <rect x="8" y="206" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="206" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 230)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 231)" />
          <rect width="0.999996" height="32" transform="translate(0 231)" fill="#EFF2F7"/>
          <rect x="8" y="239" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="239" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 263)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 264)" />
          <rect width="0.999996" height="32" transform="translate(0 264)" fill="#EFF2F7"/>
          <rect x="8" y="272" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="272" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="148" height="1" transform="translate(0 296)" fill="#EFF2F7"/>
          <rect width="148" height="32" transform="translate(0 297)" />
          <rect width="0.999996" height="32" transform="translate(0 297)" fill="#EFF2F7"/>
          <rect x="8" y="305" width="84" height="16" rx="4" fill="#EFF2F7"/>
          <rect x="110" y="305" width="30" height="16" rx="4" fill="#EFF2F7"/>
          <rect width="0.999996" height="263" transform="translate(148 66)" fill="#EFF2F7"/>
          <path d="M231 95L227 95C220.373 95 215 89.6274 215 83V83C215 76.3726 220.373 71 227 71L231
        71L231 95Z" fill="#EFF2F7"/>
          <rect x="232" y="71" width="36" height="24" fill="#EFF2F7"/>
          <rect x="269" y="71" width="36" height="24" fill="#EFF2F7"/>
          <rect x="306" y="71" width="36" height="24" fill="#EFF2F7"/>
          <path d="M343 71H347C353.627 71 359 76.3726 359 83V83C359 89.6274 353.627 95 347
          95H343V71Z" fill="#EFF2F7"/>
          <path d="M675 95L671 95C664.373 95 659 89.6274 659 83V83C659 76.3726 664.373 71 671 71L675
        71L675 95Z" fill="#EFF2F7"/>
          <rect x="676" y="71" width="36" height="24" fill="#EFF2F7"/>
          <rect x="713" y="71" width="36" height="24" fill="#EFF2F7"/>
          <rect x="750" y="71" width="36" height="24" fill="#EFF2F7"/>
          <path d="M787 71H791C797.627 71 803 76.3726 803 83V83C803 89.6274 797.627 95 791
          95H787V71Z" fill="#EFF2F7"/>
          <path d="M194 162L190 162C183.373 162 178 156.627 178 150V150C178 143.373 183.373 138 190
        138L194 138L194 162Z" fill="#EFF2F7"/>
          <rect x="195" y="138" width="36" height="24" fill="#EFF2F7"/>
          <rect x="232" y="138" width="36" height="24" fill="#EFF2F7"/>
          <path d="M269 138H273C279.627 138 285 143.373 285 150V150C285 156.627 279.627 162 273
        162H269V138Z" fill="#EFF2F7"/>
          <path d="M601 162L597 162C590.373 162 585 156.627 585 150V150C585 143.373 590.373 138 597
        138L601 138L601 162Z" fill="#EFF2F7"/>
          <rect x="602" y="138" width="36" height="24" fill="#EFF2F7"/>
          <rect x="639" y="138" width="36" height="24" fill="#EFF2F7"/>
          <rect x="676" y="138" width="36" height="24" fill="#EFF2F7"/>
          <rect x="713" y="138" width="36" height="24" fill="#EFF2F7"/>
          <rect x="750" y="138" width="36" height="24" fill="#EFF2F7"/>
          <rect x="787" y="138" width="36" height="24" fill="#EFF2F7"/>
          <path d="M824 138H828C834.627 138 840 143.373 840 150V150C840 156.627 834.627 162 828
        162H824V138Z" fill="#EFF2F7"/>
          <path d="M305 226L301 226C294.373 226 289 220.627 289 214V214C289 207.373 294.373 202 301
        202L305 202L305 226Z" fill="#EFF2F7"/>
          <rect x="306" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="343" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="380" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="417" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="454" y="202" width="36" height="24" fill="#EFF2F7"/>
          <path d="M491 202H495C501.627 202 507 207.373 507 214V214C507 220.627 501.627 226 495
        226H491V202Z" fill="#EFF2F7"/>
          <path d="M194 194L190 194C183.373 194 178 188.627 178 182V182C178 175.373 183.373 170 190
        170L194 170L194 194Z" fill="#EFF2F7"/>
          <rect x="195" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="232" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="269" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="306" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="343" y="170" width="36" height="24" fill="#EFF2F7"/>
          <path d="M380 170H384C390.627 170 396 175.373 396 182V182C396 188.627 390.627 194 384
        194H380V170Z" fill="#EFF2F7"/>
          <path d="M823 226L819 226C812.373 226 807 220.627 807 214V214C807 207.373 812.373 202 819
        202L823 202L823 226Z" fill="#EFF2F7"/>
          <rect x="824" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="861" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="898" y="202" width="36" height="24" fill="#EFF2F7"/>
          <path d="M935 202H939C945.627 202 951 207.373 951 214V214C951 220.627 945.627 226 939
        226H935V202Z" fill="#EFF2F7"/>
          <path d="M381 127L377 127C370.373 127 365 121.627 365 115V115C365 108.373 370.373 103 377
        103L381 103L381 127Z" fill="#EFF2F7"/>
          <rect x="382" y="103" width="36" height="24" fill="#EFF2F7"/>
          <rect x="419" y="103" width="36" height="24" fill="#EFF2F7"/>
          <rect x="456" y="103" width="36" height="24" fill="#EFF2F7"/>
          <path d="M493 103H497C503.627 103 509 108.373 509 115V115C509 121.627 503.627 127 497
        127H493V103Z" fill="#EFF2F7"/>
          <path d="M342 161L338 161C331.373 161 326 155.627 326 149V149C326 142.373 331.373 137
        338 137L342 137L342 161Z" fill="#EFF2F7"/>
          <rect x="343" y="137" width="36" height="24" fill="#EFF2F7"/>
          <rect x="380" y="137" width="36" height="24" fill="#EFF2F7"/>
          <rect x="417" y="137" width="36" height="24" fill="#EFF2F7"/>
          <path d="M454 137H458C464.627 137 470 142.373 470 149V149C470 155.627 464.627 161 458
        161H454V137Z" fill="#EFF2F7"/>
          <path d="M268 258L264 258C257.373 258 252 252.627 252 246V246C252 239.373 257.373 234 264
        234L268 234L268 258Z" fill="#EFF2F7"/>
          <rect x="269" y="234" width="36" height="24" fill="#EFF2F7"/>
          <rect x="306" y="234" width="36" height="24" fill="#EFF2F7"/>
          <rect x="343" y="234" width="36" height="24" fill="#EFF2F7"/>
          <path d="M380 234H384C390.627 234 396 239.373 396 246V246C396 252.627 390.627 258 384
        258H380V234Z" fill="#EFF2F7"/>
          <path d="M305 319L301 319C294.373 319 289 313.627 289 307V307C289 300.373 294.373 295 301
        295L305 295L305 319Z" fill="#EFF2F7"/>
          <rect x="306" y="295" width="36" height="24" fill="#EFF2F7"/>
          <rect x="343" y="295" width="36" height="24" fill="#EFF2F7"/>
          <rect x="380" y="295" width="36" height="24" fill="#EFF2F7"/>
          <rect x="417" y="295" width="36" height="24" fill="#EFF2F7"/>
          <rect x="454" y="295" width="36" height="24" fill="#EFF2F7"/>
          <path d="M491 295H495C501.627 295 507 300.373 507 307V307C507 313.627 501.627 319 495
        319H491V295Z" fill="#EFF2F7"/>
          <path d="M675 127L671 127C664.373 127 659 121.627 659 115V115C659 108.373 664.373 103 671
        103L675 103L675 127Z" fill="#EFF2F7"/>
          <rect x="676" y="103" width="36" height="24" fill="#EFF2F7"/>
          <rect x="713" y="103" width="36" height="24" fill="#EFF2F7"/>
          <rect x="750" y="103" width="36" height="24" fill="#EFF2F7"/>
          <rect x="787" y="103" width="36" height="24" fill="#EFF2F7"/>
          <rect x="824" y="103" width="36" height="24" fill="#EFF2F7"/>
          <path d="M861 103H865C871.627 103 877 108.373 877 115V115C877 121.627 871.627 127 865
        127H861V103Z" fill="#EFF2F7"/>
          <path d="M527 226L523 226C516.373 226 511 220.627 511 214V214C511 207.373 516.373 202
        523 202L527 202L527 226Z" fill="#EFF2F7"/>
          <rect x="528" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="565" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="602" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="639" y="202" width="36" height="24" fill="#EFF2F7"/>
          <rect x="676" y="202" width="36" height="24" fill="#EFF2F7"/>
          <path d="M713 202H717C723.627 202 729 207.373 729 214V214C729 220.627 723.627
        226 717 226H713V202Z" fill="#EFF2F7"/>
          <path d="M416 194L412 194C405.373 194 400 188.627 400 182V182C400 175.373 405.373 170
        412 170L416 170L416 194Z" fill="#EFF2F7"/>
          <rect x="417" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="454" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="491" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="528" y="170" width="36" height="24" fill="#EFF2F7"/>
          <rect x="565" y="170" width="36" height="24" fill="#EFF2F7"/>
          <path d="M602 170H606C612.627 170 618 175.373 618 182V182C618 188.627
        612.627 194 606 194H602V170Z" fill="#EFF2F7"/>
          <path d="M453 258L449 258C442.373 258 437 252.627 437 246V246C437 239.373
        442.373 234 449 234L453 234L453 258Z" fill="#EFF2F7"/>
          <rect x="454" y="234" width="36" height="24" fill="#EFF2F7"/>
          <rect x="491" y="234" width="36" height="24" fill="#EFF2F7"/>
          <rect x="528" y="234" width="36" height="24" fill="#EFF2F7"/>
          <rect x="565" y="234" width="36" height="24" fill="#EFF2F7"/>
          <rect x="602" y="234" width="36" height="24" fill="#EFF2F7"/>
          <path d="M639 234H643C649.627 234 655 239.373 655 246V246C655 252.627
        649.627 258 643 258H639V234Z" fill="#EFF2F7"/>
          <path d="M342 291L338 291C331.373 291 326 285.627 326 279V279C326 272.373
        331.373 267 338 267L342 267L342 291Z" fill="#EFF2F7"/>
          <rect x="343" y="267" width="36" height="24" fill="#EFF2F7"/>
          <rect x="380" y="267" width="36" height="24" fill="#EFF2F7"/>
          <rect x="417" y="267" width="36" height="24" fill="#EFF2F7"/>
          <path d="M454 267H458C464.627 267 470 272.373 470 279V279C470 285.627
        464.627 291 458 291H454V267Z" fill="#EFF2F7"/>
          <path d="M638 291L634 291C627.373 291 622 285.627 622 279V279C622 272.373
        627.373 267 634 267L638 267L638 291Z" fill="#EFF2F7"/>
          <rect x="639" y="267" width="36" height="24" fill="#EFF2F7"/>
          <rect x="676" y="267" width="36" height="24" fill="#EFF2F7"/>
          <rect x="713" y="267" width="36" height="24" fill="#EFF2F7"/>
          <rect x="750" y="267" width="36" height="24" fill="#EFF2F7"/>
          <rect x="787" y="267" width="36" height="24" fill="#EFF2F7"/>
          <path d="M824 267H828C834.627 267 840 272.373 840 279V279C840 285.627 834.627 291
        828 291H824V267Z" fill="#EFF2F7"/>
        </g>
        <rect x="0.5" y="66.5" width="990" height="262" stroke="#EFF2F7"/>
      </g>
      <defs>
        <clipPath id="clip0_1559_30218">
          <rect width="995" height="329" />
        </clipPath>
        <clipPath id="clip1_1559_30218">
          <rect y="66" width="991" height="263" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BookingTableStub',
};
</script>

<style lang="scss" scoped>
.booking-table-stub {
  background: linear-gradient(110deg, #ffffff1a 8%, #f7f7f745 18%, #ececec57 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@import '/src/styles/keyframes.scss';
</style>
