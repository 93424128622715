<template>
  <div class="info-block">
    <div class="info-block__column">
      <UiLabel
        v-if="withLabel"
        class="info-block__header"
        :label="headerLabel"
      />
      <div class="info-block__title">
        <slot name="BlockTitle"></slot>
      </div>
      <div class="info-block__text">
        <slot name="BlockText"></slot>
      </div>
      <div class="info-block__btn">
        <slot name="BlockButton"></slot>
      </div>
    </div>
    <slot name="BlockImage"></slot>
  </div>
</template>

<script>
import UiLabel from '../../ui-kit/UiLabel/UiLabel.vue';

export default {
  name: 'InfoBlock',
  components: {
    UiLabel,
  },
  props: {
    headerLabel: {
      type: String,
      default: '',
    },
    headerDate: {
      type: String,
      default: '',
    },
    withLabel: {
      type: Boolean,
      deafult: true,
    },
  },
};
</script>

<style lang="scss">
.info-block {
  background-color: $green100;
  border: 1px solid $grey200;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;

  &__header {
    align-self: flex-start;
    background-color: $green700;
    color: $white;
  }

  &__column {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    flex-shrink: 0;
  }

  &__title {
    padding: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-title;
    line-height: 28px;
    color: $green700;
  }

  &__text {
    margin: 12px 0 0 0;
    padding: 0;
    font-weight: $font-weight-medium;
    font-size: $font-size-medium;
    line-height: 22px;
    color: $grey900;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .info-block {
    &__column {
      max-width: 100%;
    }

    &__title {
      font-size: $font-size-title-small;
    }

    &__text {
      font-size: $font-size-regular;
    }
  }
}
</style>
