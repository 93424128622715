export const OCCUPATION_INFO_STATUS_LIST = [
  {
    id: 'checkedOut',
    label: 'Выехали',
    color: '#f8d181',
    amount: 0,
  },
  {
    id: 'living',
    label: 'Проживают',
    color: '#5063c3',
    amount: 0,
  },
  {
    id: 'willCheckIn',
    label: 'Заедут',
    color: '#6FCCEE',
    amount: 0,
  },
  {
    id: 'notCheckedIn',
    label: 'Не заехали',
    color: '#f48575',
    amount: 0,
  },
];

export const CLEANING_UNITS_STATUS = [
  {
    id: 'dirty',
    label: 'Грязно',
    color: '#2d5360',
  },
  {
    id: 'cleaned',
    label: 'Убрано',
    color: '#5992a5',
  },
  {
    id: 'checked',
    label: 'Проверено',
    color: '#5dc397',
  },
  // {
  //   id: 'repair',
  //   label: 'Ремонт',
  //   color: '#5fb6d6',
  // },
];

export const POPUP_DATA = {
  adr: {
    header: 'Средняя цена номера',
    text: `Average daily room rate (ADR) —
    средняя стоимость номера за сутки.
    Вычисляется при помощи деления чистой выручки от продаж
    номерного фонда на общее количество проданных номероночей.`,
  },
  revpar: {
    header: 'Доход за номер',
    text: `RevPar – средний доход за номер.
    Вычисляется делением выручки от продажи номеров
    на общее количество доступных номеров.`,
  },
  roiPerYear: {
    header: 'ROI по году',
    text: `Средний процент годовых, среднее
    арифметическое процентов за каждый месяц.`,
  },
  roiPerPeriod: {
    header: 'ROI за период',
    text: 'Процент годовых за предыдущий месяц.',
  },
  payback: {
    header: '',
    text: 'Как только комплекс VIEW GA «Волочаевка» будет запущен, все показатели будут меняться в зависимости от выручки и ежемесячного дохода, который вы получаете от активов. Средняя окупаемость наших юнитов около 42 месяцев.',
  },
};

export const BOOKING_SOURCES = [
  {
    id: 0,
    label: 'bookingSources.reception',
  },
  {
    id: 1,
    label: 'bookingSources.call',
  },
  {
    id: 2,
    label: 'bookingSources.sales',
  },
  {
    id: 3,
    label: 'bookingSources.web',
  },
  {
    id: 4,
    label: 'bookingSources.fax',
  },
  {
    id: 5,
    label: 'bookingSources.email',
  },
  {
    id: 6,
    label: 'bookingSources.vk',
  },
  {
    id: 7,
    label: 'bookingSources.mobile',
  },
  {
    id: 8,
    label: 'bookingSources.fb',
  },
  {
    id: 9,
    label: 'bookingSources.ta',
  },
  {
    id: 2147483644,
    label: 'bookingSources.extranet',
  },
  {
    id: 2147483645,
    label: 'bookingSources.ya',
  },
  {
    id: 2147483646,
    label: 'bookingSources.mobileExtranet',
  },
];
