<template>
  <div class="media-gallery">
    <video
      v-if="currentSlidesIndex !== null && slides[currentSlidesIndex].isVideo"
      class="media-gallery__media media-gallery__media--video"
      controls
      autoplay
    >
      <source :src="slides[currentSlidesIndex].url">
    </video>
    <img
      v-else-if="currentSlidesIndex !== null"
      :src="slides[currentSlidesIndex].url"
      :alt="slides[currentSlidesIndex].name || slides[currentSlidesIndex].id"
      class="media-gallery__media"
    />
    <button
      v-if="slides && slides.length > 1"
      class="media-gallery__button media-gallery__button--prev"
      @click="changeSlide('prev')"
    >
      <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656 1.25832C-0.0752187
        0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409 -0.0719468 1.01413 -0.0719468 1.31503
        0.21584L5.49998 4.22061L9.68496 0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742
        0.21598C11.0753 0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
        5.69705 6 5.49998 6Z"/>
      </svg>
    </button>
    <button
      v-if="slides && slides.length > 1"
      class="media-gallery__button media-gallery__button--next"
      @click="changeSlide('next')"
    >
      <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656 1.25832C-0.0752187
        0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409 -0.0719468 1.01413 -0.0719468 1.31503
        0.21584L5.49998 4.22061L9.68496 0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742
        0.21598C11.0753 0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92814
        5.69705 6 5.49998 6Z"/>
      </svg>
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'MediaGallery',
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const currentSlidesIndex = ref(props.slides[0] ? 0 : null);

    const changeSlide = (direction) => {
      if (direction === 'prev') {
        currentSlidesIndex.value = currentSlidesIndex.value > 0
          ? currentSlidesIndex.value - 1
          : props.slides.length - 1;
        return;
      }

      if (direction === 'next') {
        currentSlidesIndex.value = currentSlidesIndex.value < props.slides.length - 1
          ? currentSlidesIndex.value + 1
          : 0;
      }
    };

    return {
      currentSlidesIndex,
      changeSlide,
    };
  },
};
</script>

<style scoped lang="scss">
.media-gallery {
  position: relative;
  max-height: 90vh;
  max-width: 95vw;
  line-height: 0;
  border: 1px solid $grey300;
  border-radius: 12px;
  overflow: hidden;

  &__media {
    height: 100%;
    width: 100%;
  }

  &__button {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &--next {
      right: 20px;
      transform: translateY(-50%) rotate(-90deg);
    }

    &--prev {
      left: 20px;
      transform: translateY(-50%) rotate(90deg);
    }

    svg {
      width: 22px;
      height: 12px;
      fill: $blue500;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .media-gallery {
  }
}
</style>
