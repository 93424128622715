export default {
  en: {
    blockTitle: `Convert your loan agreement from 500 thousand to 
        a share in the project\u00A0— become an investor in the VIEW GA hotels!`,
    blockText: [
      'Guaranteed payments up to 24% annually',
      'Asset capitalization up to 40% per year',
      '30% discount on accommodation at any VIEW GA hotel',
    ],
    buttonText: 'Submit request',
  },
  ru: {
    blockTitle: `Конвертируйте свой договор займа от 500 тысяч в
      долю в проекте\u00A0— станьте инвестором сети отелей VIEW GA!`,
    blockText: [
      'Гарантированные выплаты до 24% годовых.',
      'Капитализация актива до 40% за год.',
      'Скидка 30% на проживание в любом отеле сети VIEW GA.',
    ],
    buttonText: 'Оставить заявку',
  },
};
