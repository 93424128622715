import { DOMAIN } from '@/models/constants';

export const API_INCOME_STATISTICS = `${DOMAIN}api/v1/investor-income/statistics`;
export const API_INCOME_LENDERS_STATISTICS = `${DOMAIN}api/v1/lender-income/statistics`;
export const API_INCOME_MONTHLY_DATA = `${DOMAIN}api/v1/investor-income`;
export const API_INCOME_LENDERS_MONTHLY_DATA = `${DOMAIN}api/v1/lender-income`;
export const API_INCOME_BY_UNITS = `${DOMAIN}api/v1/investor-income/actives`;
export const API_INCOME_LENDERS_BY_LOCATIONS = `${DOMAIN}api/v1/lender-income/actives`;
export const API_INCOME_DOWNLOAD_XLS = `${DOMAIN}api/v1/investor-income/xls`;
export const API_INCOME_LENDERS_DOWNLOAD_XLS = `${DOMAIN}api/v1/lender-income/xls`;
export const API_INCOME_CAPITALIZATION = `${DOMAIN}api/v1/investor-income/unit-capitalization-graphic`;
export const API_INCOME_CONVERSION_REQUEST = `${DOMAIN}api/v1/lender-income/send-conversion-request`;
