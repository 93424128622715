<template>
  <button
    :class="classes"
    @click="onClick"
  >
    {{ label }}
    <slot name="content"></slot>
  </button>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'primary-button',
  props: {
    label: {
      type: String,
      default: '',
    },
    green: {
      type: Boolean,
      default: false,
    },
    greenLight: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    return {
      classes: computed(() => ({
        'primary-button': true,
        'primary-button--green': props.green,
        'primary-button--green-light': props.greenLight,
        'primary-button--full-width': props.fullWidth,
        'primary-button--disabled': props.isDisabled,
      })),
      onClick() {
        emit('click');
      },
    };
  },
};
</script>

<style lang="scss">
.primary-button {
  display: flex;
  width: 327px;
  height: 42px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey300;
  border-radius: 4px;
  background-color: $white;
  line-height: 22px;
  color: $main-black;
  font-size: $font-size-medium;
  font-weight: $font-weight-regular;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: $blue500;
    border-color: $blue400;
  }

  &--full-width {
    width: 100%;
  }

  &--green {
    background-color: $blue500;
    border-color: $blue500;
    color: $white;

    &:hover {
      background-color: $blue600;
      border-color: $blue600;
      color: $white;
    }
  }

  &--green-light {
    background-color: $blue400;
    border-color: $blue400;
    color: $white;

    &:hover {
      background-color: $blue500;
      border-color: $blue500;
      color: $white;
    }
  }

  &--disabled {
    color: $grey800;
    background-color: $grey300;
    border-color: $grey300;
    pointer-events: none;
    cursor: default;
  }
}
</style>
