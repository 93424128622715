<template>
  <div class="occupation-info">
    <info-header
      :text="header"
    />
    <div class="occupation-info__legend">
      <legend-list
        :items-list="statusList"
        :max-amount="maxRoomsAmount"
      />
    </div>
  </div>
</template>

<script>
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import LegendList from '@/components/LegendList/LegendList.vue';

export default {
  name: 'OccupationInfo',
  components: {
    InfoHeader,
    LegendList,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    maxRoomsAmount: {
      type: Number,
      default: null,
    },
    statusList: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style scoped lang="scss">
.occupation-info {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}

@media screen and (max-width: $mobile-breakpoint) {
  .occupation-info {
    gap: 16px 0;
  }
}
</style>
