export const OUTCOME_PAGE_TAB_TITLES = [
  {
    id: 'income',
    label: 'Доходы',
  },
  {
    id: 'outcome',
    label: 'Расходы',
  },
];

export const OUTCOME_PAGE_POPUPS_DATA = {
  opex: {
    text: 'OPEX (Oрerating ехpense) — cумма операционных расходов по всей локации.',
  },
  gop: {
    text: `GOP (Gross Operating Profit)
    — выручка по всей локации после вычета расходов.`,
  },
};
