export default {
  en: {
    title: 'Capitalization and construction timeline',
    capitalizationStage: 'Sages of capitalization',
    date: 'Date',
    constructionStage: 'Construction stage',
    marketValue: 'Market value',
    capitalization: 'Capitalization',
  },
  ru: {
    title: 'Таймлайн капитализации и строительства',
    capitalizationStage: 'Этапы капитализации',
    date: 'Дата',
    constructionStage: 'Этапы строительства',
    marketValue: 'Рыночная стоимость',
    capitalization: 'Капитализация',
  },
};
