export default {
  namespaced: true,
  state: () => ({
    currentPath: '',
  }),
  mutations: {
    'set:current:path': function (state, path) {
      state.currentPath = path;
    },
  },
  actions: {
    async setCurrentPath({ commit }, route) {
      if (route && route.path && route.path.length) {
        commit('set:current:path', route.path);
      }
    },
  },
};
