import newsModel from '@/models/news';
import { prepareShortMonthDate } from '@/helpers/datesAndTime';

export default {
  namespaced: true,
  state: () => ({
    pageId: 1,
    mainImageUrls: {
      desktop: '',
      tablet: '',
      mobile: '',
    },
    allNews: [],
  }),
  mutations: {
    'set:news:main-images': function (state, data) {
      state.mainImageUrls = {
        desktop: data.desktop,
        tablet: data.tablet,
        mobile: data.mobile,
      };
    },
    'set:news:all-news': function (state, data) {
      state.allNews = data;
    },
  },
  actions: {
    async getAllNewsData({ dispatch }) {
      dispatch('getPageImages');
      dispatch('getAllNews');
    },
    async getPageImages({ commit, state }) {
      const resp = await newsModel.getNewsMainData();
      if (resp.length) {
        const preparedTitleImageData = resp.reduce((acc, item) => {
          if (item.id === state.pageId) {
            const preparedObject = {
              desktop: item.titleImageUrl,
              tablet: item.titleImageTabletUrl,
              mobile: item.titleImageMobileUrl,
            };

            return preparedObject;
          }
          return {};
        }, {});

        commit('set:news:main-images', preparedTitleImageData);
      } else {
        commit('set:news:main-images', {});
      }
    },
    async getAllNews({ commit, state }) {
      const resp = await newsModel.getAllNews(state.pageId);
      if (resp.length) {
        const preparedArray = resp.map((newsItem) => ({
          ...newsItem,
          uiLabel: newsItem.tagName,
          rawDate: newsItem.date ? new Date(newsItem.date).getTime() : null,
          date: prepareShortMonthDate(newsItem.date, { notShort: true }),
          imageUrl: newsItem.newsImageUrl,
        }));
        const sortedArray = preparedArray.sort((a, b) => b.rawDate - a.rawDate);
        commit('set:news:all-news', sortedArray);
        return preparedArray;
      }
      return null;
    },
  },
};
