<template>
  <h1 class="title-main">
    <span
      v-if="icon"
      class="title-main__icon"
    >
      <slot name="icon">
      </slot>
    </span>
    <span
      class="title-main__text"
      v-if="!withTooltip"
    >
    {{ text }}
    </span>
    <info-header
      v-if="withTooltip"
      underlineDisabled
      class="title-main__text"
      :text="text"
      :popupData="titlePopupData"
    />
  </h1>
</template>

<script>
import InfoHeader from '../InfoHeader/InfoHeader.vue';

export default {
  name: 'title-main',
  components: {
    InfoHeader,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    withTooltip: {
      type: Boolean,
      deafult: false,
    },
    titlePopupData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.title-main {
  display: flex;
  align-items: center;
  height: 28px;
  margin: 0;
  font-size: $font-size-title;
  font-weight: $font-weight-bold;

  .info-header {
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
    height: 28px;
  }

  &__icon {
    height: 24px;
    margin-right: 12px;

    img {
      height: 100%;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .title-main {
    font-size: $font-size-title;

    .info-header {
      font-size: $font-size-title;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .title-main {
    font-size: $font-size-title-small;

    .info-header {
      font-size: $font-size-title-small;
    }
  }
}
</style>
