import { sendRequest } from '@/helpers/request';
import {
  API_LOCATION_TIMELINE,
  API_TIMELINE_BY_UNITS,
  API_TIMELINE_MEDIA_REPORTS_BY_LOCATION,
  API_TIMELINE_MEDIA_REPORTS_BY_UNIT,
} from './api_pathes';

async function getTimelinesByUnits(unitId = 1) {
  try {
    const resp = await sendRequest(`${API_TIMELINE_BY_UNITS}`, { userUnitId: unitId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[TIMELINE] Something went wrong with request to get timelines by units', error);
    return {};
  }
}
async function getLocationTimeline(locationId = 1) {
  try {
    const resp = await sendRequest(`${API_LOCATION_TIMELINE}`, { locationId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[TIMELINE] Something went wrong with request to get timelines by units', error);
    return {};
  }
}
async function getMediaReportsByUnit(unitId) {
  try {
    const resp = await sendRequest(API_TIMELINE_MEDIA_REPORTS_BY_UNIT, { unitId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[TIMELINE] Something went wrong with request to get media reports by unit', error);
    return {};
  }
}
async function getMediaReportsByLocation(locationId) {
  try {
    const resp = await sendRequest(API_TIMELINE_MEDIA_REPORTS_BY_LOCATION, { locationId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[TIMELINE] Something went wrong with request to get media reports by location', error);
    return {};
  }
}

export default {
  getTimelinesByUnits,
  getMediaReportsByUnit,
  getLocationTimeline,
  getMediaReportsByLocation,
};
