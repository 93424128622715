import outcomeModel from '@/models/outcome';
import { openDownloadFilePage } from '@/helpers/common';
import { checkMonthsOrder } from '@/helpers/datesAndTime';

export default {
  namespaced: true,
  state: () => ({
    allData: {
      gop: null,
      gopPercent: null,
      incomeTotal: null,
      incomePercent: null,
      outcomeTotal: null,
      outcomePercent: null,
    },
    incomesList: [],
    outcomesList: [],
    defaultDate: {
      year: new Date().getFullYear(),
      month: checkMonthsOrder(new Date().getMonth()),
    },
    selectedDate: {
      year: null,
      month: '',
    },
  }),
  mutations: {
    'set:outcome:all-data': function (state, data) {
      state.allData.gop = data.gop;
      state.allData.gopPercent = data.gopPercent;
      state.allData.incomeTotal = data.incomeTotal;
      state.allData.incomePercent = data.incomePercent;
      state.allData.outcomeTotal = data.outcomeTotal;
      state.allData.outcomePercent = data.outcomePercent;
      state.incomesList = data.incomes;
      state.outcomesList = data.outcomes;
    },
    'set:outcome:selected-date': function (state, { year, month }) {
      state.selectedDate.year = year;
      state.selectedDate.month = checkMonthsOrder(month);
    },
  },
  actions: {
    async setDefaultData({ commit, rootState, state }) {
      const year = state.defaultDate.year;
      const month = state.defaultDate.month;
      const resp = await
      outcomeModel.getOutcomeAndIncomeData({
        locationId: rootState.user.currentLocation.id,
        year,
        month,
      });
      commit('set:outcome:all-data', resp);
    },
    async setDefaultPeripheryData({ commit, rootState, state }) {
      const year = state.defaultDate.year;
      const month = state.defaultDate.month;
      const resp = await
      outcomeModel.getOutcomeAndIncomePeripheryData({
        locationId: rootState.user.currentLocation.id,
        year,
        month,
      });
      commit('set:outcome:all-data', resp);
    },
    async setSelectedData({ commit, rootState, state }, { year, month }) {
      commit('set:outcome:selected-date', { year, month });
      const selectedYear = state.selectedDate.year;
      const selectedMonth = state.selectedDate.month;
      const resp = await
      outcomeModel.getOutcomeAndIncomeData({
        locationId: rootState.user.currentLocation.id,
        year: selectedYear,
        month: selectedMonth,
      });
      commit('set:outcome:all-data', resp);
    },
    async setSelectedPeripheryData({ commit, rootState, state }, { year, month }) {
      commit('set:outcome:selected-date', { year, month });
      const selectedYear = state.selectedDate.year;
      const selectedMonth = state.selectedDate.month;
      const resp = await
      outcomeModel.getOutcomeAndIncomePeripheryData({
        locationId: rootState.user.currentLocation.id,
        year: selectedYear,
        month: selectedMonth,
      });
      commit('set:outcome:all-data', resp);
    },
    async downloadOutcomeTable({ state }) {
      const { blob, fileName } = await outcomeModel.getBlobOfOutcomeXls(state.selectedMonth);

      if (blob) {
        openDownloadFilePage(blob, fileName);
      }
    },
  },
};
