<template>
  <div
    class="info-header__wrapper"
    v-if="!popupData || (!popupData.text && !popupData.header)"
  >
    <h2 class="info-header">
      <span class="info-header__text">{{text}}</span>
    </h2>
  </div>
  <div
    v-else
    class="info-header__wrapper"
  >
    <PopupInfo
      hover
      :popupHeader="popupData.header"
      :popupText="popupData.text"
    >
      <h2
        class="info-header"
        :class="headerClasses"
      >
        <span
          class="info-header__text"
          :class="textClasses"
        >
          {{text}}
        </span>
      </h2>
    </PopupInfo>
  </div>
</template>

<script>
import { computed } from 'vue';
import PopupInfo from '../../components/PopupInfo/PopupInfo.vue';

export default {
  name: 'info-header',
  components: {
    PopupInfo,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    popupData: {
      type: Object,
      default: () => {},
    },
    additionalClassActive: {
      type: Boolean,
      default: false,
    },
    underlineDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const headerClasses = computed(() => ({
      'info-header__additional-class': props.additionalClassActive,
      'info-header--state-active':
      (props.popupData.header || props.popupData.text) && !props.underlineDisabled,
    }));

    const textClasses = computed(() => ({
      'info-header__text--state-active':
      (props.popupData.header || props.popupData.text) && !props.underlineDisabled,
    }));

    return {
      headerClasses,
      textClasses,
    };
  },
};
</script>

<style lang="scss">
.info-header {
  display: inline;
  font-size: $font-size-regular;
  font-weight: $font-weight-medium;
  line-height: 18px;
  margin: 0;
  transition: 0.3s;

  &__wrapper {
    color: $grey900;
  }

  &__additional-class {
    line-height: 24px;
    font-size: $font-size-title-small;
    font-weight: $font-weight-bold;
  }

  &--state-active {

    &:hover{
      color: black;
      cursor: pointer;
    }
  }

  &__text {
    box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px dotted transparent;

    &--state-active {
      border-bottom: 2px dotted $grey300;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .info-header {
    font-size: $font-size-regular;

    &__text {
      padding-bottom: 1px;
    }
  }
}
</style>
