<template>
  <content-layout
    class="financial-report-page"
  >
    <template #main-content>
      <div class="financial-report-page__main-block">
        <title-main
          class="financial-report-page__title"
          text="Промежуточный финансовый отчет о работе группы компаний dewis"
        />
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page1.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page2.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page3.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page4.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page5.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page6.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page7.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page8.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page9.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page10.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page11.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page12.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page13.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page14.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
        <div class="financial-report-page__img-wrapper">
          <img
            src="../assets/financialReportPageImgs/report-page15.png"
            alt=""
            class="financial-report-page__img"
          />
          <div class="financial-report-page__img-overflow"></div>
        </div>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import ContentLayout from '@/layouts/ContentLayout.vue';

import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'FinancialReport',
  components: {
    TitleMain,
    ContentLayout,
  },
  setup() {
    const store = useStore();
    const userState = store.state.user;
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const currentUserLocation = computed(() => userState.currentLocation.id);
    window.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    const initFinancialReportPageData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/getUserUnitsFullInfo');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      await store.dispatch('user/getFaq');
    };

    initFinancialReportPageData();

    return {
      userLocations,
      currentUserLocation,
    };
  },
};
</script>

  <style scoped lang="scss">
  .financial-report-page {
    &__main-block {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: $font-size-title-large;
      font-weight: $font-weight-bold;
      line-height: 40px;
      color: $main-black;
      height: 100%;
    }

    &__img {
      &-wrapper {
        width: 100%;
        position: relative;
      }

      &-overflow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      pointer-events: none;
      width: 100%;
    }

  }

  @media screen and (max-width: $tablet-breakpoint) {
    .financial-report-page {
      &__title {
        line-height: 32px;
        font-size: $font-size-title-mid-large;
      }
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    .financial-report-page {
      &__title {
        line-height: 24px;
      }
    }
  }
  </style>
