<template>
  <div
    class="info-chart-donut"
  >
    <InfoHeader
      :text="header"
    />
    <div class="info-chart-donut__wrapper">
      <div
        class="info-chart-donut__chart"
        :class="{
          'info-chart-donut__chart--stub': !isShowChart,
        }"
      >
        <apexchart
          v-if="isShowChart"
          type="donut"
          :series="chartData"
          :options="chartOptions"
        />
      </div>
      <div
        class="info-chart-donut__legend"
      >
        <legend-list
          :items-list="legend"
          :max-amount="legendMaxAmount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import LegendList from '@/components/LegendList/LegendList.vue';
import VueApexcharts from 'vue3-apexcharts';

export default {
  name: 'InfoChartDonut',
  components: {
    LegendList,
    InfoHeader,
    apexchart: VueApexcharts,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    legend: {
      type: Array,
      default: () => ([]),
    },
    legendMaxAmount: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const chartOptions = computed(() => {
      if (!props.legend.length) {
        return [];
      }

      return {
        legend: {
          show: false,
        },
        labels: props.legend.map((item) => item.label),
        colors: props.legend.map((item) => item.color),
        chart: {
          width: '100%',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '38%',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
      };
    });
    const chartData = computed(() => props.legend.map((item) => item.amount));
    const isShowChart = computed(() => props.legend[0].amount !== undefined);

    return {
      chartOptions,
      chartData,
      isShowChart,
    };
  },
};
</script>

<style lang="scss">
.info-chart-donut {
  &__wrapper {
    display: flex;
    margin-top: 28px;
    overflow: visible;
  }
  &__chart {
    width: 160px;
    flex-shrink: 0;
    margin-left: -35px;
    margin-top: -8px;
    margin-bottom: -22px;

    &--stub {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      margin: 0 16px 0 0;
      border-radius: 50%;
      background-color: $grey200;

      &:before {
        content: '';
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
  &__legend {
    flex-grow: 1;
  }
}

path.apexcharts-pie-area {
  &:hover {
    opacity: 0.6;
    filter: none;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .info-chart-donut {
    &__wrapper {
      margin-top: 10px;
    }
  }
}
</style>
