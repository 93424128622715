import { sendRequest } from '@/helpers/request';
import {
  API_ID_LOGIC_ITEMS,
  API_ID_LOGIC_ITEM_IMAGES,
} from './api_pathes';

async function getInventoryItems(payload) {
  try {
    return await sendRequest(API_ID_LOGIC_ITEMS, payload, 'GET');
  } catch (error) {
    console.error('[ID LOGIC] Something went wrong with request to get id-logic items', error);
    return {};
  }
}

async function getInventoryItemImage(payload) {
  try {
    return await sendRequest(API_ID_LOGIC_ITEM_IMAGES, payload, 'GET');
  } catch (error) {
    console.error('[ID LOGIC] Something went wrong with request to get id-logic item images', error);
    return {};
  }
}
export default {
  getInventoryItemImage,
  getInventoryItems,
};
