<template>
  <content-layout
    class="news-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #mobile-controls>
      <main-side-menu
        :item-list="menuItems"
      />
    </template>
    <template #main-content>
      <div class="news-page__content">
        <div
          v-if="titleImageUrls"
          class="news-page__header-image-wrapper"
        >
          <img
            :src="titleImageUrls.desktop"
            class="news-page__header-image-first"
            alt="">
          <!-- <img
            src="@/assets/updateImage130423.png"
            class="news-page__header-image-second"
            alt="next update"> -->
          <img
            :src="titleImageUrls.tablet"
            class="news-page__header-tablet-image-first"
            alt="">
          <!-- <img
            src="@/assets/updateImageTablet130423.png"
            class="news-page__header-tablet-image-second"
            alt="next update"> -->
          <img
            :src="titleImageUrls.mobile"
            class="news-page__header-mobile-image-first"
            alt="">
          <!-- <img
            src="@/assets/updateImageMobile130423.png"
            class="news-page__header-mobile-image-second"
            alt="next update"> -->
         </div>
        <div class="news-page__header-wrapper">
          <title-main
            class="news-page__header"
            :text="t('newsPage.title')"
          />
        </div>
        <div class="news-page__inner-content">
          <div
            v-if="paginationData && paginationData.length"
            class="news-page__cards-wrapper"
          >
            <news-card
              v-for="item in paginationData"
              :key="item.id"
              class="news-page__card"
              :headerLabel="item.uiLabel"
              :headerDate="item.date"
            >
              <template #cardTitle>
                {{ item.title }}
              </template>
              <template #cardContent>
                <div
                  v-html="item.content"
                >
                </div>
              </template>
              <template #cardImage>
                <img
                class="news-page__card-image"
                :src="item.imageUrl"
                alt="image"
              >
              </template>
            </news-card>
          </div>
          <div class="news-page__controls">
            <div class="news-page__page-info">
              {{ t('newsPage.page') }} {{ currentPage }}
              <span class="news-page__page-info--greyscale">{{ t('of') }} {{ maxPages }}</span>
            </div>
            <div class="news-page__button-wrapper">
              <secondary-button
                :label="t('newsPage.prevPage')"
                :isDisabled="previousButtonDisabled"
                @click="previousPage"
              >
                <template #iconBefore>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="inherit"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 6.49998C3 6.30284 3.07203 6.10572 3.21578 5.95542L7.74168
                      1.22566C8.02959 0.924781 8.49637 0.924781 8.78416 1.22566C9.07195
                      1.52641 9.07195 2.01413 8.78416 2.31503L4.77939 6.49998L8.78402
                      10.685C9.07181 10.9858 9.07181 11.4735 8.78402 11.7742C8.49623 12.0753
                      8.02945 12.0753 7.74154 11.7742L3.21564 7.04455C3.07187 6.89417 3
                      6.69705 3 6.49998Z"
                      fill="inherit"
                    />
                  </svg>
                </template>
              </secondary-button>
              <secondary-button
                :label="t('newsPage.nextPage')"
                :isDisabled="nextButtonDisabled"
                @click="nextPage"
              >
                <template #iconAfter>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="inherit"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 6.00002C9.5 6.19716 9.42797 6.39428 9.28422 6.54458L4.75832
                      11.2743C4.47041 11.5752 4.00363 11.5752 3.71584 11.2743C3.42805
                      10.9736 3.42805 10.4859 3.71584 10.185L7.72061 6.00002L3.71598
                      1.81504C3.42819 1.51416 3.42819 1.02649 3.71598 0.725766C4.00377
                      0.424746 4.47055 0.424746 4.75846 0.725766L9.28436 5.45545C9.42813
                      5.60583 9.5 5.80295 9.5 6.00002Z"
                      fill="inherit"
                    />
                  </svg>
                </template>
              </secondary-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useWindowSize } from '@vueuse/core';
import ContentLayout from '@/layouts/ContentLayout.vue';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import NewsCard from '@/components/NewsCard/NewsCard.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';

export default {
  name: 'NewsPage',
  components: {
    ContentLayout,
    TitleMain,
    NewsCard,
    MainSideMenu,
    SecondaryButton,
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const userState = store.state.user;
    const newsState = store.state.news;
    const { width } = useWindowSize();
    const currentUserLocation = computed(() => userState.currentLocation);
    const menuItems = computed(() => userState.sideMenuItems);
    const titleImageUrls = computed(() => newsState.mainImageUrls);
    const newsArray = computed(() => newsState.allNews);
    const currentPage = ref(1);

    const perPage = computed(() => {
      if (width.value <= 480) {
        return 3;
      } if (width.value > 480 && width.value <= 1080) {
        return 2;
      }
      return 4;
    });

    const maxPages = computed(() => Math.ceil(newsArray.value.length / perPage.value));

    const previousButtonDisabled = computed(() => {
      if (currentPage.value === 1) {
        return true;
      }
      return false;
    });

    const nextButtonDisabled = computed(() => {
      if (currentPage.value === maxPages.value) {
        return true;
      }
      return false;
    });

    const paginationData = computed(() => newsArray.value.slice(
      (currentPage.value - 1) * perPage.value,
      currentPage.value * perPage.value,
    ));

    const nextPage = () => {
      if (currentPage.value !== maxPages.value) {
        currentPage.value += 1;
      }
    };

    const previousPage = () => {
      if (currentPage.value !== 1) {
        currentPage.value -= 1;
      }
    };

    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));

    const initNewsPage = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/getUserProfileSettings');
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      await store.dispatch('news/getAllNewsData');
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initNewsPage();
    };

    initNewsPage();

    return {
      t,
      userLocations,
      currentUserLocation,
      menuItems,
      locationChangeHandler,
      titleImageUrls,
      newsArray,
      currentPage,
      previousButtonDisabled,
      nextButtonDisabled,
      paginationData,
      maxPages,
      nextPage,
      previousPage,
    };
  },
};
</script>

<style lang="scss">
.news-page {

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 7px;
  }

  &__header-wrapper {
    margin-top: 123px;
    padding-bottom: 12px;
    border-bottom: 1px solid $grey200;
    align-self: flex-start;
    width: 100%;
  }

  &__header {
    font-size: $font-size-title-large;
    line-height: 40px;
    color: $main-black;
  }

  &__header-image-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    width: 905px;
    overflow: hidden;
  }

  &__header-image-first {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  // &__header-image-second {
  //   object-fit: contain;
  //   max-width: 24%;
  //   margin: 0 0 0 24px;
  // }

  &__header-tablet-image-first,
  &__header-tablet-image-second,
  &__header-mobile-image-first,
  &__header-mobile-image-second {
    display:none;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__inner-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1px;
  }

  &__page-info {
    color: $main-black;
    display: inline;
    font-size: $font-size-regular;
    line-height: 18px;

    &--greyscale {
      color: $grey400;
    }
  }

  &__button-wrapper {
    display: flex;

    .secondary-button {
      &:last-child {
        margin-left: 4px;
      }
    }
  }

  &__cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 70px 67px;
    margin: 70px 0 80px 0;
  }

  &__marquiz-vacation {
    margin: 0 auto 99px auto;
  }

  &__marquiz-overview {
    margin: 0 auto 109px auto;
  }

  &__card-image {
    width: 100%;
  }

  &__bold-text {
    font-weight: $font-weight-bold;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .news-page {
    &__content {
      width: 100%;
      padding: 0;
    }

    &__header-image-first,
    &__header-image-second {
      display: none;
    }

    &__header-tablet-image-first {
      display: inline-block;
    }

    // &__header-tablet-image-second {
    //   display: inline-block;
    //   object-fit: contain;
    //   margin-top: 20px;
    // }

    &__header-image-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      width: 732px;
    }

    &__header-wrapper {
      margin-top: 57px;
      padding-bottom: 0;
      border-bottom: none;
    }

    &__header {
      line-height: 28px;
      font-size: $font-size-title;
    }

    &__cards-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0 0;
      margin: 50px 0 40px;
    }

    &__card {
      margin-top: 25px;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    &__marquiz-overview,
    &__marquiz-vacation {
      display: none;
    }

    &__button-wrapper {
      .secondary-button {
        &:last-child {
          margin-left: 12px;
        }
      }
    }

    &__controls {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .news-page {
    &__content {
      padding: 0;
    }

    &__header-wrapper {
      margin-top: 40px;
    }

    &__card {
      margin-top: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid $grey200;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &-wrapper {
        margin-top: 32px;
      }

      &-image {
        border: 1px solid $grey200;
      }
    }

    &__header-tablet-image-first,
    &__header-tablet-image-second {
      display: none;
    }

    &__header-mobile-image-first {
      display: inline-block;
      border: 1px solid $grey200;
      border-radius: 12px;
    }

    // &__header-mobile-image-second {
    //   margin-top: 24px;
    //   display: inline-block;
    //   border: 1px solid $grey200;
    //   border-radius: 12px;
    // }

    &__header-image-wrapper {
      width: calc(100% + 48px);
      margin-left: 0;
    }

    &__controls {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 40px;
    }

    &__cards-wrapper {
      margin: 40px 0;
    }

    &__button-wrapper {
      margin-top: 12px;
    }
  }
}
</style>
