<template>
  <nav class="header-menu">
    <ul class="header-menu__list">
      <li
        v-for="link in LINKS_LIST"
        :key="link.url"
        class="header-menu__list-item"
        :class="{
          'header-menu__list-item--active': path === link.url,
        }"
      >
        <router-link
          class="header-menu__link"
          :to="link.url"
        >
          {{ t(link.label) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { useI18n } from 'vue-i18n';

const LINKS_LIST = [
  {
    url: '/news',
    label: 'news',
  },
  // {
  //   url: '/promo',
  //   label: 'Предложения',
  // },
  {
    url: '/faq',
    label: 'faq',
  },
];

const transcriptions = {
  en: {
    news: 'News',
    faq: 'FAQ',
  },
  ru: {
    news: 'Новости',
    faq: 'FAQ',
  },
};

export default {
  name: 'header-menu',
  setup() {
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', transcriptions.en);
    mergeLocaleMessage('ru', transcriptions.ru);

    return {
      LINKS_LIST,
      t,
    };
  },
};
</script>

<style scoped lang="scss">
.header-menu {
  display: flex;
  margin-right: 36px;
  padding: 0 10px;
  align-items: center;

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    padding: 3px 8px;
    line-height: 16px;
    border-radius: 20px;
    color: $grey900;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    text-decoration: none;

    &:hover {
      background-color: $grey150;
    }

    &.router-link-active {
      color: $blue500;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .header-menu {
    display: none;
  }
}
</style>
