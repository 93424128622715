import inventoryModel from '@/models/inventory';
import { prepareShortMonthDate } from '@/helpers/datesAndTime';

export default {
  namespaced: true,
  state: () => ({
    inventoryItemsListAll: [],
    inventoryItemsListGrouped: [],
    currentUnitId: null,
    inventoryItemImages: [],
  }),
  mutations: {
    'set:inventory:items:grouped': function (state, inventoryItems) {
      state.inventoryItemsListGrouped = inventoryItems || [];
    },
    'set:inventory:items': function (state, inventoryItems) {
      state.inventoryItemsListAll = inventoryItems && inventoryItems.length
        ? inventoryItems.map((item) => ({
          ...item,
          preparedDate: prepareShortMonthDate(item.InvDate),
        }))
        : [];
    },
    'set:current:unit:id': function (state, unitId) {
      state.currentUnitId = unitId;
    },
    'set:inventory:item:images': function (state, inventoryItemImages) {
      state.inventoryItemImages = inventoryItemImages
      && inventoryItemImages.length
        ? inventoryItemImages
          .map((item) => ({ url: item.Image ? `data:image/jpeg;base64,${item.Image}` : '', isVideo: false }))
        : [];
    },
  },
  actions: {
    async getInventoryItems({ commit, state }) {
      const resp = await inventoryModel
        .getInventoryItems({ unitId: state.currentUnitId });
      if (resp.ok) {
        commit('set:inventory:items', resp.data);
        commit('set:inventory:items:grouped', state.inventoryItemsListAll
          .reduce((acc, item) => {
            const locID = item.LocID;
            const locName = item.LocName;

            if (!acc[locID]) {
              acc[locID] = {
                LocName: locName,
                items: [],
              };
            }

            acc[locID].items.push(item);

            return acc;
          }, {}));
      }
    },
    async getInventoryItemImages({ commit }, itemId) {
      const resp = await inventoryModel.getInventoryItemImage({ itemId });
      if (resp.ok) {
        commit('set:inventory:item:images', resp.data);
      }
    },
    setCurrentUnitId({ commit }, unitId) {
      commit('set:current:unit:id', unitId);
    },
  },
};
