export default {
  en: {
    blockTitle: '30% discount on accommodation at VIEW GA hotels',
    blockText: 'Promo code',
    buttonText: 'Book cottage',
    tooltipText: 'Click to copy promo code',
  },
  ru: {
    blockTitle: 'Скидка 30% на проживание в отелях VIEW GA',
    blockText: 'Промокод',
    buttonText: 'Забронировать коттедж',
    tooltipText: 'Нажмите, что бы скопировать промокод',
  },
};
