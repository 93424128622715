export default {
  en: {
    date: 'Date',
    category: 'Category',
    location: 'Location',
    project: 'Project',
    unit: 'Unit',
    room: 'Room',
    description: 'Description',
    status: 'Status',
    signInADiadoc: 'Sign in a Diadoc',
    uploadDoc: 'Upload signed document',
    downloadDoc: 'Download as PDF',
    stubTitle: 'There are no documents here yet',
    stubSubTitle: 'Later, you will be able to see contracts, statements, and reports of the complex on this page.',
  },
  ru: {
    date: 'Дата',
    category: 'Категория',
    location: 'Локция',
    project: 'Проект',
    unit: 'Юнит',
    room: 'Номер',
    description: 'Описание',
    status: 'Статус',
    signInADiadoc: 'Подписать в Диадок',
    uploadDoc: 'Загрузить подписанный документ',
    downloadDoc: 'Скачать в .pdf',
    stubTitle: 'Пока здесь нет документов',
    stubSubTitle: 'Позже вы сможете увидеть на этой странице договоры, акты и отчетность комплекса.',
  },
};
