<template>
  <div class="location-info">
    <title-secondary
      :text="$t(locationData.locationName)"
    />
    <p
      class="location-info__subtitle"
      v-if="locationData.locationAbout"
    >
      {{ $t(locationData.locationAbout) }}
    </p>
    <div class="location-info__data-container">
      <div
        class="location-info__data-item-container
        location-info__data-item-container--state-lesser"
      >
        <div class="location-info__data-item">
          <div class="location-info__data-header-block">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="location-info__data-icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.0414408 4.2002L14.8514 2.85385C14.817 2.6953 14.7629
                2.54169 14.6902 2.39662C14.4518 1.92864 14.0713 1.54817
                13.6034 1.30974C12.7592 1.03191 11.8651 0.93943 10.982
                1.03862H4.01802C3.13488 0.939414 2.24074 1.03188 1.39659
                1.30972C0.928627 1.54816 0.54816 1.92863 0.309715
                2.39659C0.0855514 2.96991 -0.0061572 3.58647 0.0414408
                4.2002ZM14.9608 4.50883L11.1482 4.85543C11.1204
                4.85796 11.0933 4.86629 11.0689 4.87988C11.0444
                4.89347 11.023 4.91202 11.0062 4.93435C10.9893
                4.95667 10.9772 4.98228 10.9708 5.00952C10.9644
                5.03677 10.9638 5.06505 10.9689 5.09256L12.9919
                15.8819C13.2044 15.8498 13.4106 15.7852 13.6034
                15.6903C14.0714 15.4519 14.4519 15.0714 14.6903
                14.6034C14.9681 13.7593 15.0606 12.8651 14.9614
                11.982V5.01802C14.9614 4.83691 14.9614 4.66756
                14.9608 4.50883ZM11.2752 15.7233L9.30321
                5.2058C9.29399 5.15665 9.26677 5.11271 9.22688
                5.08256C9.18699 5.05241 9.13729 5.03822 9.08749
                5.04276L0.22136 5.84872C0.171407 5.85327 0.12496
                5.87633 0.0911456 5.91337C0.0573313 5.95042
                0.0385947 5.99878 0.0386179 6.04893V11.982C-0.0605845
                12.8651 0.0318841 13.7593 0.309715 14.6034C0.54815
                15.0714 0.928619 15.4518 1.39659 15.6903C2.24073
                15.9681 3.13488 16.0606 4.01802 15.9614H11.0776L11.2752
                15.7233ZM7.91451 11.0115C7.91451 12.7273 6.28351
                13.9537 5.46718 14.46C5.33114 14.5457 5.17367
                14.5911 5.01293 14.5911C4.85218 14.5911 4.69471
                14.5457 4.55867 14.46C3.74229 13.9537 2.11134
                12.7273 2.11134 11.0115C2.10679 10.6281 2.17855
                10.2475 2.32244 9.89208C2.46634 9.53662 2.6795
                9.21334 2.94952 8.94104C3.21954 8.66874 3.54102
                8.45287 3.89525 8.30599C4.24949 8.15911 4.6294 8.08415
                5.01287 8.08548C5.39754 8.07985 5.7794 8.15187 6.13559
                8.29724C6.49178 8.4426 6.81499 8.65832 7.08588
                8.93149C7.35678 9.20466 7.56978 9.52966 7.71216
                9.88706C7.85454 10.2445 7.92336 10.6269 7.91451 11.0115Z"
                fill="#2B54E2"
              />
            </svg>
            <p class="location-info__data-header">
              {{ $t('locationsInfo.common.location') }}
            </p>
          </div>
          <div class="location-info__data-block">
            <p
              class="location-info__data-info"
              v-if="locationData.locationPlace"
            >
              {{ $t(locationData.locationPlace) }}
            </p>
          </div>
        </div>
        <div class="location-info__data-item">
          <div class="location-info__data-header-block">
            <svg
              class="location-info__data-icon"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.78364 5.69921C1.5 6.26451 1.5 6.90762 1.5
                8.19384V12.2108C1.5 13.9971 1.5 14.8902 2.10537
                15.4451C2.65471 15.9487 3.50786 15.9953 5.11719
                15.9996C5.11719 15.9992 5.11719 15.9988 5.11719
                15.9984V11.2618C5.11719 10.2154 6.04255 9.36719
                7.18405 9.36719H10.2843C11.4258 9.36719 12.3512
                10.2154 12.3512 11.2618V15.9984C12.3512 15.9988
                12.3512 15.9992 12.3512 15.9996C13.9603 15.9953
                14.8134 15.9487 15.3627 15.4451C15.968 14.8902
                15.968 13.9971 15.968 12.2108V8.19384C15.968 6.90762
                15.968 6.26451 15.6844 5.69921C15.4008 5.13392
                14.8681 4.71539 13.8027 3.87833L12.7693
                3.06634C10.8437 1.55336 9.88089 0.796875 8.73402
                0.796875C7.58715 0.796875 6.62434 1.55336 4.69873
                3.06634L3.6653 3.87833C2.59995 4.71539 2.06727
                5.13392 1.78364 5.69921ZM10.2843 16.0001C10.2843
                15.9995 10.2843 15.9989 10.2843
                15.9984V11.2618H7.18405V15.9984C7.18405 15.9989 7.18405
                15.9995 7.18405 16.0001H10.2843Z"
                fill="#2B54E2"
              />
            </svg>
            <p class="location-info__data-header">
              {{ $t('locationsInfo.common.units') }}
            </p>
          </div>
          <div
            class="location-info__data-block"
            v-if="!locationData.locationUnit.hasMultipleArea"
          >
            <div
              class="location-info__inner-block"
              v-if="locationData.locationUnit.livingSpace
              && locationData.locationUnit.livingSpace.length"
            >
              <p class="location-info__inner-title">
                {{ $t('locationsInfo.common.accom') }}
              </p>
              <div
                v-for="item in locationData.locationUnit.livingSpace"
                :key="item.type"
                class="location-info__inner-item"
              >
                <div class="location-info__inner-item-type">
                  {{ $t(item.type) }}:
                </div>
                <div class="location-info__inner-item-quantity">
                  {{ prepareUnitQuantity(item.quantity) }}
                </div>
              </div>
            </div>
            <div
              class="location-info__inner-block"
              v-if="locationData.locationUnit.periphery
              && locationData.locationUnit.periphery.length"
            >
              <p class="location-info__inner-title">
                {{ $t('locationsInfo.common.periphery') }}
              </p>
              <div
                v-for="item in locationData.locationUnit.periphery"
                :key="item.type"
                class="location-info__inner-item"
              >
                <div class="location-info__inner-item-type">
                  {{ $t(item.type) }}:
                </div>
                <div class="location-info__inner-item-quantity">
                  {{ prepareUnitQuantity(item.quantity) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="location-info__data-block"
            v-if="locationData.locationUnit.hasMultipleArea"
          >
            <div
              class="location-info__area-block"
              v-for="item in locationData.locationUnit.areas"
              :key="item.areaName"
            >
<!--              <div class="location-info__area-name">{{ $t(item.areaName) }}</div>-->
              <div
                class="location-info__inner-block"
                v-if="item.units.livingSpace
                && item.units.livingSpace.length"
              >
                <p class="location-info__inner-title">
                  {{ $t('locationsInfo.common.accom') }}
                </p>
                <div
                  v-for="livingSpaceUnit in item.units.livingSpace"
                  :key="livingSpaceUnit.type"
                  class="location-info__inner-item"
                >
                  <div class="location-info__inner-item-type">
                    {{ $t(livingSpaceUnit.type) }}:
                  </div>
                  <div class="location-info__inner-item-quantity">
                    {{ prepareUnitQuantity(livingSpaceUnit.quantity) }}
                  </div>
                </div>
              </div>
              <div
                class="location-info__inner-block"
                v-if="item.units.periphery
                && item.units.periphery.length"
              >
                <p class="location-info__inner-title">
                  {{ $t('locationsInfo.common.periphery') }}
                </p>
                <div
                  v-for="peripheryUnit in item.units.periphery"
                  :key="peripheryUnit.type"
                  class="location-info__inner-item"
                >
                  <div class="location-info__inner-item-type">
                    {{ $t(peripheryUnit.type) }}:
                  </div>
                  <div class="location-info__inner-item-quantity">
                    {{ prepareUnitQuantity(peripheryUnit.quantity) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="location-info__data-item-container
        location-info__data-item-container--state-bigger"
      >
        <div class="location-info__data-item">
          <div
            class="location-info__data-header-block
            location-info__data-header-block--align-top"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="location-info__data-icon"
            >
              <path
                d="M1.15244 2.32556C1.30891 1.68895 1.38714 1.37064 1.6205 1.18532C1.85385
                1 2.17642 1 2.82157 1H12.1784C12.8236 1 13.1462 1 13.3795 1.18532C13.6129
                1.37064 13.6911 1.68895 13.8476 2.32556L14.883 6.53821C14.9663 6.87716
                15.0079 7.04663 14.9987 7.18429C14.9749 7.54123 14.7399 7.84746 14.4052
                7.95754C14.2761 8 14.1004 8 13.7489 8C13.2903 8 13.061 8 12.8684
                7.95436C12.3288 7.82651 11.8828 7.44218 11.6701 6.92184C11.5942 6.73611
                11.5573 6.51124 11.4836 6.0615C11.463 5.93567 11.4527 5.87275 11.441
                5.85619C11.4067 5.80759 11.3356 5.80759 11.3013 5.85619C11.2896 5.87275
                11.2793 5.93567 11.2586 6.0615L11.1885 6.48958C11.1811 6.53497 11.1773
                6.55766 11.1736 6.57754C11.0218 7.38795 10.3342 7.98046 9.52331
                7.99953C9.50342 8 9.4808 8 9.43557 8C9.39033 8 9.36772 8 9.34783
                7.99953C8.53696 7.98046 7.84932 7.38795 7.69751 6.57754C7.69379 6.55766
                7.69007 6.53497 7.68263 6.48958L7.61249 6.0615C7.59187 5.93567 7.58156
                5.87275 7.56987 5.85619C7.53555 5.80759 7.46446 5.80759 7.43013
                5.85619C7.41844 5.87275 7.40813 5.93567 7.38751 6.0615L7.31737
                6.48958C7.30993 6.53497 7.30621 6.55766 7.30249 6.57754C7.15069 7.38795
                6.46304 7.98046 5.65217 7.99953C5.63228 8 5.60967 8 5.56444 8C5.5192
                8 5.49659 8 5.4767 7.99953C4.66583 7.98046 3.97819 7.38795 3.82638
                6.57754C3.82266 6.55766 3.81894 6.53497 3.8115 6.48958L3.74136
                6.0615C3.72074 5.93567 3.71043 5.87275 3.69874 5.85619C3.66441
                5.80759 3.59332 5.80759 3.559 5.85619C3.54731 5.87275 3.537 5.93567
                3.51638 6.0615C3.44269 6.51124 3.40584 6.73611 3.32992 6.92184C3.11723
                7.44218 2.67119 7.82651 2.13161 7.95436C1.93901 8 1.70969 8 1.25106
                8C0.899607 8 0.723881 8 0.594816 7.95754C0.260151 7.84746 0.0250857
                7.54123 0.00125296 7.18429C-0.00793823 7.04663 0.0337164 6.87716
                0.117026 6.53821L1.15244 2.32556Z"
                fill="#2B54E2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.01623 8.60228C1 8.93005 1 9.31483 1 9.77022V14.4426C1 15.1767
                1 15.5438 1.27197 15.7719C1.54394 16 1.98168 16 2.85714
                16H5.64286V12.8851C5.64286 12.455 6.05859 12.1064 6.57143
                12.1064H8.42857C8.94141 12.1064 9.35714 12.455 9.35714
                12.8851V16H12.1429C13.0183 16 13.4561 16 13.728 15.7719C14
                15.5438 14 15.1767 14 14.4426V9.77022C14 9.31483 14 8.93005
                13.9838 8.60228C13.645 8.60161 13.2985 8.59354 12.9787
                8.53106C12.4965 8.43684 12.0538 8.2532 11.6786 8C11.1362
                8.36609 10.4531 8.58711 9.7162 8.60141C9.67801 8.60215 9.6378
                8.60214 9.59937 8.60214H9.59929L9.58929 8.60213L9.57928
                8.60214H9.5792C9.54077 8.60214 9.50056 8.60215 9.46237
                8.60141C8.72543 8.58711 8.04238 8.36609 7.5 8C6.95763 8.36609
                6.27457 8.58711 5.53763 8.60141C5.49944 8.60215 5.45923 8.60214
                5.4208 8.60214H5.42072L5.41071 8.60213L5.40071 8.60214H5.40063C5.3622
                8.60214 5.32199 8.60215 5.2838 8.60141C4.54686 8.58711 3.8638 8.36609
                3.32142 8C2.94625 8.2532 2.50355 8.43684 2.02128 8.53106C1.70146
                8.59354 1.35496 8.60161 1.01623 8.60228Z"
                fill="#2B54E2"
              />
            </svg>
            <info-header
              :text="$t('locationsInfo.common.addedValue')"
              :popupData="infoHeaderPopups.infrastructure"
            />
          </div>
          <div class="location-info__data-block">
            <ul
              class="location-info__inner-list"
              v-if="locationData.locationInfrastructure
              && locationData.locationInfrastructure.length"
            >
              <li
                class="location-info__inner-list-item"
                v-for="item in locationData.locationInfrastructure"
                :key="item.name"
              >
                {{ $t(item.name) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import InfoHeader from '@/ui-kit/InfoHeader/InfoHeader.vue';
import { useI18n } from 'vue-i18n';
import transcriptions from './transcriptions';

export default {
  name: 'LocationInfo',
  components: {
    TitleSecondary,
    InfoHeader,
  },
  props: {
    locationData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { mergeLocaleMessage } = useI18n();
    const { t } = useI18n();
    mergeLocaleMessage('en', transcriptions.en);
    mergeLocaleMessage('ru', transcriptions.ru);

    const infoHeaderPopups = computed(() => ({
      infrastructure: {
        text: t('funded'),
      },
    }));
    const prepareUnitQuantity = (item) => {
      if (item === 1) {
        return `1 ${t('oneUnit')}`;
      } if (item < 5) {
        return `${item} ${t('fewUnits')}`;
      }
      return `${item} ${t('moreUnits')}`;
    };

    return {
      infoHeaderPopups,
      prepareUnitQuantity,
    };
  },
};
</script>

<style lang="scss">
.location-info {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid $grey200;
  background-color: $grey100;

  &__area {
    &-block {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-name {
      color: $main-black;
      font-size: $font-size-regular;
      font-weight: $font-weight-half-bold;
    }
  }

  &__subtitle {
    margin: 4px 0 0 0;
    padding: 0;
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    line-height: 18px;
    color: $grey900;
  }

  &__data {
    &-container {
      display: flex;
      margin-top: 24px;
    }

    &-icon {
      flex-shrink: 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      width: 100%;

      &:first-child {
        margin-left: 0;
      }

      &-container {
        display: flex;
        margin-left: 12px;

        &:first-child {
          margin-left: 0;
        }

        &--state-lesser {
          width: 48%;

          .location-info__data-item {
            width: 50%;
          }
        }

        &--state-bigger {
          width: 52%;
        }
      }
    }

    &-header {
      color: $main-black;
      font-size: $font-size-regular;
      line-height: 18px;
      font-weight: $font-weight-bold;
      margin: 0 0 0 6px;
      padding: 0;

      &-block {
        display: flex;
        align-items: center;

        .info-header {
          color: $main-black;
          font-size: $font-size-regular;
          line-height: 18px;
          font-weight: $font-weight-bold;

          &__wrapper {
            margin-left: 6px;
          }
        }
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
    }

    &-info {
      color: $grey900;
      font-size: $font-size-regular;
      line-height: 18px;
      font-weight: $font-weight-regular;
      margin: 0;
      padding: 0;
    }
  }

  &__inner {
    &-block {
      display: flex;
      flex-direction: column;
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-title {
      font-size: $font-size-regular;
      line-height: 18px;
      color: $grey900;
      font-weight: $font-weight-medium;
      margin: 0;
      padding: 0;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-top: 1px;

      &:first-child {
        margin-top: 0;
      }

      &-type {
        width: 135px;
        font-size: $font-size-regular;
        line-height: 18px;
        color: $grey700;
        font-weight: $font-weight-regular;
      }

      &-quantity {
        font-size: $font-size-regular;
        line-height: 18px;
        color: $grey900;
        font-weight: $font-weight-regular;
      }
    }

    &-list {
      margin: 0;
      padding-left: 22px;

      &-item {
        margin: 4px 0 0 0;
        padding: 0;
        color: $grey900;
        font-size: $font-size-regular;
        line-height: 18px;
        font-weight: $font-weight-regular;

        &:first-child {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .location-info {
    &__data {
      &-item {
        &-container {
          &--state-lesser {
            flex-direction: column;
            width: 35%;

            .location-info__data-item {
              width: 100%;
              margin-left: 0;
              margin-top: 24px;

              &:first-child {
                margin-top: 0;
              }
            }
          }

          &--state-bigger {
            width: 65%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .location-info {
    .title-secondary {
      line-height: 24px;
    }

    &__subtitle {
      font-size: $font-size-small;
    }

    &__data {
      &-container {
        flex-direction: column;
      }

      &-header-block--align-top {
        align-items: flex-start;

        .location-info__data-icon {
          margin-top: 3px;
        }
      }

      &-item-container {
        &--state-lesser {
          width: 100%;
        }
        &--state-bigger {
          width: 100%;
          margin-left: 0;
          margin-top: 24px;
        }
      }

      &-info {
        font-size: $font-size-small;
      }
    }

    &__inner {
      &-title {
        font-size: $font-size-small;
      }

      &-item {
        &-type {
          font-size: $font-size-small;
        }

        &-quantity {
          font-size: $font-size-small;
        }
      }

      &-list {
        &-item {
          font-size: $font-size-small;
        }
      }
    }
  }
}
</style>
