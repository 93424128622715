export default {
  en: {
    category: 'Category',
    amount: 'Amount',
    date: 'Date',
    stubTitle: 'There is no data on {msg} yet.',
    stubSubTitle: 'The complex does not have {msg} yet. As soon as they become available, you will see them on this page',
    income: 'income | income',
    outcome: 'expenses | expenses',
    incomeItem: 'Income item',
    outcomeItem: 'Expense item',
  },
  ru: {
    category: 'Категория',
    amount: 'Сумма',
    date: 'Дата',
    stubTitle: 'Пока нет данных о {msg}',
    stubSubTitle: 'У комплекса пока что нет {msg}. Как только они появятся, вы увидите их на этой странице.',
    income: 'доходы | доходах',
    outcome: 'расходы | расходах',
    incomeItem: 'Статья дохода',
    outcomeItem: 'Статья расхода',
  },
};
