<template>
  <div class="report-card__wrapper">
    <ui-label
      class="report-card__date"
      :label="date"
      background="#EFF2F6"
    />
    <div
      v-if="title && title.length"
      class="report-card__title"
    >
      {{ title }}
    </div>
    <div
      v-if="subtitle && subtitle.length"
      v-html="subtitle"
      class="report-card__text-area"
    >
    </div>
    <div class="report-card__gallery-wrapper">
      <media-block
        v-if="slides && cover"
        :cover="cover"
        :slides="slides"
      />
    </div>
  </div>
</template>

<script>
import UiLabel from '@/ui-kit/UiLabel/UiLabel.vue';
import MediaBlock from '@/components/MediaBlock/MediaBlock.vue';

export default {
  name: 'ReportCard',
  components: {
    UiLabel,
    MediaBlock,
  },
  props: {
    date: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    cover: {
      type: String,
      default: '',
    },
    slides: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
.report-card {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__date {
    align-self: flex-start;
  }

  &__title {
    margin-top: 20px;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }

  &__text-area {
    margin-top: 20px;
    font-size: $font-size-medium;
    line-height: 22px;

    p {
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__gallery-wrapper {
    margin-top: 20px;
    width: 267px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .report-card {
    &__date {
      line-height: 10px;
      font-size: $font-size-small;
    }

    &__text-area {
      line-height: 18px;
      font-size: $font-size-small;
    }

    &__gallery-wrapper {
      width: 100%;
    }
  }
}
</style>
