import { sendRequest } from '@/helpers/request';
import {
  API_NEWS_MAIN_DATA,
  API_NEWS_ALL_NEWS,
} from './api_pathes';

async function getNewsMainData() {
  try {
    const resp = await sendRequest(API_NEWS_MAIN_DATA, null, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[NEWS] Something went wrong with request to get main data', error);
    return {};
  }
}

async function getAllNews(pageId = 1) {
  try {
    const resp = await sendRequest(API_NEWS_ALL_NEWS, { pageId }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[NEWS] Something went wrong with request to get all news', error);
    return {};
  }
}

export default {
  getNewsMainData,
  getAllNews,
};
