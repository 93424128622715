<template>
  <n-config-provider
    :theme-overrides="configProviderOverride"
  >
    <div
      class="main-layout"
      :class="{
        'main-layout__authorized': blank,
      }"
    >
      <template v-if="hasUnsignDoc">
        <main-notification url="/documents">
          <template #content>
            {{ $t('needSign') }}
            <svg style="margin-left: 4px; transform: rotate(270deg);" width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
              1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409 -0.0719468
              1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584 -0.0718069
              10.4735 -0.0718069 10.7742 0.21598C11.0753 0.503767 11.0753 0.970553 10.7742
              1.25846L6.04455 5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z" fill="#EB5757"/>
            </svg>
          </template>
        </main-notification>
      </template>
      <main-header
        :class="{'main-layout__header--logo-hidden': hideLogoText}"
      >
        <template #navigation>
          <slot name="navigation"></slot>
        </template>
      </main-header>
      <section class="main-layout__content">
        <slot name="content"></slot>
      </section>
      <main-footer/>
    </div>
    <div class="main-layout__mobile-stub">
      <slot name="mobile-stub"></slot>
    </div>
  </n-config-provider>
</template>

<script>
import MainHeader from '@/components/MainHeader/MainHeader.vue';
import MainFooter from '@/components/MainFooter/MainFooter.vue';
import MainNotification from '@/ui-kit/MainNotification/MainNotification.vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { computed, watch } from 'vue';
import { NOT_ALLOWED_PATHS_FOR_LENDERS } from '@/constants/common';
import { useI18n } from 'vue-i18n';
import { NConfigProvider } from 'naive-ui';

export default {
  name: 'main-layout',
  components: {
    MainNotification,
    MainFooter,
    MainHeader,
    NConfigProvider,
  },
  props: {
    blank: {
      type: Boolean,
      default: false,
    },
    hideLogoText: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const hasUnsignDoc = computed(() => store.state.documents.hasUnsignDocuments);
    const { locale } = useI18n();

    store.subscribe((mutation, state) => {
      if (
        !state.user.isInvestor
        && NOT_ALLOWED_PATHS_FOR_LENDERS.some((item) => route.path.includes(item))
      ) {
        router.push('/dashboard/');
      }
    });

    watch(
      () => store.state.user.currentLocale,
      (newLocale) => {
        locale.value = newLocale;
      },
      { immediate: true },
    );

    store.dispatch('documents/initDocuments');

    const MAIN_COLORS = {
      blue100: '#ECF4FF',
      blue150: '#DFECFE',
      blue400: '#2469F6',
      blue500: '#2B54E2',
      blue600: '#2147CB',
      blue700: '#093DC7',
      grey100: '#FAFBFB',
      grey200: '#EFF2F6',
      grey300: '#E0E3E7',
      grey400: '#C1C5CA',
      grey700: '#89909E',
      grey800: '#6A7383',
      grey900: '#424551',
      green100: '#C6EED4',
      purple100: '#E8E2FF',
      black: '#141F3C',
      white: '#F3F6FA',
      test: '#ff0000',
    };

    const configProviderOverride = {
      common: {
        primaryColor: MAIN_COLORS.blue500,
        primaryColorHover: MAIN_COLORS.blue400,
        textColor: MAIN_COLORS.black,
      },
      Typography: {
        headerFontWeight: 700,
        headerFontSize2: '20px',
        textColor2Depth: MAIN_COLORS.grey800,
      },
      Button: {
        iconMarginSmall: '8px',
        paddingSmall: '0 12px',
        heightSmall: '26px',
        border: `1px solid ${MAIN_COLORS.blue100}`,
        borderHover: `1px solid ${MAIN_COLORS.blue150}`,
        borderRadiusSmall: '4px',
        textColor: MAIN_COLORS.black,
        textColorHover: MAIN_COLORS.black,
      },
      Menu: {
        borderRadius: 0,
        itemColorHover: 'transparent',
        itemHeight: '20px',
      },
      DataTable: {
        tdColor: MAIN_COLORS.blue100,
        tdColorHover: MAIN_COLORS.blue100,
        thColorHover: 'transparent',
        borderColor: '#fff',
        thIconColorActive: MAIN_COLORS.blue400,
        thPaddingMedium: '12px',
        thColor: '#fff',
        thTextColor: MAIN_COLORS.black,
        tdTextColor: MAIN_COLORS.black,
        thFontWeight: 600,
        fontSizeMedium: '14px',
      },
      Form: {
        labelTextColor: MAIN_COLORS.grey700,
        feedbackHeightMedium: '4px',
        blankHeightMedium: '30px',
        labelPaddingHorizontal: '6px 0 6px 0',
      },
      Input: {
        heightMedium: '30px',
      },
      Checkbox: {
        borderRadius: '5px',
      },
    };

    return {
      hasUnsignDoc,
      configProviderOverride,
    };
  },
};
</script>

<style lang="scss">
.main-layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  position: relative;

  &__content {
    flex-grow: 2;
  }

  &__blank {
    .main-header {
      border-bottom: none;
    }
  }

  &__mobile-stub {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
}

@media (max-width: 980px) {
  .main-layout {
    &__mobile-stub {
      display: flex;

      &:empty {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .main-layout {
    height: auto;

    &__authorized {
      .main-header {
        &__location-mobile, &__burger-menu {
          display: none;
        }
      }
    }

    &__header--logo-hidden {
      .main-header__logo-text-img {
        display: none;
      }
    }
  }
}
</style>
