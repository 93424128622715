import { sendRequest, downloadFile } from '@/helpers/request';
import {
  API_OUTCOME_DOWNLOAD_XLS,
  API_OUTCOME_AND_INCOME,
  API_OUTCOME_AND_INCOME_PERIPHERY,
} from '@/models/outcome/api_pathes';

async function getBlobOfOutcomeXls(date) {
  try {
    return await downloadFile(`${API_OUTCOME_DOWNLOAD_XLS}?date=${date}`);
  } catch (error) {
    console.error('[OUTCOME] Something went wrong with request to download outcome table', error);
    return {};
  }
}

async function getOutcomeAndIncomeData({ locationId, year, month }) {
  try {
    const resp = await sendRequest(API_OUTCOME_AND_INCOME, { locationId, year, month }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[OUTCOME] Something went wrong with request to get response from Adesk', error);
    return {};
  }
}

async function getOutcomeAndIncomePeripheryData({ locationId, year, month }) {
  try {
    const resp = await sendRequest(API_OUTCOME_AND_INCOME_PERIPHERY, { locationId, year, month }, 'GET');
    return resp.ok ? resp.data : null;
  } catch (error) {
    console.error('[OUTCOME_PERIPHERY] Something went wrong with request to get response from Adesk', error);
    return {};
  }
}

export default {
  getBlobOfOutcomeXls,
  getOutcomeAndIncomeData,
  getOutcomeAndIncomePeripheryData,
};
